/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import {
    CHANGE_REDLINING_STATE,
    UPDATE_REDLINING_STYLE
} from '../actions/redlining';

function redlining(state = {
    created: [],
    updated: [],
    removed: [],
    action: null,
    geomType: null,
    showToolbar: false,
    definition: [],
    styleName: "default",
    style: {
        borderColor: [255, 0, 0, 1],
        size: 2,
        scale: 1,
        marker: "PIN_Red.svg",
        fillColor:  [255, 255, 255, 1],
        text: "",
        textFill: 'rgba(255, 255, 255 ,1)',
        textStroke: 'rgba(0, 0, 0, 0.5)'
    },
    layer: 'redlining',
    layerTitle: 'Redlining',
    selectedFeature: null,
    currentFeature: null
}, action) {
    switch (action.type) {
        case CHANGE_REDLINING_STATE:
            return {
                ...state,
                ...action.data
            }
        case UPDATE_REDLINING_STYLE:
            return {
                ...state, 
                style: {
                    ...state.style,
                    ...action.style
                }
            }
        default:
            return state;
    }
}

export default redlining;
