/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

export const CHANGE_REDLINING_STATE = 'CHANGE_REDLINING_STATE';
export const UPDATE_REDLINING_STYLE = 'UPDATE_REDLINING_STYLE';

export const changeRedliningState = (redliningState) => {
    return {
        type: CHANGE_REDLINING_STATE,
        data: redliningState
    };
}

export const updateRedliningStyle = (style) => {
    return {
        type: UPDATE_REDLINING_STYLE,
        style
    };
}

