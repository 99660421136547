import { MenuItem, TextField } from "goodmap-core/dist"
import dateFormat from "dateformat";
import React from "react"

const AvailableCadastreStatesSelect = ({
    handleChange,
    selectedDate,
    availableDates
}) => {

    return (
        <TextField    
            select
            fullWidth
            variant="outlined"
            size="small"
            label="Stav katastra nehnuteľností"
            onChange={(e) => handleChange({selectedDate: e.target.value})}
            value={selectedDate}
        >    
            {
                availableDates.map((date, key) => (
                    <MenuItem key={key} value={date}>{dateFormat(date, "d.m.yyyy")}</MenuItem>
                ))
            }
        </TextField>
    )

}

export default AvailableCadastreStatesSelect