/**
 * Copyright 2015, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

const { TOGGLE_VECTOR_LAYER_CONFIG_DIALOG} = require('../actions/vectorLayer');

function vectorLayer(state = {
    open: false,
    layer: {}
}, action) {
    switch (action.type) {
        case TOGGLE_VECTOR_LAYER_CONFIG_DIALOG: {
            return {
                ...state,
                open: action.open,
                layer: action.layer || {}
            }
        }
        default:
            return state;
    }
}

export default vectorLayer;
