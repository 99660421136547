/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
import {setIdentifyEnabled} from './identify';
import ConfigUtils from '../utils/ConfigUtils';
import CoordinatesUtils from '../utils/CoordinatesUtils';
import MapUtils from '../utils/MapUtils';
import {UrlParams} from '../utils/PermaLinkUtils';

export const SET_CURRENT_TASK = 'SET_CURRENT_TASK';
export const SET_CURRENT_TASK_BLOCKED = 'SET_CURRENT_TASK_BLOCKED';

export const setCurrentTask = (task, mode=null, mapClickAction=null) => {
    return (dispatch, getState) => {
        // Don't do anything if current task is blocked
        if(getState().task && getState().task.blocked === true) {
            return;
        }
        // Attempt to read mapClickAction from plugin configuration block if not set
        if(!mapClickAction) {
            try {
                let device = getState().browser && getState().browser.mobile ? 'mobile' : 'desktop';
                mapClickAction = getState().localConfig.plugins[device].find(config => config.name === task).mapClickAction;
            } catch(e) {
            }
        }
        dispatch(setIdentifyEnabled(task === null || mapClickAction === 'identify'));
        dispatch({
            type: SET_CURRENT_TASK,
            id: task,
            mode: mode,
            unsetOnMapClick: mapClickAction === 'unset'
        });
    }
}

export const setCurrentTaskBlocked = (blocked) => {
    return {
        type: SET_CURRENT_TASK_BLOCKED ,
        blocked
    }
}

export const openExternalUrl = (url) => {
    return (dispatch, getState)  => {
        // Replace all entries in URL
        Object.entries(UrlParams.getParams()).forEach(([key, value]) => {
            url = url.replace('$' + key + '$', value);
        })

        // Additional entries
        let state = getState();
        let bounds = state.map.bbox.bounds;
        let proj = state.map.projection;
        let roundfactor = CoordinatesUtils.getUnits(proj) === 'degrees' ? 100000. : 1;
        let xmin = Math.round(bounds[0] * roundfactor) / roundfactor;
        let ymin = Math.round(bounds[1] * roundfactor) / roundfactor;
        let xmax = Math.round(bounds[2] * roundfactor) / roundfactor;
        let ymax = Math.round(bounds[3] * roundfactor) / roundfactor;
        let x = Math.round(0.5 * (bounds[0] + bounds[2]) * roundfactor) / roundfactor;
        let y = Math.round(0.5 * (bounds[1] + bounds[3]) * roundfactor) / roundfactor;
        let scale = Math.round(MapUtils.computeForZoom(state.map.scales, state.map.zoom));
        // In case mode is center + scale, extent is missing in UrlParams
        url = url.replace('$e$', [xmin, ymin, xmax, ymax].join(","));
        // In case mode is extent, center + scale are missing in UrlParams
        url = url.replace('$c$', x + "," + y);
        url = url.replace('$s$', scale);
        // Add separate x, y
        url = url.replace('$x$', x);
        url = url.replace('$y$', y);

        url = url.replace('$crs$', proj);

        url = url.replace('$user$', ConfigUtils.getConfigProp("username") || "");

        window.open(url);
    }
}
