/* eslint-disable */
import React from "react";
import { CadastralResult } from "goodmap-core";

export default (layer, result) => {
    switch(layer){
        case "Parcely E - 0":
            return (
                <CadastralResult    
                    reg="E" 
                    esknId={result.properties["ID"]}               
                    kuCode={result.properties["Kódkatastrálnehoúzemia"]}
                    kuName={result.properties["Názovkatastrálnehoúzemia"]}
                    parcel={result.properties["ČísloparcelyregistraE"]}
                    area={result.properties["VýmeraSPIvm2"]}
                    lvNo={result.properties["Číslolistuvlastníctva"] === "Null" ? undefined : result.properties["Číslolistuvlastníctva"]}
                />)
        case "Parcely C - 5":
            return (
                <CadastralResult    
                    reg="C" 
                    esknId={result.properties["ID"]}               
                    kuCode={result.properties["Kódkatastrálnehoúzemia"]}
                    kuName={result.properties["Názovkatastrálnehoúzemia"]}
                    landType={result.properties["Názovdruhupozemku"]}
                    landUse={result.properties["Názovspôsobuvyužívaniapozemku"]}
                    parcel={result.properties["ČísloparcelyregistraC"]}
                    area={result.properties["VýmeraSPIm2"]}
                    lvNo={result.properties["Číslolistuvlastníctva"] === "Null" ? undefined : result.properties["Číslolistuvlastníctva"]}
                />)
        default:
            return undefined
    }


} 
    