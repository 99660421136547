/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from "react";
import PropTypes from'prop-types';
import {connect} from'react-redux';
import assign from'object-assign';
import isEmpty from'lodash.isempty';
import Sortable from'react-sortablejs';
import FileSaver from'file-saver';
import {LayerRole, changeLayerProperty, removeLayer, reorderLayer, setSwipe, addLayerSeparator} from '../../actions/layers'
import {setActiveLayerInfo} from'../../actions/layerinfo';
import {toggleVectorLayerConfigDialog} from'../../actions/vectorLayer';
import {toggleMapTips, zoomToExtent} from'../../actions/map';
import ConfigUtils from"../../utils/ConfigUtils";
import { addLayer } from'../../actions/layers';
import LayerInfoWindow from'./LayerInfoWindow';
import Window from '../../components/Window';
import MapUtils from'../../utils/MapUtils';
import VectorLayerUtils from'../../utils/VectorLayerUtils';
import { withStyles, Divider, List, ListItem } from "goodmap-core"
import { updateDrawingState } from'../../actions/drawing';
import { changeCollectorState } from'../../actions/collector';
import {setCurrentTask} from '../../actions/task';
import VectorLayerDefinitionDialog from "../../components/widgets/VectorLayerDefinitionDialog";
import LayerItem from "./LayerItem";
import LayerMenu from "./LayerMenu";
import LayerGroupItem from "./LayerGroupItem";
import LayerGroupMenu from "./LayerGroupMenu";
import LayerTreeToolbar from "./LayerTreeToolbar";
import LayerTreeActions from "./LayerTreeActions";
import { injectIntl } from "react-intl";
import LegendTooltip from "./LegendTooltip";
import clsx from "clsx";


const styles = (theme) => ({
    optionsContainer: {
        padding: theme.spacing(1)
    },
    list: {
        padding: 0
    },
    listItem: {
        display: "block",
        padding: 0,
        paddingLeft: 22,
        boxShadow: "0px 0px 1px rgba(136, 136, 136, 0.5)",
    },
    listItemFirstLevel: {
        paddingLeft: 30,
        paddingRight: theme.spacing(1)
    },
    layerGroupItem: {
        display: "block",
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    }
})

class LayerTree extends React.Component {
    static propTypes = {
        layers: PropTypes.array,
        mapCrs: PropTypes.string,
        mapScale: PropTypes.number,
        swipe: PropTypes.number,
        mobile: PropTypes.bool,
        fallbackDrag: PropTypes.bool,
        theme: PropTypes.object,
        mapTipsEnabled: PropTypes.bool,
        changeLayerProperty: PropTypes.func,
        removeLayer: PropTypes.func,
        reorderLayer: PropTypes.func,
        toggleMapTips: PropTypes.func,
        showLegendIcons: PropTypes.bool,
        showRootEntry: PropTypes.bool,
        showQueryableIcon: PropTypes.bool,
        allowMapTips: PropTypes.bool,
        allowCompare: PropTypes.bool,
        allowImport: PropTypes.bool,
        groupTogglesSublayers: PropTypes.bool,
        grayUnchecked: PropTypes.bool,
        layerInfoWindowSize: PropTypes.object,
        bboxDependentLegend: PropTypes.bool,
        flattenGroups: PropTypes.bool,
        setSwipe: PropTypes.func,
        setActiveLayerInfo: PropTypes.func,
        width: PropTypes.string,
        enableLegendPrint: PropTypes.bool,
        enableVisibleFilter: PropTypes.bool,
        infoInSettings: PropTypes.bool,
        showToggleAllLayersCheckbox: PropTypes.bool,
        addLayerSeparator: PropTypes.func,
        zoomToExtent: PropTypes.func
    }
    static defaultProps = {
        layers: [],
        showLegendIcons: true,
        showRootEntry: true,
        showQueryableIcon: true,
        allowMapTips: true,
        allowCompare: true,
        allowImport: true,
        groupTogglesSublayers: false,
        grayUnchecked: true,
        layerInfoWindowSize: {width: 320, height: 480},
        bboxDependentLegend: false,
        flattenGroups: false,
        width: "25em",
        enableLegendPrint: true,
        enableVisibleFilter: true,
        infoInSettings: true,
        showToggleAllLayersCheckbox: true
    }
    state = {
        activemenu: null,
        legendTooltip: null,
        sidebarwidth: null,
        importvisible: false,
        filtervisiblelayers: false
    }
    static contextTypes = {
        messages: PropTypes.object
    }
    constructor(props) {
        super(props);
        this.legendPrintWindow = null;
        window.addEventListener('beforeunload', (ev) => {
            if(this.legendPrintWindow && !this.legendPrintWindow.closed) {
                this.legendPrintWindow.close();
            }
        });
    }
    componentWillReceiveProps(newProps) {
        if(newProps.theme.mapTips !== undefined && newProps.theme.mapTips !== this.props.theme.mapTips) {
            this.props.toggleMapTips(newProps.theme.mapTips && !this.props.mobile);
        }
    }
    getGroupVisibility = (group) => {
        if(isEmpty(group.sublayers) || group.visibility === false) {
            return 0;
        }
        let visible = 0;
        group.sublayers.map(sublayer => {
            let sublayervisibility = sublayer.visibility === undefined ? true : sublayer.visibility;
            if(sublayer.sublayers && sublayervisibility) {
                visible += this.getGroupVisibility(sublayer);
            } else {
                visible += sublayervisibility ? 1 : 0;
            }
        });
        return visible / group.sublayers.length;
    }
    renderSubLayers = (layer, group, path, enabled, inMutuallyExclusiveGroup=false) => {
        return (group.sublayers || []).map((sublayer, idx) => {
            let subpath = [...path, idx];
            if(sublayer.sublayers) {
                return this.renderLayerGroup(layer, sublayer, subpath, enabled, inMutuallyExclusiveGroup);
            } else {
                return this.renderLayer(layer, sublayer, subpath, enabled, inMutuallyExclusiveGroup);
            }
        });
    }
    renderLayerGroup = (layer, group, path, enabled, inMutuallyExclusiveGroup=false) => {
        
        const flattenGroups = ConfigUtils.getConfigProp("flattenLayerTreeGroups", this.props.theme) || this.props.flattenGroups;
        let visibility = true; 
        const expanderstate = group.expanded ? 'MdExpandMore' : 'MdChevronRight';
        let checkboxstate;

        if(flattenGroups) {
            return this.renderSubLayers(layer, group, path, enabled, false);
        }

        let subtreevisibility = this.getGroupVisibility(group);
        if(subtreevisibility === 0 && this.state.filtervisiblelayers) {
            return null;
        }

        if(this.props.groupTogglesSublayers) {
            visibility = subtreevisibility > 0;
            checkboxstate = subtreevisibility === 1 ? 'MdCheckBox' : subtreevisibility === 0 ? 'MdCheckBoxOutlineBlank' : 'MdIndeterminateCheckBox';
        } else {
            visibility = group.visibility === undefined ? true : group.visibility;
            checkboxstate = visibility === true ? subtreevisibility === 1 ? 'MdCheckBox' : 'MdIndeterminateCheckBox' : 'MdCheckBoxOutlineBlank';
        }
        if(inMutuallyExclusiveGroup) {
            checkboxstate = checkboxstate;
        }
      
        let sublayersContent = null;
        if(group.expanded) {
            sublayersContent = this.renderSubLayers(layer, group, path, enabled && visibility, group.mutuallyExclusive === true);
        }
     
        const allowRemove = ConfigUtils.getConfigProp("allowRemovingThemeLayers", this.props.theme) === true || layer.role !== LayerRole.THEME;
        const allowReordering = ConfigUtils.getConfigProp("allowReorderingLayers", this.props.theme) === true && !this.state.filtervisiblelayers;
        const sortable = allowReordering && ConfigUtils.getConfigProp("preventSplittingGroupsWhenReordering", this.props.theme) === true;
        const disabled = (!this.props.groupTogglesSublayers && !enabled) || (this.props.grayUnchecked && !visibility);
        
        return (
            <ListItem 
                classes={{root: this.props.classes.layerGroupItem}} 
                button 
                disableRipple={true}
                onClick={() => {}}
                key={group.uuid} 
                data-id={JSON.stringify({layer: layer.uuid, path: path})}
            >
                <LayerGroupItem 
                    layer={layer}
                    visibility={visibility}
                    inMutuallyExclusiveGroup={inMutuallyExclusiveGroup}
                    path={path}
                    groupExpandedToggled={() => this.groupExpandedToggled(layer, path, group.expanded)}
                    expanderstate={expanderstate}
                    checkboxState={checkboxstate}
                    group={group}
                    allowRemove={allowRemove}
                    allowReordering={allowReordering}
                    layerMenuToggled={this.layerMenuToggled}
                    itemVisibilityToggled={this.itemVisibilityToggled}
                    removeLayer={this.props.removeLayer}
                    disabled={disabled}
                />
                {(this.state.activemenu === group.uuid && allowReordering) && 
                    <LayerGroupMenu
                        allowRemove={allowRemove}
                        reorderLayer={this.props.reorderLayer}
                        layer={layer}
                        path={path}
                    />
                }
                
                <Sortable options={{disabled: sortable === false, ghostClass: 'drop-ghost', delay: 200, forceFallback: this.props.fallbackDrag}} onChange={this.onSortChange}>
                    {sublayersContent}
                </Sortable>
            </ListItem>
        );
    }
    renderLayer = (layer, sublayer, path, enabled=true, inMutuallyExclusiveGroup=false, skipExpanderPlaceholder=false, firstLevel = false) => {
        if(this.state.filtervisiblelayers && !sublayer.visibility) {
            return null;
        }

        const allowRemove = ConfigUtils.getConfigProp("allowRemovingThemeLayers", this.props.theme) === true || (layer.role !== LayerRole.THEME && layer.role !== LayerRole.SYSTEMLAYER);
        const allowReordering = ConfigUtils.getConfigProp("allowReorderingLayers", this.props.theme) === true;
        const outsideScaleRange = (sublayer.minScale !== undefined && this.props.mapScale < sublayer.minScale) || (sublayer.maxScale !== undefined && this.props.mapScale > sublayer.maxScale);
        const layerItemDisabled = layer.type !== "separator" && ((!this.props.groupTogglesSublayers && !enabled) || (this.props.grayUnchecked && !sublayer.visibility));
        const allowOptions = layer.type !== "placeholder" && layer.type !== "separator";
        const deleteIconTooltip = this.props.intl.formatMessage({id: "layertree.deletetooltip"})
        const optionsIconTooltip = this.props.intl.formatMessage({id: "layertree.optionstooltip"})

        let checkboxstate = sublayer.visibility === true ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank';
        if(inMutuallyExclusiveGroup) {
            checkboxstate = checkboxstate;
        }

        const classes = clsx({
            [this.props.classes.listItem]: true,
            [this.props.classes.listItemFirstLevel]: firstLevel,
        })
       
        return (
            <ListItem 
                onClick={() => this.itemVisibilityToggled(layer, path, sublayer.visibility, inMutuallyExclusiveGroup)} 
                classes={{root: classes}} 
                button 
                key={sublayer.uuid} 
                data-id={JSON.stringify({layer: layer.uuid, path: path})}
            >
                <LayerItem
                    changeLayerProperty={ev => changeLayerProperty(layer.uuid, "title", ev.target.value)}
                    sublayer
                    checkboxState={checkboxstate}
                    outsideScaleRange={outsideScaleRange}
                    disabled={layerItemDisabled}
                    layer={layer}
                    showLegendIcons={this.props.showLegendIcons}
                    sublayer={sublayer}
                    optionsIconTooltip={optionsIconTooltip}
                    deleteIconTooltip={deleteIconTooltip}
                    setActiveLayerInfo={this.props.setActiveLayerInfo}
                    infoInSettings={this.props.infoInSettings}
                    allowRemove={allowRemove}
                    allowOptions={allowOptions}
                    layerMenuToggled={(e) => {e.stopPropagation(); this.layerMenuToggled(sublayer.uuid)}}
                    removeLayer={(e) => {e.stopPropagation(); this.props.removeLayer(layer.id, path)}}
                    onLegendMouseOver={(e, request) => this.showLegendTooltip(e, request)} 
                    onLegendMouseOut={this.hideLegendTooltip} 
                    onLegendTouchStart={(e, request) => this.showLegendTooltip(e, request)}
                />
                {(this.state.activemenu === sublayer.uuid) && 
                    <LayerMenu
                        path={path}
                        sublayer={sublayer}
                        layer={layer}
                        allowRemove={allowRemove}
                        infoInSettings={this.props.infoInSettings}
                        zoomToExtent={this.props.zoomToExtent}
                        reorderLayer={this.props.reorderLayer}
                        allowReordering={allowReordering}
                        setActiveLayerInfo={this.props.setActiveLayerInfo}
                        filterVisibleLayers={this.state.filtervisiblelayers}
                        editUserLayerStructure={this.editUserLayerStructure}
                        editSystemLayer={this.editSystemLayer}
                        exportRedliningLayerToKml={this.exportRedliningLayerToKml}
                        exportRedliningLayerToJSON={this.exportRedliningLayerToJSON}
                        layerTransparencyChanged={this.layerTransparencyChanged}
                        editUserLayer={this.editUserLayer}
                    />
                }
            </ListItem>
        );
    }
    editUserLayerStructure = (layer) => {
        this.props.toggleVectorLayerConfigDialog(true, layer)
    }
    renderLayerTree = (layer) => {
        if(layer.role === LayerRole.BACKGROUND || layer.layertreehidden) {
            return null;
        } else if(!Array.isArray(layer.sublayers)) {
            return this.renderLayer(layer, layer, [], layer.visibility, false, false, true);
        } else if(this.props.showRootEntry) {
            return this.renderLayerGroup(layer, layer, [], layer.visibility);
        } else {
            return layer.sublayers.map((sublayer, idx) => {
                let subpath = [idx];
                if(sublayer.sublayers) {
                    return this.renderLayerGroup(layer, sublayer, subpath, layer.visibility)
                } else {
                    return this.renderLayer(layer, sublayer, subpath, layer.visibility, false, true);
                }
            });
        }
    }
    renderBody = () => {
        let maptipcheckboxstate = this.props.mapTipsEnabled === true ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank';
        let maptipsEnabled = false;
        if(this.props.theme.mapTips !== undefined) {
            maptipsEnabled = this.props.theme.mapTips !== null && this.props.allowMapTips;
        } else {
            maptipsEnabled = this.props.allowMapTips;
        }

        let allowReordering = ConfigUtils.getConfigProp("allowReorderingLayers", this.props.theme) === true && !this.state.filtervisiblelayers;
        let flattenGroups = ConfigUtils.getConfigProp("flattenLayerTreeGroups", this.props.theme) || this.props.flattenGroups;
        let sortable = allowReordering && (ConfigUtils.getConfigProp("preventSplittingGroupsWhenReordering", this.props.theme) === true || flattenGroups === true);
        
        return (
            <div role="body" className="layertree-container-wrapper">
                <List 
                    classes={{root: this.props.classes.list}}
                    dense
                    onTouchStart={ev => { ev.stopPropagation(); }}
                    onTouchMove={ev => { ev.stopPropagation(); }}
                    onTouchEnd={ev => { ev.stopPropagation(); }}
                    onContextMenuCapture={ev => {ev.stopPropagation(); ev.preventDefault(); return false; }}>
                    <Sortable options={{disabled: sortable === false, ghostClass: 'drop-ghost', delay: 200, forceFallback: this.props.fallbackDrag}} onChange={this.onSortChange}>
                        {this.props.layers.map(this.renderLayerTree)}
                    </Sortable>
                </List>
                <div className={this.props.classes.optionsContainer}>
                    <Divider/>
                    <LayerTreeActions
                        importvisible={this.state.importvisible}
                        swipecheckboxstate={this.props.swipe || this.props.swipe === 0 ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank'}
                        maptipcheckboxstate={maptipcheckboxstate}
                        mapTipsEnabled={(!this.props.mobile && maptipsEnabled)}
                        allowCompare={(this.props.allowCompare && allowReordering)}
                        allowImport={this.props.allowImport}
                        toggleImportLayers={this.toggleImportLayers}
                        toggleSwipe={this.toggleSwipe}
                        theme={this.props.theme}
                        toggleMapTips={this.toggleMapTips}
                    />
                </div>
            </div>
        );
    }
    render() {
        
        const extraTitlebarContent = (
            <LayerTreeToolbar
                enableRedlining={true}
                enableLegend={this.props.enableLegendPrint}
                enableVisibleFilter={this.props.enableVisibleFilter}
                enableDeleteAllLayers={ConfigUtils.getConfigProp("allowRemovingThemeLayers")}
                onLegendClick={this.printLegend}
                onRedliningClick={() => this.props.toggleVectorLayerConfigDialog(true)}
                onFilterClick={ev => this.setState({filtervisiblelayers: !this.state.filtervisiblelayers})}
                onDeleteClick={this.deleteAllLayers}
            />
        );

        return (
            <div>
                <Window 
                    id="LayerTree" 
                    variant="left" 
                    width={this.state.sidebarwidth || this.props.width}
                    title="appmenu.items.LayerTree" icon="MdLayers"
                    onHide={this.hideLegendTooltip} 
                    extraTitlebarContent={extraTitlebarContent}>
                    {() => ({
                        body: this.renderBody()
                    })}
                </Window>
                {
                    (this.state.legendTooltip) && <LegendTooltip 
                        onTouchStart={this.hideLegendTooltip} 
                        legendTooltip={this.state.legendTooltip} 
                        onLoad={this.legendTooltipLoaded}
                    />
                }
                <LayerInfoWindow 
                    windowSize={this.props.layerInfoWindowSize} 
                    bboxDependentLegend={this.props.bboxDependentLegend} 
                />
                <VectorLayerDefinitionDialog/>
            </div>
        );
    }

    

    legendTooltipLoaded = (ev) => {
        if(ev.target.naturalWidth > 1) {
            ev.target.style.visibility = 'visible';
        }
    }
    onSortChange = (order, sortable, ev) => {
        let moved = JSON.parse(order[ev.newIndex]);
        let layer = this.props.layers.find(layer => layer.uuid === moved.layer);
        if(layer) {
            this.props.reorderLayer(layer, moved.path, ev.newIndex - ev.oldIndex);
        }
    }
    toggleImportLayers = () => {
        let visible = !this.state.importvisible;
        this.setState({importvisible: visible, sidebarwidth: visible ? '40em' : null});
    }
    propagateOptions = (layer, options, path=null) => {
        if(layer.sublayers) {
            layer.sublayers = layer.sublayers.map((sublayer, idx) => {
                if(path === null || (!isEmpty(path) && path[0] == idx)) {
                    let newsublayer = assign({}, sublayer, options);
                    this.propagateOptions(newsublayer, options, path ? path.slice(1) : null);
                    return newsublayer;
                } else {
                    return sublayer;
                }
            });
        }
    }
    groupExpandedToggled = (layer, grouppath, oldexpanded) => {
        this.props.changeLayerProperty(layer.uuid, "expanded", !oldexpanded, grouppath);
    }
    itemVisibilityToggled = (layer, grouppath, oldvisibility, inMutuallyExclusiveGroup) => {
        let recurseDirection = null;
        // If item becomes visible, also make parents visible
        if(this.props.groupTogglesSublayers) {
            recurseDirection = !oldvisibility ? "both" : "children";
        } else {
            recurseDirection = !oldvisibility ? "parents" : null;
        }
        this.props.changeLayerProperty(layer.uuid, "visibility", !oldvisibility, grouppath, recurseDirection);
    }
    layerTransparencyChanged = (layer, sublayerpath, value) => {
        this.props.changeLayerProperty(layer.uuid, "opacity", Math.max(1, 255 - value), sublayerpath);
    }
    layerMenuToggled = (sublayeruuid) => {
        this.setState({activemenu: this.state.activemenu === sublayeruuid ? null : sublayeruuid});
    }
    showLegendTooltip = (ev, request) => {
        this.setState({
            legendTooltip: {
                x: ev.target.getBoundingClientRect().right,
                y: ev.target.getBoundingClientRect().top,
                img: request + "&TYPE=tooltip"
            }
        });
    }
    hideLegendTooltip = (ev) => {
        this.setState({legendTooltip: undefined});
    }
    toggleMapTips = () => {
        this.props.toggleMapTips(!this.props.mapTipsEnabled)
    }
    toggleSwipe = () => {
        debugger
        this.props.setSwipe(this.props.swipe || this.props.swipe === 0 ? undefined : 50);
    }
    printLegend = () => {
        let body = '<p id="legendcontainerbody">';
        let printLabel = this.props.intl.formatMessage({id: "layertree.printlegend"});
        body += '<div id="print">' +
                '<style type="text/css">@media print{ #print { display: none; }}</style>' +
                '<button onClick="(function(){window.print();})()">' + printLabel + '</button>' +
                '</div>';
        body += this.props.layers.map(layer => {
            if(!layer.visibility) {
                return "";
            } else if(layer.legendUrl) {
                return layer.params.LAYERS ? layer.params.LAYERS.split(",").reverse().map(sublayer => {
                    let request = layer.legendUrl + (layer.legendUrl.indexOf('?') === -1 ? '?' : '&') + "SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=" + (layer.version || "1.3.0") + "&FORMAT=image/png&LAYER=" + sublayer;
                    return '<div><img src="' + request + '" /></div>';
                }).join("\n") : "";
            } else if(layer.color) {
                return '<div><span style="display: inline-block; width: 1em; height: 1em; box-shadow: inset 0 0 0 1000px ' + layer.color + '; margin: 0.25em; border: 1px solid black;">&nbsp;</span>' + (layer.title || layer.name) + '</div>';
            } else {
                return "";
            }
        }).join("");
        body += "</p>";
        let setLegendPrintContent = () => {
            let container = this.legendPrintWindow.document.getElementById("legendcontainer");
            if(container) {
                container.innerHTML = body;
            } else {
                this.legendPrintWindow.document.body.innerHTML = "Broken template. An element with id=legendcontainer must exist.";
            }
        };

        if(this.legendPrintWindow && !this.legendPrintWindow.closed) {
            let container = this.legendPrintWindow.document.getElementById("legendcontainer");
            setLegendPrintContent();
            this.legendPrintWindow.focus();
        } else {
            let assetsPath = ConfigUtils.getConfigProp("assetsPath");
            this.legendPrintWindow = window.open(assetsPath + "/templates/legendprint.html", "Legend", "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes");
            if(window.navigator.userAgent.indexOf('Trident/') > 0) {
                // IE...
                let interval = setInterval(() => {
                    if (this.legendPrintWindow.document.readyState === 'complete') {
                        setLegendPrintContent();
                        clearInterval(interval);
                    }
                });
            } else {
                this.legendPrintWindow.addEventListener('load', setLegendPrintContent, false);
            }
        }
    }
    deleteAllLayers = () => {
        for(let layer of this.props.layers) {
            if(layer.role === LayerRole.THEME) {
                let sublayers = layer.sublayers || [];
                for(let i = sublayers.length - 1; i >= 0; --i) {
                    this.props.removeLayer(layer.id, [i]);
                }
            } else if(layer.role === LayerRole.USERLAYER) {
                this.props.removeLayer(layer.id);
            }
        }
    }
    toggleLayerTreeVisibility = (visibile) => {
        for(let layer of this.props.layers) {
            if(layer.role === LayerRole.THEME || layer.role === LayerRole.USERLAYER) {
                this.props.changeLayerProperty(layer.uuid, "visibility", visibile, [], this.props.groupTogglesSublayers ? "children" : null);
            }
        }
    }
    editUserLayer = (layer) => {
        this.props.setCurrentTask("Redlining")
        if(layer)
            this.props.updateDrawingState({ layer: { id: layer.id, title: layer.title, properties: layer.definition }})
    }
    editSystemLayer = (layer) => {
        this.props.setCurrentTask("DataCollector")
        if(layer){
            const newState = { layer: layer.id, layerTitle: layer.title };
            if(layer.definition){
                const definition = JSON.parse(layer.definition);
                newState.definition = definition.edit_config && definition.edit_config.default && definition.edit_config.default.fields;
            }
            this.props.changeCollectorState(newState);
        }
    }
    exportRedliningLayerToKml = (layer) => {
        const data = {
            type: "FeatureCollection",
            definition: layer.definition,
	        features: layer.features.map(feature => ({...feature, geometry: VectorLayerUtils.reprojectGeometry(feature.geometry, feature.crs || this.props.mapCrs, 'EPSG:4326')}))
        };
        const kml = VectorLayerUtils.geoJSONToKML(data);
        FileSaver.saveAs(new Blob([kml], {type: "text/plain;charset=utf-8"}), layer.title + ".kml");
    }
    exportRedliningLayerToJSON = (layer) => {
        let data = JSON.stringify({
            type: "FeatureCollection",
            definition: layer.definition,
	        features: layer.features.map(feature => ({...feature, geometry: VectorLayerUtils.reprojectGeometry(feature.geometry, feature.crs || this.props.mapCrs, 'EPSG:4326')}))
        }, null, ' ');
        FileSaver.saveAs(new Blob([data], {type: "text/plain;charset=utf-8"}), layer.title + ".json");
    }
};

const selector = (state) => ({
    mobile: state.browser ? state.browser.mobile : false,
    ie: state.browser ? state.browser.ie : false,
    fallbackDrag: state.browser.ie || (state.browser.platform === 'Win32' && state.browser.chrome),
    layers: state.layers && state.layers.flat ? state.layers.flat : [],
    mapCrs: state.map.projection,
    mapScale: MapUtils.computeForZoom(state.map.scales, state.map.zoom),
    swipe: state.layers && state.layers.swipe || undefined,
    theme: state.theme.current || {},
    mapTipsEnabled: state.map && state.map.maptips
});

const LayerTreeWithStyles = withStyles(styles)(injectIntl(LayerTree))

export default {
    LayerTreePlugin: connect(selector, {
        addLayerSeparator: addLayerSeparator,
        changeLayerProperty: changeLayerProperty,
        removeLayer: removeLayer,
        updateDrawingState: updateDrawingState,
        changeCollectorState: changeCollectorState,
        setCurrentTask: setCurrentTask,
        reorderLayer: reorderLayer,
        addLayer,
        toggleVectorLayerConfigDialog,
        toggleMapTips: toggleMapTips,
        setSwipe: setSwipe,
        setActiveLayerInfo: setActiveLayerInfo,
        zoomToExtent: zoomToExtent
    })(LayerTreeWithStyles)
};
