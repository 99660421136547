/* eslint-disable */
import ol from "../../../libs/openlayers"
import { object, string, func } from "prop-types"
import { useEffect, useCallback } from "react"
import CoordinatesUtils from "../../../utils/CoordinatesUtils"
import MeasureUtils from "../../../utils/MeasureUtils"
import LocaleUtils from "../../../utils/LocaleUtils"
import { LayerRole, addLayer, addLayerFeatures, removeLayer, removeLayerFeatures } from "../../../actions/layers"
import { useDispatch } from "react-redux"
import proj4js from 'proj4';
import uuid from 'uuid';


const style = [
    new ol.style.Style({
        fill: new ol.style.Fill({ color: 'rgba(255, 0, 0, 0.25)' }),
        stroke: new ol.style.Stroke({ color: 'red', width: 2, lineDash: [4,4] })
    }),
    new ol.style.Style({
        image: new ol.style.Circle({
            radius: 5,
            fill: new ol.style.Fill({color: 'white'}),
            stroke: new ol.style.Stroke({ color: 'red', width: 2 }),
        }),
        geometry: (feature) => {
            if(feature.getGeometry().getType() === "Point") {
                return new ol.geom.MultiPoint([feature.getGeometry().getCoordinates()]);
            } else if(feature.getGeometry().getType() === "LineString") {
                return new ol.geom.MultiPoint(feature.getGeometry().getCoordinates());
            } else {
                return new ol.geom.MultiPoint(feature.getGeometry().getCoordinates()[0]);
            }
        }
    })
]

const resultLabelStyle = {
    font: '12pt sans-serif', 
    strokeColor: [255,0,0,1], 
    fillColor: "white", 
    strokeWidth: 1.2, 
    styleName: "text",
    offsetY: -20
};

const segmentLabelStyle = {
    label: 0,
    font: '10pt sans-serif', 
    strokeColor: [0,0,0,1], 
    fillColor: "white", 
    strokeWidth: 1, 
    styleName: "text"    
}


const MeasurementSupport = ({
    map,
    projection,
    measurement,
    mapcrs,
    displaycrs,
    task,
    changeMeasurementState
}) => {

    const gmLayer = {
        id: "measurement",
        role: LayerRole.USERLAYER,
        title: "Meracia vrstva",
        type: 'vector',
        layertreehidden: true
    };

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addLayer(gmLayer));
        return () => {
            dispatch(removeLayer("measurement"))
        }
    }, [])

    
    const searchRedliningLayer = (layerId) => {
        let layer = null;
        const ls = map.getLayers();
        ls.forEach(olLayer => {
            if(olLayer.get('id') === layerId) {
                layer = olLayer;
            }
        });
        return layer;
    }

    const measureLayer = searchRedliningLayer("measurement");
   
    let activeLabel = { fid: null, segments: [], result: null};
    let labels = []; 

    const createFeatureObject = (feature, { 
        label,
        font = "12pt sans-serif",
        fillColor = [255, 0, 0, 0.25],
        strokeColor = "red",
        strokeWidth = 2,
        styleName = "measurement",
        angle = 0,
        offsetX = 0,
        offsetY = 0
     }) => {
        let format = new ol.format.GeoJSON();
        const ft = format.writeFeatureObject(feature);

        ft.properties = { styleName: "measurement", label }
        ft.styleName = styleName;
        ft.type= "Feature";
        ft.styleOptions = {
            // font,
            // strokeColor,
            // strokeWidth,
            // fillColor,
            // circleRadius: 3,
            // text: "",
            // scale: 1,
            // strokeDash: [],
            // rotation: angle,
            // offsetX,
            // offsetY,
            // fillColor: [255, 0, 0, 0.25],
            // strokeColor: "red",
            stroke: {
                color: strokeColor,
                width: strokeWidth,
                dash: [4, 4]
            },
            fill: {
                color: fillColor
            },
            point: {
                radius: 10
            },
            text: {
                borderSize: strokeWidth,
                fill: fillColor,
                stroke: strokeColor 
            },
            
        }

        return ft;
    }

    const getFeatureById = useCallback((fid) => {
        const fts = measureLayer.getSource().getFeatures();
        return fts.find(f => f.getId() === fid)
    }, [measureLayer])
    
    useEffect(() => {

        let pointermove = null;
        let pointerclick = null;
        let drawInteraction = null;
        let selectInteraction = null;

        const addDrawInteraction = () => {

            drawInteraction = new ol.interaction.Draw({
                source: measureLayer.getSource(),
                condition: (event) => {  return event.pointerEvent.buttons === 1 },
                type: measurement.geomType,
                style: []
            });
       
            drawInteraction.on('drawstart', (ev) => {
                ev.feature.setStyle(style);
                activeLabel.segments = [];
                activeLabel.result = null;
                const fid = uuid.v4();
                activeLabel.fid = fid;
                ev.feature.setId(fid);
                pointermove = map.on('pointermove', () => calculateCreated(ev.feature));
                pointerclick = map.on('click', () => calculateCreated(ev.feature));
            });
    
            drawInteraction.on('drawend', (ev) => {
                ol.Observable.unByKey(pointermove);
                ol.Observable.unByKey(pointerclick);
                const ft = createFeatureObject(ev.feature, {});
                dispatch(addLayerFeatures(gmLayer, [ft]));
                const addedFeature = getFeatureById(ev.feature.getId())
                calculateCreated(addedFeature);
                // calculateUpdated(addedFeature);
             
                if(activeLabel.segments.length > 0) {
                    const last = activeLabel.segments.pop();
                    dispatch(removeLayerFeatures(gmLayer.id, [last.id]))
                }
                updateLabels();
                enterTemporaryPickMode(addedFeature);
            });

            map.addInteraction(drawInteraction);

        }

        const addSelectInteraction = () => {
            selectInteraction = new ol.interaction.Select({layers: [measureLayer], hitTolerance: 5});
            selectInteraction.on('select', (evt) => {
           
            })
            map.addInteraction(selectInteraction);
        }
        
        const enterTemporaryPickMode = (feature) => {
            const collection = new ol.Collection([feature]);
            const modifyInteraction = new ol.interaction.Modify({
                features: collection,
                condition: (event) => {  return event.pointerEvent.buttons === 1 },
                // deleteCondition: (event) => {
                //     return ol.events.condition.shiftKeyOnly(event) && ol.events.condition.singleClick(event);
                // }
                insertVertexCondition: (event) => { return false; },
                deleteCondition: (event) => { return false; }
            });
            modifyInteraction.on('modifystart', evt => {
                activeLabel = labels.find(l => l.fid === feature.getId())
                pointermove = map.on('pointermove', () => calculateUpdated(collection.item(0)));
                pointerclick = map.on('click', () => calculateUpdated(collection.item(0)));
            });
            modifyInteraction.on('modifyend', evt => {
                ol.Observable.unByKey(pointermove);
                ol.Observable.unByKey(pointerclick);
                const ft = createFeatureObject(collection.item(0), {});
                dispatch(addLayerFeatures(gmLayer, [ft]));
                const f = getFeatureById(ft.id);
                collection.clear();
                collection.push(f);
                updateLabels();                
                calculateUpdated(f);
            });
            map.addInteraction(modifyInteraction);
        }

        if(measureLayer && measurement.geomType && task.id === "Measure"){
            addDrawInteraction();
        }

        return () => {
            map.removeInteraction(drawInteraction);
            pointermove = null;
            pointerclick = null;
            drawInteraction = null;
        }

    }, [measureLayer, measurement.geomType,  task, mapcrs, displaycrs, measurement.lenUnit, measurement.areaUnit])

    const updateLabels = () => {
        labels = labels.filter(x => x.fid !== activeLabel.fid)
        labels.push({...activeLabel});
    }
    
    const calculateUpdated = (feature) => {
        const coors = feature.getGeometry().getCoordinates();
        const geomType = feature.getGeometry().getType();
        let length = 0; let area = 0; let formatedLength = null; let lonlat = null; let coordinates = null;
       
        if(geomType === 'LineString') {
            length = calculateGeodesicDistances(coors);          
            formatedLength = (length || []).reduce((i,j) => i+j, 0);
            formatedLength = LocaleUtils.toLocaleFixed(MeasureUtils.getFormattedArea(measurement.lenUnit, formatedLength), 2) + " " + getUnit(measurement.lenUnit);  
            for(let i = 0; i < activeLabel.segments.length; ++i) {
                updateSegmentMarker(activeLabel.segments[i], coors[i], coors[i + 1], length[i]);
            }
            updateResultLabel(coors[coors.length - 1], formatedLength)
        }
        
        if(geomType === 'Polygon') {
            area = calculateGeodesicArea(feature.getGeometry().getLinearRing(0).getCoordinates());
            area = LocaleUtils.toLocaleFixed(MeasureUtils.getFormattedArea(measurement.areaUnit, area), 2)  + " " + getUnit(measurement.areaUnit);
            const extent = feature.getGeometry().getExtent();
            const center = ol.extent.getCenter(extent);
            updateResultLabel(center, area)
        }

        if(geomType === 'Point') {
            coordinates = reprojectCoordinate(coors)
            coordinates = `${coordinates[0]}, ${coordinates[1]}`
            updateResultLabel(coors, coordinates)
        }

        changeMeasurementState({
            geomType: measurement.geomType,
            drawing: true,
            coordinates: coordinates,
            length: formatedLength,
            area: area
        });
    }
    
    const updateResultLabel = (newCoordinate, label) => {
        const resultFeature = createResultLabelFeature(newCoordinate, label)
        resultFeature.setId(activeLabel.result ? activeLabel.result.getId() : uuid.v4())
        const gmResultFeature = createFeatureObject(resultFeature, {...resultLabelStyle, label});
        dispatch(addLayerFeatures(gmLayer, [gmResultFeature]))
        activeLabel.result = resultFeature;
    }

    const getUnit = (unit) => {
        return "";
        switch(unit) {
            case "sqm":
                return `m${"2".sup()}`
            case "sqft":
                return `ft${"2".sup()}`
            case "sqkm":
                return `km${"2".sup()}`
            case "sqmi":
                return `mi${"2".sup()}`
            default:
                return unit;
        }
    }

    const calculateCreated = (feature) => {
        // const feature = getFeatureById(f.getId())
        const coors = feature.getGeometry().getCoordinates();
        const geomType = feature.getGeometry().getType();
        let area = 0; let formatedLength = null; let lonlat = null; let coordinates = null;

        if(geomType === 'LineString') {
            let length = calculateGeodesicDistances(coors);       
            formatedLength = (length || []).reduce((i,j) => i+j, 0);
            formatedLength = LocaleUtils.toLocaleFixed(MeasureUtils.getFormattedArea(measurement.lenUnit, formatedLength), 2) + " " + getUnit(measurement.lenUnit)
            let vertexAdded = (coors.length > 0 && activeLabel.segments.length < coors.length - 1);
            // Adjust previous marker if any
            if(vertexAdded && activeLabel.segments.length > 0) {
                let p1 = coors[coors.length - 3];
                let p2 = coors[coors.length - 2];
                updateSegmentMarker(activeLabel.segments[activeLabel.segments.length - 1], p1, p2, length[coors.length - 3]);
            }
            // Add segment markers as neccessary
            if(coors.length > 0 && activeLabel.segments.length < coors.length - 1) {
                const point = createSegmentLabelFeature(coors)
                point.setId(uuid.v4())
                const ft = createFeatureObject(point, segmentLabelStyle);
                dispatch(addLayerFeatures(gmLayer, [ft]))
                activeLabel.segments.push(ft);
                updateResultLabel(coors[coors.length - 1], formatedLength)
            }

            if(!vertexAdded && coors.length > 1) {
                let p1 = coors[coors.length - 2];
                let p2 = coors[coors.length - 1];
                updateSegmentMarker(activeLabel.segments[activeLabel.segments.length - 1], p1, p2, length[coors.length - 2]);
            }
            
        }
        
        if(geomType === 'Polygon') {
            area = calculateGeodesicArea(feature.getGeometry().getLinearRing(0).getCoordinates());
            area = LocaleUtils.toLocaleFixed(MeasureUtils.getFormattedArea(measurement.areaUnit, area), 2) + " " + getUnit(measurement.areaUnit);
            const extent = feature.getGeometry().getExtent();
            const center = ol.extent.getCenter(extent);
            updateResultLabel(center, area)
        }

        if(geomType === 'Point') {
            coordinates = reprojectCoordinate(coors)
            coordinates = `${coordinates[0]}, ${coordinates[1]}`
            updateResultLabel(coors, coordinates)
        }

        changeMeasurementState({
            geomType: measurement.geomType,
            drawing: true,
            coordinates,
            length: formatedLength,
            area: area
        });
    }

    const reprojectCoordinate = (xy) => {
        const digits = proj4js.defs(displaycrs).units === 'degrees'? 5 : 2;
        let reprojected = CoordinatesUtils.reproject(xy, mapcrs, displaycrs);
        reprojected = reprojected.map(x => LocaleUtils.toLocaleFixed(x, digits));
        return reprojected;
    }

    const reprojectedCoordinates = (coordinates) => {
        return coordinates.map((coordinate) => {
            return CoordinatesUtils.reproject(coordinate, projection, 'EPSG:4326');
        });
    }

    const calculateGeodesicDistances = (coordinates) => {
        let rc = reprojectedCoordinates(coordinates);
        let lengths = [];
        for (let i = 0; i < rc.length - 1; ++i) {
            lengths.push(ol.sphere.getDistance(rc[i], rc[i + 1]));
        }
        return lengths;
    }
    
    const calculateGeodesicArea = (coordinates) => {
        let rc = reprojectedCoordinates(coordinates);
        return Math.abs(ol.sphere.getArea(new ol.geom.Polygon([rc]), {projection: 'EPSG:4326'}));
    }

    const createResultLabelFeature = (coordinates, text) => {
        const point = new ol.Feature({
            geometry: new ol.geom.Point(coordinates),
            geomType: "Polygon"
        });
        point.set("label", text);
        return point;
    }
    
    const createSegmentLabelFeature = (coordinates) => {
        const point = new ol.Feature({
            geometry: new ol.geom.Point(coordinates[coordinates.length - 1])
        });
        return point;
    }

    const updateSegmentMarker = (f, p1, p2, length) => {
        const marker = getFeatureById(f.id);
        let angle = -Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);
        if(Math.abs(angle) > 0.5 * Math.PI) {
            angle += Math.PI;
        }
        const label = LocaleUtils.toLocaleFixed(MeasureUtils.getFormattedLength(measurement.lenUnit, length), 2);
        const ft = createFeatureObject(marker, {...segmentLabelStyle, label, angle, offsetY: -8})
        dispatch(addLayerFeatures(gmLayer, [ft]))
        const markerFeature = getFeatureById(ft.id)
        markerFeature.set("label", label);
        markerFeature.getStyle().getText().setRotation(angle);
        markerFeature.setGeometry(new ol.geom.Point([0.5 * (p1[0] + p2[0]), 0.5 * (p1[1] + p2[1])]));
        markerFeature.getStyle().getText().setText(label);
    }

    return null
}

MeasurementSupport.propTypes = {
    map: object,
    projection: string,
    measurement: object,
    changeMeasurementState: func,
}


export default MeasurementSupport;