/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import ol from '@openlayers'
import assign from 'object-assign'
import ConfigUtils from '../../../utils/ConfigUtils'
import markerIcon from './img/marker-icon.png'

const FeatureStyles = {
    default: (feature, options) => {
        debugger
        let opts = assign({}, ConfigUtils.getConfigProp("defaultFeatureStyle"), options);
        return new ol.style.Style({
            fill: new ol.style.Fill(opts.fill),
            stroke: new ol.style.Stroke(opts.stroke),
            image: opts.point.radius > 0 ? new ol.style.Circle({
                radius: opts.point.radius,
                fill: new ol.style.Fill(opts.fill),
                stroke: new ol.style.Stroke(opts.stroke)
            }) : null,
            text: new ol.style.Text({
              font: `${opts.text.size || "11"}pt sans-serif`,
              text: feature.get("label") || "",
              fill: new ol.style.Fill({color: opts.text.fill}),
              stroke: new ol.style.Stroke({color: opts.text.stroke, width: opts.text.borderSize}),
              textAlign: feature.getGeometry().getType() === "Point" ? 'left' : 'center',
              textBaseline: feature.getGeometry().getType() === "Point" ? 'bottom' : 'middle',
            //   offsetX: feature.getGeometry().getType() === "Point" ? (5 + opts.point.radius) : 0
            })
        });
    },
    highlight: () => {
        return new ol.style.Style({
            fill: new ol.style.Fill({
                color:  [255, 0, 0, 0.33],
            }),
            stroke: new ol.style.Stroke({
                color: [255, 0, 0],
                width: 2
            })
        });
    },
    selection: () => {
        return new ol.style.Style({
            fill: new ol.style.Fill({
                color:  [255, 255, 0, 0.33],
            }),
            stroke: new ol.style.Stroke({
                color: [255, 255, 0],
                width: 2
            })
        });
    },
    dashedGreen: () => {
        return new ol.style.Style({
            fill: new ol.style.Fill({
                color:  [0, 255, 0, 0.13],
            }),
            stroke: new ol.style.Stroke({
                color: [0, 255, 0],
                width: 1.5,
                lineDash: [4,6],
            })
        });
    },
    dashedYellow: () => {
        return new ol.style.Style({
            fill: new ol.style.Fill({
                color:  [255, 255, 0, 0.13],
            }),
            stroke: new ol.style.Stroke({
                color: [255, 255, 0],
                width: 1.5,
                lineDash: [4,6],
            })
        });
    },
    marker: (feature, options) => {
        let opts = assign({}, ConfigUtils.getConfigProp("defaultFeatureStyle"), options);
        return new ol.style.Style({
            image: new ol.style.Icon({
                anchor: (opts.icon && opts.icon.anchor) || [0.5, 1],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                opacity: 1.,
                scale: (((opts.icon && opts.icon.scale) || 1) * 0.1),
                src: (opts.icon && `/markers/${opts.icon.src}`) || markerIcon
            }),
            text: new ol.style.Text({
                font: `${opts.text.size || "11"}pt sans-serif`,
                text: feature.get("label") || "",
                fill: new ol.style.Fill({color: opts.text.fill}),
                stroke: new ol.style.Stroke({color: opts.text.stroke, width: opts.text.borderSize}),
                textAlign: feature.getGeometry().getType() === "Point" ? 'left' : 'center',
                textBaseline: feature.getGeometry().getType() === "Point" ? 'bottom' : 'middle',
            })
        });
    },
    text: (feature, options) => {
        let opts = assign({}, ConfigUtils.getConfigProp("defaultFeatureStyle"), options);
        return new ol.style.Style({
            text: new ol.style.Text({
                font: `${opts.text.size || "11"}pt sans-serif`,
                text: feature.getProperties()["label"] || "",
                scale: opts.text.scale,
                angle: opts.text.angle,
                offsetX: opts.text.offsetX,
                offsetY: opts.text.offsetY,
                fill: new ol.style.Fill({color: opts.text.fill}),
                stroke: new ol.style.Stroke({color: opts.text.stroke, width: opts.text.borderSize})
            })
        });
    },
    measurement: (feature, {fillColor, strokeColor, font, borderSize}) => {
        return [
            new ol.style.Style({
                fill: new ol.style.Fill({ color: 'rgba(255, 0, 0, 0.25)' }),
                stroke: new ol.style.Stroke({ color: 'red', width: 2, lineDash: [4,4] })
            }),
            new ol.style.Style({
                image: new ol.style.Circle({
                    radius: 5,
                    fill: new ol.style.Fill({color: 'white'}),
                    stroke: new ol.style.Stroke({ color: 'red', width: 2 }),
                }),
                geometry: (f) => {
                    if(f.getGeometry().getType() === "Point") {
                        return new ol.geom.MultiPoint([f.getGeometry().getCoordinates()]);
                    } else if(f.getGeometry().getType() === "LineString") {
                        return new ol.geom.MultiPoint(f.getGeometry().getCoordinates());
                    } else {
                        return new ol.geom.MultiPoint(f.getGeometry().getCoordinates()[0]);
                    }
                }
            })
        ]
    }
};

export default FeatureStyles;
