/**
 * Copyright 2018, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from "goodmap-core"
import * as MaterialDesign from 'react-icons/md';
import * as FontAwesome from 'react-icons/fa';

const getIcon = (type, icon) => {
    if(type === "md"){
        return MaterialDesign[icon];
    } else if(type === "fa"){
        return FontAwesome[icon];
    }
}

const Icon = ({
    icon,
    type = "md",
    onClick,
    fontSize
}) => {

    
    const ReactIcon = getIcon(type, icon)

    if(!ReactIcon) return null;

    return (
        <ReactIcon onClick={onClick} fontSize={fontSize} />
    )
};

Icon.defaultProps = {
    onClick: () => {},
    fontSize: "inherit"
}

Icon.propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.string
}

const styles = (theme) => ({
    root: ({fontSize}) => ({
        fontSize
    })
})

export default withStyles(styles)(Icon);
