/**
* Copyright 2019, Sourcepole AG.
* All rights reserved.
*
* This source code is licensed under the BSD-style license found in the
* LICENSE file in the root directory of this source tree.
*/

import React from 'react';
import Icon from '../../components/Icon';
import VectorLayerDefinitionDialog from "./VectorLayerDefinitionDialog"
import { string, array, func, bool } from "prop-types";
import { 
    TextField, 
    MenuItem, 
    makeStyles, 
    Button
} from "goodmap-core";
import { useState } from 'react';
import uuid from 'uuid'

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    button: {
        padding: 8,
        height: 36,
        width: 36,
        marginTop: 4,
        marginLeft: 4
    }
}))

const VectorLayerPicker = ({
    addLayer,
    onChange,
    layers,
    value,
    enableAddLayer
}) => {
   
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    const handleAddLayer = (data) => {
        if(data.title) {
            let layer = {
                id: uuid.v4(),
                title: data.title,
                type: 'vector',
                properties: data.attributes
            };
            addLayer(layer);
            onChange(layer);
            setOpenDialog(false)
        }
    }
    
    return (
        <>
            <div className={classes.root}>
                <TextField
                    className={classes.select}
                    select
                    label="Vrstva"
                    variant="outlined"
                    margin="dense"
                    value={value}
                    onChange={ev => onChange(layers.find(layer => layer.id === ev.target.value))}
                >
                    {
                        layers.map(layer => (
                            <MenuItem key={layer.id} value={layer.id}>
                                {layer.title}
                            </MenuItem>
                        ))
                    }
                </TextField>
                {
                    enableAddLayer && (
                        <Button className={classes.button} color="primary" variant="contained" onClick={() => setOpenDialog(true)}>
                            <Icon icon="plus" />
                        </Button>
                    )
                }
                
            </div>
            {(openDialog) &&
                <VectorLayerDefinitionDialog addLayer={handleAddLayer} onClose={() => setOpenDialog(false)} onChange={onChange} />
            }
        </>
    );
};

VectorLayerPicker.defaultProps = {
    enableAddLayer: true
}

VectorLayerPicker.propTypes = {
    value: string,
    layers: array,
    enableAddLayer: bool,
    onChange: func,
    addLayer: func
}

export default VectorLayerPicker;
