/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import proj4js from 'proj4';
import CoordinatesUtils from '../utils/CoordinatesUtils'
import LocaleUtils from '../utils/LocaleUtils'
import { TextField, withStyles } from 'goodmap-core';

class CoordinateDisplayer extends React.Component {
    static propTypes = {
        displaycrs: PropTypes.string,
        mapcrs: PropTypes.string,
        coordinate: PropTypes.object,
        className: PropTypes.string
    }
    render() {
        let value = "";
        if(this.props.mousepos) {
            let coo = CoordinatesUtils.reproject(this.props.mousepos.coordinate, this.props.mapcrs, this.props.displaycrs);
            if(!isNaN(coo[0]) && !isNaN(coo[1])) {
                let digits = proj4js.defs(this.props.displaycrs).units === 'degrees'? 4 : 0;
                value = LocaleUtils.toLocaleFixed(coo[0], digits) + " " + LocaleUtils.toLocaleFixed(coo[1], digits);
            }
        }
        return (
            <TextField InputProps={{classes: {input: this.props.classes.input}}} size="small" variant="outlined" type="text" className={this.props.className} value={value} readOnly="readOnly"/>
        )
    }
};

const selector = state => ({
    mapcrs: state.map.projection,
    mousepos: state.mousePosition && state.mousePosition.position && state.mousePosition.position || undefined,
});

const styles = () => ({
    input: {
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4,
        paddingBottom: 4
    }
})

export default connect(selector)(withStyles(styles)(CoordinateDisplayer))
