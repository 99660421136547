/* eslint-disable */
/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Message from '../components/I18N/Message';
import CoordinatesUtils from '../utils/CoordinatesUtils';
import ProxyUtils from "../utils/ProxyUtils";
import {LayerRole} from '../actions/layers';
import {setCurrentTask} from '../actions/task';
import TaskBar from '../components/TaskBar';
import PrintFrame from '../components/PrintFrame';
import MapUtils from '../utils/MapUtils';
import { withStyles, TextField, MenuItem } from "goodmap-core"
import VectorLayerUtils from '../utils/VectorLayerUtils';

const styles = {
    container: {
        padding: 16,
        background: "#fff",
        borderRadius: 8
    }
}

class RasterExport extends React.Component {
    static propTypes = {
        theme: PropTypes.object,
        map: PropTypes.object,
        layers: PropTypes.array,
        setCurrentTask: PropTypes.func,
        dpis: PropTypes.array
    }
    state = {
        selectedFormat: null,
        dpi: 96
    }
    formatChanged = (ev) => {
        this.setState({selectedFormat: ev.target.value})
    }
    dpiChanged = (ev) => {
        this.setState({dpi: parseInt(ev.target.value)});
    }
    renderBody = () => {
        let themeLayers = this.props.layers.filter(layer => layer.role === LayerRole.THEME);
        // let themeLayers = this.props.layers;
        if(!this.props.theme || !themeLayers) {
            return null;
        }
        const formatMap = {
            "image/jpeg" : "JPEG",
            "image/png": "PNG",
            "image/png; mode=16bit": "PNG 16bit",
            "image/png; mode=8bit" : "PNG 8bit",
            "image/png; mode=1bit" : "PNG 1bit",
            "image/geotiff" : "GeoTIFF"
        };

        let availableFormats = this.props.theme.availableFormats;
        let defaultFormat = availableFormats.includes('image/geotiff') ? 'image/geotiff' : availableFormats[0];
        let selectedFormat = this.state.selectedFormat || defaultFormat;
        let filename = this.props.theme.name + "." + selectedFormat.split(";")[0].split("/").pop();
        let action = ProxyUtils.addProxyIfNeeded(this.props.theme.url, "&filename=" + encodeURIComponent(filename));
        let exportLayers = themeLayers.map(layer => layer.params.LAYERS).reverse().join(",");
        let exportOpacities = themeLayers.map(layer => layer.params.OPACITIES).reverse().join(",");
        let backgroundLayer = this.props.layers.find(layer => layer.role === LayerRole.BACKGROUND && layer.visibility === true);
        let themeBackgroundLayer = backgroundLayer ? this.props.theme.backgroundLayers.find(entry => entry.name === backgroundLayer.name) : null;
        let exportBackgroundLayer = themeBackgroundLayer ? themeBackgroundLayer.printLayer : null;
        if(exportBackgroundLayer) {
            let printBgLayerName = exportBackgroundLayer;
            if(Array.isArray(exportBackgroundLayer)) {
                let scale = MapUtils.computeForZoom(this.props.map.scales, this.props.map.zoom);
                printBgLayerName = null;
                for(let i = 0; i < exportBackgroundLayer.length; ++i) {
                    printBgLayerName = exportBackgroundLayer[i].name;
                    if(scale <= exportBackgroundLayer[i].maxScale) {
                        break;
                    }
                }
            }
            if(printBgLayerName) {
                exportLayers = printBgLayerName + "," + exportLayers;
                exportOpacities = "255," + exportOpacities;
            }
        }
        let dpiSelector = null;
        if(this.props.dpis) {
            dpiSelector = (
                <span>
                    <TextField 
                        label={<Message msgId="rasterexport.resolution" />}
                        name="DPI" 
                        defaultValue={this.props.dpis[0]} 
                        onChange={this.dpiChanged}
                        select
                        variant="outlined"
                        fullWidth
                        size="small"
                    >
                        {this.props.dpis.map(dpi => {
                            return (<MenuItem key={dpi+"dpi"} value={dpi}>{dpi+" dpi"}</MenuItem>);
                        })}
                    </TextField>
                </span>
            );
        }

        // Local vector layer features
        let mapCrs = this.props.map.projection;
        let highlightParams = VectorLayerUtils.createPrintHighlighParams(this.props.layers, mapCrs, this.state.dpi);

        return (
            <div className={this.props.classes.container}>
                <form ref={form => this.form = form} action={action} method="POST" target="_blank" >
                <div><Message msgId="rasterexport.selectinfo" /></div>
                <div className="raster-export-settings">
                    <span>
                        <TextField 
                            label={<Message msgId="rasterexport.format" />} 
                            variant="outlined" 
                            fullWidth
                            size="small" 
                            select 
                            margin="normal"
                            name="FORMAT" 
                            value={selectedFormat} 
                            onChange={this.formatChanged}
                        >
                            {
                                availableFormats.map(format => {
                                    if(format.startsWith('image/')) {
                                        return (<MenuItem key={format} value={format}>{formatMap[format] || format}</MenuItem >);
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </TextField>
                    </span>
                    {dpiSelector}
                </div>
                <input type="hidden" name="SERVICE" value="WMS" readOnly={true} />
                <input type="hidden" name="VERSION" value={themeLayers[0].version || "1.3.0"} readOnly={true} />
                <input type="hidden" name="REQUEST" value="GetMap" readOnly={true} />
                <input type="hidden" name="LAYERS" value={exportLayers} readOnly={true} />
                <input type="hidden" name="OPACITIES" value={exportOpacities} readOnly={true} />
                <input type="hidden" name="TRANSPARENT" value="true" readOnly={true} />
                <input type="hidden" name="TILED" value="false" readOnly={true} />
                <input type="hidden" name="STYLES" value="" readOnly={true} />
                <input type="hidden" name="CRS" value={this.props.map.projection} readOnly={true} />
                <input type="hidden" name="FILENAME" value={filename} readOnly={true} />
                <input ref={input => this.extentInput = input} type="hidden" name="BBOX" value="" readOnly={true} />
                <input ref={input => this.widthInput = input} type="hidden" name="WIDTH" value="" readOnly={true} />
                <input ref={input => this.heightInput = input} type="hidden" name="HEIGHT" value="" readOnly={true} />
                {Object.keys(this.props.theme.watermark || {}).map(key => {
                    return (<input key={key} type="hidden" name={"WATERMARK_" + key.toUpperCase()} value={this.props.theme.watermark[key]} readOnly={true} />)
                })}
                <input readOnly={true} name={"HIGHLIGHT_GEOM"} type="hidden" value={highlightParams.geoms.join(";")} />
                <input readOnly={true} name={"HIGHLIGHT_SYMBOL"} type="hidden" value={highlightParams.styles.join(";")} />
                <input readOnly={true} name={"HIGHLIGHT_LABELSTRING"} type="hidden" value={highlightParams.labels.join(";")} />
                <input readOnly={true} name={"HIGHLIGHT_LABELCOLOR"} type="hidden" value={highlightParams.labelFillColors.join(";")} />
                <input readOnly={true} name={"HIGHLIGHT_LABELBUFFERCOLOR"} type="hidden" value={highlightParams.labelOultineColors.join(";")} />
                <input readOnly={true} name={"HIGHLIGHT_LABELBUFFERSIZE"} type="hidden" value={highlightParams.labelOutlineSizes.join(";")} />
                <input readOnly={true} name={"HIGHLIGHT_LABELSIZE"} type="hidden" value={highlightParams.labelSizes.join(";")} />
                </form>
            </div>
        );
    }
    render() {
        return (
            <TaskBar 
                extraContent={<PrintFrame map={this.props.map} bboxSelected={this.bboxSelected} />} 
                task="RasterExport"
            >
               {this.renderBody()}
            </TaskBar>
        );
    }
    bboxSelected = (bbox, crs, pixelsize) => {
        const version = this.props.theme.version || "1.3.0";
        let extent = (CoordinatesUtils.getAxisOrder(crs).substr(0, 2) == 'ne' && version == '1.3.0') ?
            bbox[1] + "," + bbox[0] + "," + bbox[3] + "," + bbox[2]:
            bbox.join(',');
        this.extentInput.value = extent;
        this.widthInput.value = Math.round(pixelsize[0] * parseInt(this.state.dpi || 96) / 96.);
        this.heightInput.value = Math.round(pixelsize[1] * parseInt(this.state.dpi || 96) / 96.);
        this.form.submit();
        this.props.setCurrentTask(null);
    }
};

const selector = (state) => ({
    theme: state.theme ? state.theme.current : null,
    map: state.map ? state.map : null,
    layers: state.layers ? state.layers.flat : []
});

const RasterExportWithStyles = withStyles(styles)(RasterExport)

export default {
    RasterExportPlugin: connect(selector, {
        setCurrentTask: setCurrentTask
    })(RasterExportWithStyles)
}
