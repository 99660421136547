/* eslint-disable */
import React from 'react';
import {func } from "prop-types";
import { 
    TextField, 
    MenuItem, 
    makeStyles, 
    Button,
    TableContainer,
    Divider,
    Grid,
    Table,
    TableHead,
    FormControlLabel,
    Typography,
    Checkbox,
    TableBody,
    Paper,
    TableRow,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TableCell
} from "goodmap-core";
import Window from '../Window';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import {addLayer} from "../../actions/layers";
import {toggleVectorLayerConfigDialog} from "../../actions/vectorLayer";
import Message from '../I18N/Message';
import { useSelector, useDispatch } from 'react-redux';
import uuid from 'uuid';
import { refreshLayer, LayerRole } from '../../actions/layers';
import LocaleUtils from '../../utils/LocaleUtils';
import { MdExpandMore } from 'react-icons/md';

const HIDDEN_ATTRIBUTES = ["computed_area", "computed_length", "isText", "label"]

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    expansionPanel: {
        marginTop: theme.spacing(2)
    },
    expandedExpansionPanel: {
        marginBottom: "0 !important"
    }
}))

const VectorLayerDefinitionDialog = () => {
   
    const classes = useStyles();
    const {open, layer, messages} = useSelector(state => ({...state.vectorLayer, messages: state.locale.messages}));
    const [attributes, setAttributes] = useState(layer.definition || []);
    const [showAttributes, setShowAttributes] = useState(false);
    const [attributeErr, setAttributeErr] = useState()
    const dispatch = useDispatch()
    const [attributeDef, setAttributeDef] = useState({
        name: "",
        type: "text",
        min: undefined,
        max: 255,
        required: false
    })
    const { handleSubmit, register, errors, setValue } = useForm();

    useEffect(() => {
        layer.definition && setAttributes(layer.definition || [])
    }, [layer.definition])

    // Register react-form-hooks attributes
    useEffect(() => {
        register({ name: "attributes" }, { required: false });
    }, [register]);

    // Update attributes react-form-hook
    useEffect(() => {
        setValue("attributes", attributes)
    }, [attributes])

    const addAttribute = () => {
        const message = LocaleUtils.getMessageById(messages, "redlining.attributeNameConflict");
        const existsAttributeWithSameName = attributes.find(x => x.name === attributeDef.name)
        if(existsAttributeWithSameName){
            setAttributeErr(message)
            return;
        }
        setAttributes([...attributes, attributeDef])
        setAttributeDef({
            name: "",
            type: "text",
            min: undefined,
            max: 255,
            required: false
        })
        setAttributeErr(null)
    }

    const resetForm = () => {
        setAttributes([])
        setAttributeErr(null);
        setAttributeDef({
            name: "",
            type: "text",
            min: undefined,
            max: undefined,
            required: false
        })
    }

    if(!open) return null;

    const handleAddLayer = (data) => {
        if(layer.id){
            layer.title = data.title;
            layer.description = data.description;
            layer.definition = data.attributes;
            dispatch(refreshLayer(l => l.role === LayerRole.SYSTEMLAYER))
        } else if(data.title) {
            dispatch(addLayer({
                id: uuid.v4(),
                title: data.title,
                description: data.description,
                type: 'vector',
                definition: data.attributes
            }));
        }
        dispatch(toggleVectorLayerConfigDialog(false, {}))
        resetForm()
    }

    const removeAttribute = (name) => {
        setAttributes(attributes.filter(x => x.name !== name))
    }

    const renderAttributes = () => (
        <div className={classes.layerAttributesContainer}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        label={attributeErr || "Atribút"}
                        error={attributeErr}
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={attributeDef.name}
                        onChange={(e) => setAttributeDef({...attributeDef, name: e.target.value})}
                        name="name"
                        margin="nomal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Typ"
                        select
                        fullWidth
                        size="small"
                        variant="outlined"
                        name="type"
                        onChange={(e) => {
                            const contstraints = { max: "" };
                            if(e.target.value === "longtext"){
                                contstraints.max = 1000;
                            }
                            if(e.target.value === "text"){
                                contstraints.max = 255;
                            }
                            const newAttrDef = {...attributeDef, ...contstraints, type: e.target.value};
                            setAttributeDef(newAttrDef);
                        }}
                        margin="nomal"
                        value={attributeDef.type}
                    >
                        <MenuItem value="int">Celé číslo</MenuItem>
                        <MenuItem value="number">Číslo</MenuItem>
                        <MenuItem value="date">Dátum</MenuItem>
                        {/* <MenuItem value="file">Súbor</MenuItem> */}
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="longtext">Dlhý text</MenuItem>
                        {/* <MenuItem value="link">Link</MenuItem> */}
                    </TextField>
                </Grid>
                {(attributeDef.type === "int" || attributeDef.type === "number") && (
                    <Grid item xs={4}>
                        <TextField
                            label="Min"
                            variant="outlined"
                            type="number"
                            inputProps={attributeDef.type === "int" && {step: 1}}
                            value={attributeDef.min}
                            onChange={(e) => setAttributeDef({...attributeDef, min: e.target.value})}
                            fullWidth
                            size="small"
                            name="title"
                            margin="nomal"
                        />
                    </Grid>
                )}
                {(attributeDef.type === "text" || attributeDef.type === "longtext" || attributeDef.type === "int" || attributeDef.type === "number") && 
                    <Grid item xs={4}>
                        <TextField
                            label="Max"
                            fullWidth
                            size="small"
                            type="number"
                            inputProps={{shrink: true}}
                            value={attributeDef.max}
                            onChange={(e) => setAttributeDef({...attributeDef, max: e.target.value})}
                            variant="outlined"
                            name="max"
                            margin="nomal"
                        />
                    </Grid>
                }
                <Grid item xs={4}>
                <FormControlLabel control={<Checkbox onChange={(e) => setAttributeDef({...attributeDef, required: e.target.checked})} checked={attributeDef.required} name="required" />} label="Povinné" />
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                    <br/>
                    <Button color="primary" disabled={!attributeDef.name} onClick={addAttribute} variant="outlined">Pridať atribút</Button>
                </Grid>
            </Grid>
            <br/>
            <br/>
            <Message gutterBottom msgId="layertree.layerFormAttributes" />
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="Atributes">
                    <TableHead>
                        <TableRow>
                            <TableCell>Názov</TableCell>
                            <TableCell>Typ</TableCell>
                            <TableCell>Obmedzenia</TableCell>                            
                            <TableCell></TableCell>                            
                            <TableCell></TableCell>                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attributes.filter(a => !HIDDEN_ATTRIBUTES.includes(a.name)).map((attribute) => (
                            <TableRow key={attribute.name}>
                                <TableCell component="th" scope="row">{attribute.name}</TableCell>
                                <TableCell>{attribute.type}</TableCell>
                                <TableCell>{attribute.min && "min. " + attribute.min} {attribute.max && "max. " + attribute.max} </TableCell>
                                <TableCell>{attribute.required && "* povinný"}</TableCell>
                                <TableCell><Button color="error" onClick={() => removeAttribute(attribute.name)}>Odstrániť</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )

    const renderBody = () => (
        <div className={classes.root}>
            <TextField
                label="Názov vrstvy"
                fullWidth
                size="small"
                variant="outlined"
                defaultValue={layer.title}
                error={errors.title}
                name="title"
                margin="nomal"
                inputRef={register({required: "Required"})}                
            />
            {/* <br/>
            <br/> */}
            {/* <TextField
                label="Popis"
                fullWidth
                size="small"
                margin="nomal"
                defaultValue={layer.description}
                error={errors.description}
                variant="outlined"
                name="description"
                inputRef={register}
                multiline
            />      */}
             <ExpansionPanel classes={{ root: classes.expandedExpansionPanel, expanded: classes.expandedExpansionPanel }} className={classes.expansionPanel} expanded={showAttributes} onChange={() => setShowAttributes(!showAttributes)}>
                <ExpansionPanelSummary
                    expandIcon={<MdExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                <Typography>
                    <Message gutterBottom msgId="redlining.configure_attributes" />
                </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                     {renderAttributes()}   
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <br/>
            <Button color="primary" variant="contained" onClick={handleSubmit(handleAddLayer)}>Potvrdiť</Button>
        </div>
    )

    return (
        <Window 
            // maxHeight={600}
            taskMode={false}
            modal
            position="center"
            maxWidth={600}
            id="NewLayer"
            onClose={() => {
                dispatch(toggleVectorLayerConfigDialog(false, {}))
                resetForm()
            }}
            variant="center"
            title="appmenu.items.NewLayer" icon="layers">
            {() => ({
                body: renderBody()
            })}
        </Window>
    );
};

VectorLayerDefinitionDialog.propTypes = {    
    onClose: func,
    addLayer: func
}

export default VectorLayerDefinitionDialog;
