import { useEffect } from "react";
import {useDrawInteraction} from "../hooks/useDrawInteraction"
import { useMap } from "../hooks/useMap";

const DrawInteraction = ({
    geometryType,
    drawEnd,
    drawStart,
    style,
    isFreeHand
}) => {

    const map = useMap()

    const { drawInteraction } = useDrawInteraction({
        // source, 
        type: geometryType, 
        isFreeHand,
        style,
        onDrawStart: (e) => {
            drawStart(e)
        }, 
        onDrawEnd: (e) => {
            drawEnd(e)
        }
    })

    useEffect(() => {
        map.addInteraction(drawInteraction);
        
        return () => {
            map.removeInteraction(drawInteraction);
        }
    }, [map, drawInteraction])


    return null;
        
}

DrawInteraction.propTypes = {

}

DrawInteraction.defaultProps = {
    geometryType: "Polygon",
    drawStart: () => {},
    drawEnd: () => {},
    isFreeHand: false
}

export default DrawInteraction