/**
* Copyright 2017, Sourcepole AG.
* All rights reserved.
*
* This source code is licensed under the BSD-style license found in the
* LICENSE file in the root directory of this source tree.
*/

import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from "goodmap-core"
import { injectIntl } from 'react-intl'

class FileSelector extends React.Component {
    static propTypes = {
        file: PropTypes.object,
        accept: PropTypes.string,
        onFileSelected: PropTypes.func
    }
    static contextTypes = {
        messages: PropTypes.object
    }
    constructor(props) {
        super(props);
        this.fileinput = null;
    }
    componentWillReceiveProps(newProps) {
        if(!newProps.file && this.fileinput) {
            this.fileinput.value = null;
        }
    }
    render() {
        let value = "";
        if(this.props.file) {
            value = this.props.file.name + " (" + this.humanFileSize(this.props.file.size) + ")";
        }

        let placeholder = this.props.intl.formatMessage({id: "fileselector.placeholder"});

        return (
            <div className={this.props.classes.root} onClick={this.triggerFileOpen}>
                <input type="hidden" readOnly={true} placeholder={placeholder} value={value}/>
                <div className={this.props.classes.placeholder}>
                    {placeholder}
                </div>
                <input type="file" className={this.props.classes.fileInput} onChange={this.fileChanged} accept={this.props.accept} ref={el => this.fileinput = el}/>
            </div>
        );
    }
    triggerFileOpen = (ev) => {
        if(this.fileinput) {
            this.fileinput.click();
        }
    }
    fileChanged = (ev) => {
        let file = null;
        if(ev.target.files && ev.target.files.length > 0) {
            file = ev.target.files[0];
        }
        this.props.onFileSelected(file);
    }
    humanFileSize(bytes) {
        const thresh = 1000;
        const units = ['B', 'kB','MB','GB','TB','PB','EB','ZB','YB']
        let u = 0;
        for(; bytes >= thresh && u < units.length; ++u) {
            bytes /= thresh;
        }
        return bytes.toFixed(1) + ' ' + units[u];
    }
};

const styles = (theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    fileInput: {
        display: "none"
    },
    placeholder: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        borderStyle: "dashed",
        borderWidth: 2,
        borderColor: theme.palette.grey[400]
    }
})

export default withStyles(styles)(injectIntl(FileSelector));
