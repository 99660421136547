/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

export const UPDATE_DRAWING_STATE = 'UPDATE_DRAWING_STATE';
export const UPDATE_DRAWING_STYLE = 'UPDATE_DRAWING_STYLE';
export const DRAWING_ENABLE = 'DRAWING_ENABLE';

export const updateDrawingState = (drawingState) => {
    return {
        type: UPDATE_DRAWING_STATE,
        data: drawingState
    };
}

export const updateDrawingStyle = (style) => {
    return {
        type: UPDATE_DRAWING_STYLE,
        style
    };
}

export const enableDrawing = (state) => {
    return {
        type: DRAWING_ENABLE,
        active: state
    };
}

