import { useEffect } from "react";
import ol_style_Style from 'ol/style/Style';
import ol_style_Circle from 'ol/style/Circle';
import ol_style_Fill from 'ol/style/Fill';
import ol_style_Stroke from 'ol/style/Stroke';
import ol_style_Text from 'ol/style/Text';
import { useMap } from "../hooks/useMap";
import { useSelectInteraction } from "../hooks/useSelectInteraction";

const style = new ol_style_Style({
    image: new ol_style_Circle({
      radius: 7,
      fill: new ol_style_Fill({ color: 'rgba(0, 255, 0, 0.1)' }),
      stroke: new ol_style_Stroke({ color: '#ff0000', width: 1 })
    }),
    fill: new ol_style_Fill({ color: 'rgba(0, 255, 0, 0.1)' }),
    stroke: new ol_style_Stroke({ color: '#00ff00', width: 1 }),
    text: new ol_style_Text({
      font: '12px Calibri,sans-serif',
      fill: new ol_style_Fill({ color: '#000' }),
      stroke: new ol_style_Stroke({ color: '#fff', width: 3 })
    })
  });

const SelectInteraction = ({
    layers,
    hitTolerance,
    onSelect
}) => {

    const map = useMap()

    const { selectInteraction } = useSelectInteraction({
        layers,
        hitTolerance,
        style,
        onSelect
    })

    useEffect(() => {
        map.addInteraction(selectInteraction);
        return () => {
            map.removeInteraction(selectInteraction);
        }
    }, [map, selectInteraction])

    return null;
        
}

SelectInteraction.propTypes = {

}

SelectInteraction.defaultProps = {
    hitTolerance: 5,
    layers: [],
    onSelect: () => {}
}

export default SelectInteraction