import { makeStyles } from "goodmap-core"
import React, {useCallback} from "react"
import { FaDrawPolygon } from 'react-icons/fa';
import { GiArrowCursor as GrCursor } from 'react-icons/gi';
import { MdColorLens, MdDelete, MdImportExport, MdPlace } from "react-icons/md"
import { RiEditLine } from "react-icons/ri"
import { VscActivateBreakpoints } from "react-icons/vsc"
import { BsPencilSquare } from "react-icons/bs"
import { Fab } from "goodmap-core"
import { useDispatch, useSelector } from "react-redux";
import { updateDrawingState } from "../../actions/drawing";
import { useIntl } from "react-intl";
import FileSaver from "file-saver";
import VectorLayerUtils from "../../utils/VectorLayerUtils";

const RedliningTools = ({
    handleShowControls,
    showControls
}) => {

    const { geomType, selectedFeature, action, layer, layers, mapCrs } = useSelector(state => ({
        layer: state.drawing.layer.id,
        geomType: state.drawing.geomType,
        selectedFeature: state.drawing.selectedFeature,
        action: state.drawing.action,
        mapCrs: state.map.projection,
        layers: state.layers.flat
    }))

    const dispatch = useDispatch()

    const classes = useStyles()

    const {formatMessage} = useIntl()

    const handleChangeAction = useCallback(({action, geomType, styleName = "default"}) => {
        if(geomType)
            dispatch(updateDrawingState({ action, geomType, styleName }))
        else
            dispatch(updateDrawingState({ action, styleName }))
    }, [dispatch])

    const isActive = useCallback((a, g) => {
        if(g === undefined && action === a){
            return true
        }
        if(action === a && geomType === g){
            return true
        }
        return false
    }, [action, geomType])

    /**
     * EXPORT GeoJSON
     */
    const exportRedliningLayerToJSON = () => {
        const gmLayer = layers.find(l => l.id === layer);

        const data = JSON.stringify({
            type: "FeatureCollection",
            definition: gmLayer.definition,
	        features: gmLayer.features.map(feature => ({...feature, geometry: VectorLayerUtils.reprojectGeometry(feature.geometry, feature.crs || mapCrs, 'EPSG:4326')}))
        }, null, ' ');
        FileSaver.saveAs(new Blob([data], {type: "text/plain;charset=utf-8"}), gmLayer.title + ".json");
    }

    return (
        <div className={classes.root}>
           {/* <Fab 
                tooltip={formatMessage({id: "redlining.marker"})}
                className={isActive("Draw", "Marker") ? classes.activeFab : classes.fab} 
                size="small" 
                color="primary" 
                onClick={() => handleChangeAction({ 
                    action: "Draw", 
                    styleName: "marker",
                    geomType: "Marker" 
                })} 
                aria-label="marker"
            >
                <MdPlace size={16}/>
            </Fab>  */}
            <Fab 
                tooltip={formatMessage({id: "redlining.point"})}
                className={isActive("Draw", "Point") ? classes.activeFab : classes.fab} 
                size="small" 
                color="primary" 
                onClick={() => handleChangeAction({ action: "Draw", geomType: "Point" })} 
                aria-label="point"
            >
                <VscActivateBreakpoints size={16}/>
            </Fab>
            <Fab 
                tooltip={formatMessage({id: "redlining.line"})}
                className={isActive("Draw", "LineString") ? classes.activeFab : classes.fab} 
                size="small" 
                color="primary" 
                onClick={() => handleChangeAction({ action: "Draw", geomType: "LineString" })} 
                aria-label="line"
            >
                    <RiEditLine size={16}/>
            </Fab>
            <Fab 
                tooltip={formatMessage({id: "redlining.polygon"})}
                className={isActive("Draw", "Polygon") ? classes.activeFab : classes.fab} 
                size="small" 
                color="primary" 
                onClick={() => handleChangeAction({ action: "Draw", geomType: "Polygon" })} 
                aria-label="polygon">
                    <FaDrawPolygon size={16} />
            </Fab>
            {/* <Fab 
                tooltip={formatMessage({id: "redlining.text"})}
                className={isActive("Draw", "Text") ? classes.activeFab : classes.fab} 
                size="small" 
                color="primary" 
                onClick={() => handleChangeAction({ action: "Draw", geomType: "Text" })} 
                aria-label="text"
            >
                <BiText size={16}/>
            </Fab> */}
            {/* <Divider className={classes.divider} orientation="vertical" flexItem/> */}
            <Fab 
                tooltip={formatMessage({id: "redlining.pick"})}
                className={isActive("Pick") ? classes.activeFab : classes.fab} 
                size="small" 
                color="primary" 
                onClick={() => handleChangeAction({ action: "Pick", geomType: null })} 
                aria-label="text"
            >
                <GrCursor size={16}/>
            </Fab>
            <Fab 
                tooltip={formatMessage({id: "redlining.attributes"})}
                className={isActive("ShowAttributes", null) ? classes.activeFab : classes.fab} 
                disabled={!selectedFeature}
                size="small" 
                color="primary" 
                onClick={() => handleChangeAction({ action: "ShowAttributes" })} 
                aria-label="text"
            >
                <BsPencilSquare fontSize="1.6em" />
            </Fab>
            <Fab 
                tooltip={formatMessage({id: "redlining.delete"})}
                disabled={!selectedFeature} 
                className={isActive("Delete", null) ? classes.activeFab : classes.fab} 
                size="small" 
                color="primary" 
                onClick={() => handleChangeAction({ action: "Delete", geomType: null })} aria-label="text"
            >
                <MdDelete fontSize={"1.6em"}/>
            </Fab>
            <Fab 
                tooltip={formatMessage({id: "redlining.styles"})}
                className={showControls ? classes.activeFab : classes.fab} 
                size="small" 
                color="primary" 
                onClick={handleShowControls} 
                aria-label="text"
            >
                <MdColorLens fontSize={"1.6em"}/>
            </Fab>
            <Fab 
                tooltip={formatMessage({id: "layertree.export"})}
                className={classes.fab} 
                size="small" 
                color="primary" 
                onClick={() => exportRedliningLayerToJSON()} 
                aria-label="export"
            >
                <MdImportExport fontSize={"1.6em"}/>
            </Fab>
        </div>
    )
}

RedliningTools.defaultProps = {

}

RedliningTools.propTypes = {
    
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    fab: {
        marginLeft: 4,
        background: "#0f84c8",
        marginRight: 4,
        "&:hover": {
            background: theme.palette.primary.dark,
        }
    }, 
    activeFab: {
        marginLeft: 4,
        marginRight: 4,
        background: theme.palette.primary.dark,
        "&:hover": {
            background: theme.palette.primary.dark,
        }
    },
    divider: {
        marginRight: 8,
        marginLeft: 8
    }
}))

export default RedliningTools