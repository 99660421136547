import React from "react";
import appTheme from "./theme";
import AppConfig from "./AppConfig";
import GoodmapApp from "./GoodmapApp";
import config from "./config";
import { GoodmapProvider } from "goodmap-core";
// import { useSearchParam } from "react-use";
import Logo from "../assets/logo.png"
import SkymoveLogo from "../assets/logo-skymove.png"
import BgImage from "../assets/gis-bg-image.jpg"

const Goodmap = () => {
  // const tenant = useSearchParam("tenant") || config.defaultTenant;

  return (
    <GoodmapProvider 
      theme={appTheme} 
      showTenantPicker
      logo={Logo}
      bgImage={BgImage}
      logoSecondary={SkymoveLogo}
      tenantPickerProps={{description: "Vaše konto má priradených viac aplikácií. Vyberte aplikáciu, do ktorej sa chcete prihlásiť?"}}
      config={config}
    >
      <AppConfig>
        {({config, general, theme}) => {
          return (
              <GoodmapApp appConfig={{config, general, theme}} />
          )
        }}
      </AppConfig>
    </GoodmapProvider>
  )
}

export default Goodmap;