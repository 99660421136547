import React from "react"
import IntlProvider from '../components/I18N/Localized';
import {Provider as ReduxProvider} from "react-redux";
import MapProvider from "../ol/Map";


const Provider = ({
    store,
    children
}) => {

    return (
        <ReduxProvider store={store}>
            <IntlProvider>
                <MapProvider>
                    {children}
                </MapProvider>
            </IntlProvider>
        </ReduxProvider>
    )
}

export default Provider