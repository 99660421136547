/* eslint-disable */
/**
 * Copyright 2020, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {addLayer} from '../actions/layers'
import LayerUtils from '../utils/LayerUtils'
import ServiceLayerUtils from '../utils/ServiceLayerUtils'
import { useEffect } from 'react'

const API = ({
    addLayer
}) => {
   
    const addExternalLayer = (resource, beforeLayerName=null) => {
        let params = LayerUtils.splitLayerUrlParam(resource);
        ServiceLayerUtils.findLayers(params.type, params.url, [params], (id, layer) => {
            if(layer) {
                addLayer(layer, null, beforeLayerName);
            }
        });
    }

    useEffect(() => {
        window.qwc2 = {};
        window.qwc2.addExternalLayer = addExternalLayer
    }, [])
   
    return null;

};

API.propTypes = {
    addLayer: PropTypes.func,
    layers: PropTypes.array
}

export default {
    APIPlugin: connect(state => ({
        layers: state.layers.flat
    }), {
        addLayer
    })(API)
};
