/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setCurrentTask,setCurrentTaskBlocked} from "../actions/task";
import {withStyles} from "goodmap-core";
import Toolbar from './Toolbar';

const styles = (theme) => ({
    root: {
        
    }
})

const TaskBar = ({
    task,
    currentTask,
    onShow,
    onHide,
    extraContent,
    setCurrentTask,
    disableBackground,
    setCurrentTaskBlocked,
    unblockOnClose,
    onClose,
    children
}) => {
    
    useEffect(() => {
        if(currentTask.id === task){
            onShow(currentTask.mode);
        }
    }, [currentTask.id, currentTask.mode, task])

    useEffect(() => {
        if(currentTask.id !== task)
            onHide();
    }, [currentTask.id, currentTask.mode, task])

    const closeClicked = () => {
        if(currentTask.id === task) {
            if(unblockOnClose) {
                setCurrentTaskBlocked(false);
            }
            setCurrentTask(null);
            if(typeof onClose === "function"){
                onClose()
            }
        }
    }
   
    if(currentTask.id !== task) {
        return null;
    }
    return (
        <Toolbar disableBackground={disableBackground} extraContent={extraContent} onHide={closeClicked} className={task}>
            {children}
        </Toolbar>
    );
};

const selector = (state) => ({
    currentTask: state.task
});

TaskBar.defaultProps = {
    onShow: (mode) => {},
    onHide: () => {},
    unblockOnClose: false
}

TaskBar.propTypes = {
    task: PropTypes.string.isRequired,
    currentTask: PropTypes.object,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    extraContent: PropTypes.element,
    setCurrentTask: PropTypes.func,
    setCurrentTaskBlocked: PropTypes.func,
    unblockOnClose: PropTypes.bool,
    onClose: PropTypes.func
}

const TaskBarWithStyles = withStyles(styles)(TaskBar)


export default connect(selector, {
    setCurrentTask: setCurrentTask,
    setCurrentTaskBlocked: setCurrentTaskBlocked
})(TaskBarWithStyles)

// reducers: {
//     task: require('../reducers/task')
// }
