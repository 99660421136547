/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { Loader, Dialog, TextField, MenuItem, withStyles, Button, Divider, useApi } from 'goodmap-core';
import Window from '../../components/Window';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import assign from 'object-assign';
import isEmpty from 'lodash.isempty';
import FileSaver from 'file-saver';
import {default as formDataEntries} from 'form-data-entries';
import Message from '../../components/I18N/Message';
import MapUtils from '../../utils/MapUtils';
import CoordinatesUtils from '../../utils/CoordinatesUtils';
import {LayerRole} from '../../actions/layers';
import {changeRotation as changeRotationAction}  from '../../actions/map';
import ToggleSwitch from '../../components/widgets/ToggleSwitch';
import Icon from '../../components/Icon';
import PrintFrame from '../../components/PrintFrame';
import VectorLayerUtils from '../../utils/VectorLayerUtils';
import clsx from "clsx"
import {MdPrint} from "react-icons/md"
import '../style/Print.css';
import { useIntl } from 'react-intl';

const Print = ({
    theme,
    map,
    layers,
    printExternalLayers, // Caution: requires explicit server-side support!
    changeRotation,
    inlinePrintOutput,
    scaleFactor,
    defaultDpi,
    defaultScaleFactor,
    classes,
    displayRotation,
    displayGrid
}) => {
    
    const [state, setState] = useState({
        layout: null,
        scale: null,
        dpi: 300,
        initialRotation: 0,
        grid: false,
        rotationNull: false,
        minimized: false,
        printOutputVisible: false,
        outputLoaded: false,
        printing: false
    })

    const [, fetchPrint] = useApi({}, {manual: true})
    const { formatMessage } = useIntl();
    const printForm = useRef(null)
    // const [printForm, setPrintForm] = useState(null)

    // useEffect(() => {
    //     setPrintForm(null)
    // }, [])

    useEffect(() => {
        // if(!state.layout){
            let layout = null;
            if(theme && theme.print && theme.print.length > 0) {
                layout = theme.print.find(layout => layout.default) || theme.print[0];
            }
            handleChangeState({layout: layout});
        // }
    }, [theme, state.layout])

    const onShow = () => {
        let scale = Math.round(MapUtils.computeForZoom(map.scales, map.zoom) * defaultScaleFactor);
        if(theme.printScales && theme.printScales.length > 0) {
            let closestVal = Math.abs(scale - theme.printScales[0]);
            let closestIdx = 0;
            for(let i = 1; i < theme.printScales.length; ++i) {
                let currVal = Math.abs(scale - theme.printScales[i]);
                if(currVal < closestVal) {
                    closestVal = currVal;
                    closestIdx = i;
                }
            }
            scale = theme.printScales[closestIdx];
        }
        handleChangeState({scale: scale, initialRotation: map.bbox.rotation, dpi: defaultDpi});
    }

    const onHide = () => {
        changeRotation(state.initialRotation);
        handleChangeState({minimized: false, scale: null});
    }

    const renderBody = () => {     
        if(!state.layout) {
            return (<div role="body" className="print-body"><Message msgId="print.nolayouts" /></div>);
        }
        let themeLayers = layers.filter(layer => layer.role === LayerRole.THEME);
        if(!theme || (!printExternalLayers && isEmpty(themeLayers))) {
            return (<div role="body" className="print-body"><Message msgId="print.notheme" /></div>);
        }
        let printLayers = [];
        let printOpacities = [];
        let printColors = [];
        for(let layer of layers) {
            if(layer.role === LayerRole.THEME && layer.params.LAYERS) {
                printLayers.push(layer.params.LAYERS);
                printOpacities.push(layer.params.OPACITIES);
                printColors.push(layer.params.LAYERS.split(",").map(entry => "").join(","));
            } else if(printExternalLayers && layer.role === LayerRole.USERLAYER && layer.visibility && (layer.type === "wms" || layer.type === "wfs")) {
                printLayers.push(layer.type + ':' + layer.url + "#" + layer.name);
                printOpacities.push(layer.opacity);
                printColors.push(layer.color ? layer.color : "");
            }
        }

        let currentLayoutname = state.layout ? state.layout.name : "";
        let mapName = state.layout ? state.layout.map.name : "";

        let backgroundLayer = layers.find(layer => layer.role === LayerRole.BACKGROUND && layer.visibility === true);
        let backgroundLayerName = backgroundLayer ? backgroundLayer.name : null;
        let themeBackgroundLayer = theme.backgroundLayers.find(entry => entry.name === backgroundLayerName);
        let printBackgroundLayer = themeBackgroundLayer ? themeBackgroundLayer.printLayer : null;
        if(printBackgroundLayer) {
            let printBgLayerName = printBackgroundLayer;
            if(Array.isArray(printBackgroundLayer)) {
                printBgLayerName = null;
                for(let i = 0; i < printBackgroundLayer.length; ++i) {
                    printBgLayerName = printBackgroundLayer[i].name;
                    if(state.scale <= printBackgroundLayer[i].maxScale) {
                        break;
                    }
                }
            }
            if(printBgLayerName) {
                printLayers.push(printBgLayerName);
                printOpacities.push("255");
                printColors.push("");
            }
        }
        printLayers = printLayers.reverse().join(",");
        printOpacities = printOpacities.reverse().join(",");
        printColors = printColors.reverse().join(",");

        let formvisibility = 'hidden';
        let printDpi = parseInt(state.dpi);
        let mapCrs = map.projection;
        let version = theme.version || "1.3.0";
        let extent = computeCurrentExtent();
        extent = (CoordinatesUtils.getAxisOrder(mapCrs).substr(0, 2) == 'ne' && version == '1.3.0') ?
            extent[1] + "," + extent[0] + "," + extent[3] + "," + extent[2]:
            extent.join(',');
        let rotation = state.rotationNull ? "" : map.bbox ? Math.round(map.bbox.rotation / Math.PI * 180.) : 0;
        let scaleChooser = (<input name={mapName + ":scale"} type="number" value={state.scale || ""} onChange={changeScale} min="1"/>);

        if(theme.printScales && theme.printScales.length > 0) {
            scaleChooser = (
                <TextField variant="outlined" size="small" fullWidth select name={mapName + ":scale"} value={state.scale || ""} onChange={changeScale}>
                    {theme.printScales.map(scale => (<MenuItem key={scale} value={scale}>1:{scale}</MenuItem>))}
                </TextField>);
        }
        let resolutionChooser = null;
        let resolutionInput = null;
        if(!isEmpty(theme.printResolutions)) {
            if(theme.printResolutions.length > 1) {
                resolutionChooser = (
                    <TextField variant="outlined" size="small" fullWidth select name={"DPI"} value={state.dpi || ""} onChange={changeResolution}>
                        {theme.printResolutions.map(res => (<MenuItem key={res} value={res}>{res} DPI</MenuItem>))}
                    </TextField>);
            } else {
                resolutionInput = (<input name="DPI" readOnly={true} type={formvisibility} value={theme.printResolutions[0]}/>);
            }
        } else {
            resolutionChooser = (<input name="DPI" type="number" value={state.dpi || ""} onChange={changeResolution} min="50" max="1200"/>)
        }

        let gridIntervalX = null;
        let gridIntervalY = null;
        let printGrid = theme.printGrid;
        if(printGrid && printGrid.length > 0 && state.scale && state.grid) {
            let cur = 0;
            for(; cur < printGrid.length-1 && state.scale < printGrid[cur].s; ++cur);
            gridIntervalX = (<input readOnly={true} name={mapName + ":GRID_INTERVAL_X"} type={formvisibility} value={printGrid[cur].x} />);
            gridIntervalY = (<input readOnly={true} name={mapName + ":GRID_INTERVAL_Y"} type={formvisibility} value={printGrid[cur].y} />);
        }

        let labels = state.layout && state.layout.labels ? state.layout.labels : [];

        let highlightParams = VectorLayerUtils.createPrintHighlighParams(layers, mapCrs, printDpi, scaleFactor);

        return (
            <div className={clsx("print-body", classes.form)}>
                <form action={theme.printUrl} method="POST"
                    target="print-output-window" ref={printForm}
                     onSubmit={print}>
                    <table className={clsx("options-table", classes.table)}><tbody>
                        <tr>
                            <td><Message msgId="print.layout" /></td>
                            <td>
                                <TextField variant="outlined" size="small" fullWidth select name="TEMPLATE" onChange={changeLayout} value={currentLayoutname}>
                                    {theme.print.map(item => {
                                        return (
                                            <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </td>
                        </tr>
                        <tr>
                            <td><Message msgId="print.scale" /></td>
                            <td>
                                <span className="input-frame">
                                    {scaleChooser}
                                </span>
                            </td>
                        </tr>
                        {resolutionChooser ? (
                            <tr>
                                <td><Message msgId="print.resolution" /></td>
                                <td>
                                    <span className="input-frame">
                                        {resolutionChooser}
                                    </span>
                                </td>
                            </tr>
                        ) : null}
                        {displayRotation == true ? (
                            <tr>
                                <td><Message msgId="print.rotation" /></td>
                                <td>
                                    <span className="input-frame">
                                        <TextField variant="outlined" size="small" fullWidth name={mapName + ":rotation"} type="number" value={rotation} onChange={handleChangeRotation}/>
                                    </span>
                                </td>
                            </tr>
                        ) : null}
                        {printGrid && displayRotation == true ? (
                            <tr>
                                <td><Message msgId="print.grid" /></td>
                                <td>
                                    <ToggleSwitch onChange={(newstate) => handleChangeState({grid: newstate})} active={state.grid} />
                                </td>
                            </tr>
                        ) : null}
                        {(labels || []).map(label => {
                            let opts = assign({rows: 1, name: label.toUpperCase()}, theme.printLabelConfig ? theme.printLabelConfig[label] : {});
                            return (<tr key={"label." + label}>
                                <td>{label}:</td>
                                <td>
                                    {
                                        theme.printLabelForSearchResult === label && search ?
                                            (<textarea {...opts} defaultValue={search.markerLabel}/>)
                                        :
                                            (<textarea {...opts}/>)
                                    }
                                </td>
                            </tr>)
                        })}
                    </tbody></table>
                    <div>
                        <input readOnly={true} name={mapName + ":extent"} type={formvisibility} value={extent || ""} />
                        <input readOnly={true} name="SERVICE" type={formvisibility} value="WMS" />
                        <input readOnly={true} name="VERSION" type={formvisibility} value={version || "1.3.0"} />
                        <input readOnly={true} name="REQUEST" type={formvisibility} value="GetPrint" />
                        <input readOnly={true} name="FORMAT" type={formvisibility} value="pdf" />
                        <input readOnly={true} name="TRANSPARENT" type={formvisibility} value="true" />
                        <input readOnly={true} name="SRS" type={formvisibility} value={mapCrs} />
                        {!isEmpty(themeLayers) && themeLayers[0].params.MAP ? (<input readOnly={true} name="MAP" type={formvisibility} value={themeLayers[0].params.MAP} />) : null}
                        <input readOnly={true} name="OPACITIES" type={formvisibility} value={printOpacities || ""} />
                        {/* This following one is needed for opacities to work!*/}
                        <input readOnly={true} name="LAYERS" type={formvisibility} value={printLayers || ""} />
                        <input readOnly={true} name="COLORS" type={formvisibility} value={printColors || ""} />
                        <input readOnly={true} name={mapName + ":LAYERS"} type={formvisibility} value={printLayers || ""} />
                        <input readOnly={true} name={mapName + ":HIGHLIGHT_GEOM"} type={formvisibility} value={highlightParams.geoms.join(";")} />
                        <input readOnly={true} name={mapName + ":HIGHLIGHT_SYMBOL"} type={formvisibility} value={highlightParams.styles.join(";")} />
                        <input readOnly={true} name={mapName + ":HIGHLIGHT_LABELSTRING"} type={formvisibility} value={highlightParams.labels.join(";")} />
                        <input readOnly={true} name={mapName + ":HIGHLIGHT_LABELCOLOR"} type={formvisibility} value={highlightParams.labelFillColors.join(";")} />
                        <input readOnly={true} name={mapName + ":HIGHLIGHT_LABELBUFFERCOLOR"} type={formvisibility} value={highlightParams.labelOultineColors.join(";")} />
                        <input readOnly={true} name={mapName + ":HIGHLIGHT_LABELBUFFERSIZE"} type={formvisibility} value={highlightParams.labelOutlineSizes.join(";")} />
                        <input readOnly={true} name={mapName + ":HIGHLIGHT_LABELSIZE"} type={formvisibility} value={highlightParams.labelSizes.join(";")} />
                        {gridIntervalX}
                        {gridIntervalY}
                        {resolutionInput}
                    </div>
                    <br/>
                    <Divider/>
                    <br/>
                    <div className="button-bar">
                        <Button endIcon={<MdPrint/>} iconEnd variant="contained" color="primary" size="small" type="submit" disabled={!printLayers || state.printing}>
                            {state.printing ? (<span className="print-wait"><Loader /> <Message msgId="print.wait" /></span>) : (<Message msgId="print.submit" />)}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    const renderPrintFrame = () => {
        let printFrame = null;
        if(state.layout) {
            let frame = {
                width: state.scale * state.layout.map.width / 1000.,
                height: state.scale * state.layout.map.height / 1000.,
            };
            printFrame = (<PrintFrame key="PrintFrame" map={map} fixedFrame={frame} />);
        }
        return printFrame;
    }

    const renderPrintOutputWindow = () => {
        return (
            <Dialog key="PrintOutputWindow" title="print.output" icon="print"
                initialWidth={0.5 * window.innerWidth} initialHeight={0.75 * window.innerHeight}
                onClose={() => handleChangeState({printOutputVisible: false, outputLoaded: false})} visible={state.printOutputVisible}
            >
            <div role="body" className="print-output-window-body">
                {!state.outputLoaded ? (
                    <span className="print-output-window-wait">
                        <Loader /> <Message msgId="print.wait" />
                    </span>
                ) : null}
                <iframe name="print-output-window" onLoad={() => handleChangeState({outputLoaded: true})}/>
            </div>
        </Dialog>
        )
    }

    const handleChangeState = (diff) => {
        setState((s) => ({
            ...s,
            ...diff
        }))
    }

    const changeLayout = (ev) => {
        let layout = theme.print.find(item => item.name == ev.target.value);
        handleChangeState({layout: layout});
    }

    const changeScale = (ev) => {
        handleChangeState({scale: ev.target.value});
    }

    const changeResolution = (ev) => {
        handleChangeState({dpi: ev.target.value});
    }

    const handleChangeRotation = (ev) => {
        if(!ev.target.value) {
            handleChangeState({rotationNull: true});
        } else {
            handleChangeState({rotationNull: false});
            let angle = parseFloat(ev.target.value) || 0;
            while(angle < 0) {
                angle += 360;
            }
            while(angle >= 360) {
                angle -= 360;
            }
            changeRotation(angle / 180. * Math.PI);
        }
    }

    const computeCurrentExtent = () => {
        if(!map || !state.layout || !state.scale) {
            return [0, 0, 0, 0];
        }
        let center = map.center;
        let widthm = state.scale * state.layout.map.width / 1000.;
        let heightm = state.scale * state.layout.map.height / 1000.;
        let {width, height} = MapUtils.transformExtent(map.projection, center, widthm, heightm);
        let x1 = center[0]- 0.5 * width;
        let x2 = center[0] + 0.5 * width;
        let y1 = center[1] - 0.5 * height;
        let y2 = center[1] + 0.5 * height;
        return [x1, y1, x2, y2];
    }

    const print = (ev) => {
        if(inlinePrintOutput) {
            handleChangeState({printOutputVisible: true, outputLoaded: false});
        } else {
            ev.preventDefault();
            handleChangeState({printing: true});
            let formData = formDataEntries(printForm.current);
            let data = Array.from(formData).map(pair =>
                pair.map(entry => encodeURIComponent(entry).replace(/%20/g,'+')).join("=")
            ).join("&");
           
            fetchPrint({
                url: theme.printUrl, 
                data, 
                method: "POST",
                headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                responseType: "arraybuffer"
            }).then(response => {
                handleChangeState({printing: false});
                let contentType = response.headers["content-type"];
                FileSaver.saveAs(new Blob([response.data], {type: contentType}), theme.name + '.pdf');
            }).catch(e => {
                handleChangeState({printing: false});
                if(e.response) {
                    console.log(new TextDecoder().decode(e.response.data));
                }
                alert('Print failed');
            });
        }
    }

    let minMaxTooltip = formatMessage({id: state.minimized ? "print.maximize" : "print.minimize"});
    let extraTitlebarContent = (<Icon title={minMaxTooltip} className="print-minimize-maximize" icon={state.minimized ? 'chevron-down' : 'chevron-up'} onClick={ev => handleChangeState({minimized: !state.minimized})}/>)
        
    return (
        <Window 
            padding={1} 
            modal={false} 
            variant="left" 
            id="Print" 
            onShow={onShow} 
            onHide={onHide}
            width="20em" 
            title="appmenu.items.Print" 
            icon={"print"}
            // extraTitlebarContent={extraTitlebarContent}
            >
            {() => ({
                body: state.minimized ? null : renderBody(),
                extra: [
                    renderPrintFrame(),
                    inlinePrintOutput ? renderPrintOutputWindow() : null
                ]
            })}
        </Window>
    );

    
};

const selector = (state) => ({
    theme: state.theme ? state.theme.current : null,
    map: state.map ? state.map : null,
    layers: state.layers ? state.layers.flat : [],
    search: state.search
});

const styles = () => ({
    table: {
        width: "100%"
    },
    form: {        
        width: "100%"
    }
})

Print.defaultProps = {
    printExternalLayers: false,
    inlinePrintOutput: false,
    scaleFactor: 1.9, // Experimentally determined...
    defaultDpi: 300,
    defaultScaleFactor: 0.5,
    displayRotation: true,
    displayGrid: true
}

Print.propTypes = {
    theme: PropTypes.object,
    map: PropTypes.object,
    layers: PropTypes.array,
    printExternalLayers: PropTypes.bool, // Caution: requires explicit server-side support!
    changeRotation: PropTypes.func,
    inlinePrintOutput: PropTypes.bool,
    scaleFactor: PropTypes.number,
    defaultDpi: PropTypes.number,
    defaultScaleFactor: PropTypes.number,
    displayRotation: PropTypes.bool,
    displayGrid: PropTypes.bool
}

export default {
    PrintPlugin: connect(selector, {
        changeRotation: changeRotationAction
    })(withStyles(styles)(Print))
}
