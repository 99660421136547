import ol_interaction_Modify from 'ol/interaction/Modify';
import { useEffect, useMemo } from "react";
import ol_Collection from "ol/Collection"

export const useModifyInteraction = ({ 
    features, 
    onModifyStart,
    onModifyEnd
}) => {

    const modifyInteraction = useMemo(() => {
        return new ol_interaction_Modify({
            features: new ol_Collection(features),
            condition: (event) => {  return event.pointerEvent.buttons === 1 },
        })
    }, [features])
   

    useEffect(() => {
        modifyInteraction.on('modifystart', onModifyStart);
        modifyInteraction.on('modifyend', onModifyEnd);
        return () => {            
            modifyInteraction.un('modifystart', onModifyStart);
            modifyInteraction.un('modifyend', onModifyEnd);
        }
    }, [modifyInteraction, onModifyStart, onModifyEnd])

    return { modifyInteraction }  
  
   
}