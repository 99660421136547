/* eslint-disable */
// import '@formatjs/intl-relativetimeformat/locale-data/sk';
// import '@formatjs/intl-relativetimeformat/locale-data/pt';
// import '@formatjs/intl-relativetimeformat/locale-data/it';
// import '@formatjs/intl-relativetimeformat/locale-data/en';
// import '@formatjs/intl-relativetimeformat/locale-data/fr';
// import '@formatjs/intl-relativetimeformat/locale-data/de';
// import '@formatjs/intl-relativetimeformat/locale-data/ro';
// import '@formatjs/intl-relativetimeformat/locale-data/ru';

export default {
    "sk": {
        code: "sk-SK",
        description: "Slovak"
     },
     "pt": {
         code: "pt-BR",
         description: "Português Brasil",
     },
     "it": {
         code: "it-IT",
         description: "Italiano",
     },
     "en": {
        code: "en-US",
        description: "English",
     },
     "fr": {
       code: "fr-FR",
       description: "Français",
    },
    "de": {
        code: "de-DE",
        description: "Deutsch",
    },
    "ro": {
        code: "ro-RO",
        description: "Română",
    },
    "ru": {
        code: "ru-RU",
        description: "Русский",
    }
}