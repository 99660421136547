/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {changeZoomLevel} from '../actions/map';
import { useIntl } from "react-intl";
import MapButton from "../components/MapButton"

const ZoomButton = ({
    currentZoom,
    position,
    changeZoomLevel,
    maxZoom,
    direction
}) => {
   
    const { formatMessage } = useIntl()
  
    let pos = position >= 0 ? position : (direction > 0 ? 4 : 3);
    let disabled = false;
    if(direction > 0) {
        disabled = currentZoom >= maxZoom;
    } else if(direction < 0) {
        disabled = currentZoom <= 0;
    }

    const tooltip = formatMessage({id: direction > 0 ? "tooltip.zoomin" : "tooltip.zoomout"});
    return (
        <MapButton 
            icon={direction > 0 ? "FaPlus" : "FaMinus"}
            type="fa"
            tooltip={tooltip} 
            disabled={disabled}
            position={{bottom: (`${5 + 3.5 * pos}em`), right: 8}} 
            onClick={ev => changeZoomLevel(currentZoom + direction)}
        />
    );
};

ZoomButton.propTypes = {
    currentZoom : PropTypes.number,
    position: PropTypes.number,
    changeZoomLevel: PropTypes.func,
    maxZoom: PropTypes.number,
    direction: PropTypes.number
}

ZoomButton.defaultProps = {

}

export default {
    ZoomInPlugin: connect((state) => ({
        currentZoom: state.map.zoom,
        maxZoom: state.map.resolutions.length - 1,
        direction: +1
    }),{
        changeZoomLevel: changeZoomLevel
    })(ZoomButton),
    ZoomOutPlugin: connect((state) => ({
        currentZoom: state.map.zoom,
        maxZoom: state.map.resolutions.length - 1,
        direction: -1
    }),{
        changeZoomLevel: changeZoomLevel
    })(ZoomButton),
    reducers: {
        map: require("../reducers/map")
    }
};
