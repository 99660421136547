/* eslint-disable */
/**
 * Copyright 2019, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from "goodmap-core"
import classnames from "classnames";
import { Fab } from "goodmap-core"
import {MdClose} from 'react-icons/md';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const styles = (theme) => ({
    root: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
        margin: "auto",
        textAlign: "center",
        // pointerEvents: "none",
        zIndex: 5,
        display: "flex",
        alignItems: "top",
        [theme.breakpoints.down("sm")]: {
            zIndex: 99999
        }
    },
    content: {
        background: "rgba(128,128,128,0.2)",
        display: "flex",
        borderRadius: 90,
        alignItems: "center",
        padding: theme.spacing(1)
    },
    closeButtonContainer: {
        display: "flex",
        paddingTop: 8,
        alignItems: "start",
    },
    closeButton: {
        marginLeft: theme.spacing(1),
        background: theme.palette.error.main,
        "&:hover": {
            background: theme.palette.error.dark,
        }
    }
})

const ToolTaskBar = ({
    onHide,
    className,
    classes,
    disableBackground,
    extraContent,
    children,
    hideOnTaskChange,
    task
}) => {
   
    const { formatMessage } = useIntl()

    useEffect(() => {
        if(hideOnTaskChange){
            onHide();
        }
    }, [task])

    return (
        <>
            <div className={classnames(classes.root, className)}>
                <div className={!disableBackground && classes.content}>
                {children}
                </div>
                <div className={classes.closeButtonContainer}>
                    <Fab 
                        tooltip={formatMessage({id: "general.close"})}
                        className={classes.closeButton} 
                        size="small" 
                        color="primary"
                        onClick={onHide} 
                        aria-label="text"
                    >
                        <MdClose fontSize={"1.6em"}/>
                    </Fab>
                </div>
            </div>
            {extraContent}
        </>
    );
};

ToolTaskBar.propTypes = {
    onHide: PropTypes.func,
    className: PropTypes.string,
    hideOnTaskChange: PropTypes.bool,
    task: PropTypes.string
}

ToolTaskBar.defaultProps = {
    onHide: () => {},
    hideOnTaskChange: false,
    disableBackground: false
}


const selector = (state) => ({
    task: state.task ? state.task.id : null
});

const ToolTaskBarWithStyles = withStyles(styles)(ToolTaskBar)

export default connect(selector, {})(ToolTaskBarWithStyles)
