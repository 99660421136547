
import React from "react"
import { withStyles } from "goodmap-core"
import Icon from "../../components/Icon"
import Message from "../../components/I18N/Message"
import ImportLayer from "../../components/ImportLayer";

const LayerTreeActions = ({
    maptipsEnabled,
    allowCompare,
    maptipcheckboxstate,
    swipecheckboxstate,
    importvisible,
    allowImport,
    toggleMapTips,
    classes,
    theme,
    toggleSwipe,
    toggleImportLayers
}) => {

    return (
        <span className={classes.root}>
            {maptipsEnabled &&
                <div className={classes.layerTreeAction}>
                    <Icon fontSize="1.6em" icon={maptipcheckboxstate} onClick={toggleMapTips} />
                    <span onClick={toggleMapTips}><Message msgId="layertree.maptip" /></span>
                </div>
            }
            {allowCompare && 
                <div className={classes.layerTreeAction}>
                    <Icon fontSize="1.6em" icon={swipecheckboxstate} onClick={toggleSwipe} />
                    <span onClick={toggleSwipe}><Message msgId="layertree.compare" /></span>
                </div>
            }
            {allowImport &&
                <>
                <div className={classes.layerTreeAction} onClick={toggleImportLayers}>
                    <Icon fontSize="1.6em" icon={importvisible ? 'MdExpandLess' : 'MdExpandMore'} /> 
                    <span>
                        <Message msgId="layertree.importlayer" />
                    </span>
                </div>
                {importvisible ? (<ImportLayer theme={theme} />) : null}
                </>
            }
        </span>
    )
}

const styles = (theme) => ({
    root: {

    },
    layerTreeAction: {
        display: "flex",
        marginTop: theme.spacing(1),
        alignItems: "center",
        "& > span": {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1)
        }
    }
})

export default withStyles(styles)(LayerTreeActions)