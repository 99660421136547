import React, { useEffect } from "react"
import { 
    makeStyles,
    Grid,
    TextField,
    MenuItem,
    Button,
    useApi
} from "goodmap-core"
import { addLayerFeatures, LayerRole } from '../../actions/layers';
import { useDispatch } from "react-redux";
import AvailableCadastreStatesSelect from "./AvailableCadastreStatesSelect";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        maxWidth: 300,
        width: "100%",
        boxShadow: theme.shadows[1],
        borderRadius: 4,
        background: theme.palette.common.white
    }
}))


const getGeometryType = (value) => {
    if(value === 1){
        return "Point"
    } else if (value === 2){
        return "LineString"
    } else if (value === 3){
        return "Polygon"
    }
    return "Polygon"
}

const CadastreAnalyseForm = ({
    handleChange,
    state,
    task,
    selectedLayer,
    layerParcelsC,
    layerParcelsE,
    layers,
    analyse
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const [, fetchAvailableStates] = useApi({}, {manual: true})
   

    useEffect(() => {
        const fetchCadastralYears = async () => {
            const result = await fetchAvailableStates({url: `/cadastre/availableStates`});
            handleChange({
                availableDates: result.data.items,
                selectedDate: result.data.items[0]
            })
        }

        if(task.id === "CadastreAnalyse")
            fetchCadastralYears();

        return () => {
            dispatch(addLayerFeatures(layerParcelsE, [], true));
            dispatch(addLayerFeatures(layerParcelsC, [], true));
        }
    }, [task.id, dispatch, fetchAvailableStates, handleChange, layerParcelsC, layerParcelsE])    

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AvailableCadastreStatesSelect
                        handleChange={handleChange}
                        selectedDate={state.selectedDate}
                        availableDates={state.availableDates}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField    
                        select
                        variant="outlined"
                        label="Vybrať vrstvu"
                        size="small"
                        fullWidth
                        onChange={(e) => handleChange({selectedLayer: e.target.value})}
                        value={state.selectedLayer}
                    >
                        <MenuItem value="drawing">
                            Nakresliť rozsah do mapy
                        </MenuItem>
                        {
                            layers.flat.map((layer) => {
                                if(layer.role === LayerRole.USERLAYER){
                                    return (
                                        <MenuItem key={layer.id} value={layer.id}>{layer.title}</MenuItem>
                                    )
                                }
                                return null
                            })
                        }
                    </TextField>
                </Grid>
                <Grid item sm={12}>
                    <TextField    
                        select
                        size="small"
                        variant="outlined"
                        label="Vybrať typ geometrie"
                        fullWidth
                        onChange={(e) => handleChange({selectedGeometryType: e.target.value, geometryType: getGeometryType(e.target.value)})}
                        value={state.selectedGeometryType}
                    >    
                        <MenuItem key={1} value={1}>Body</MenuItem>
                        <MenuItem key={2} value={2}>Línie</MenuItem>
                        <MenuItem key={3} value={3}>Polygóny</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={analyse}
                    >
                        Potvrdiť
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default CadastreAnalyseForm