import React from "react"
import { IconButton, Tooltip, withStyles } from "goodmap-core"
import { useIntl } from "react-intl";
import { RiMenuAddLine } from "react-icons/ri";
import { FiPrinter, FiTrash2 } from "react-icons/fi";
import { MdLayersClear } from "react-icons/md";

const LayerTreeToolbar = ({
    enableRedlining,
    enableLegend,
    enableVisibleFilter,
    enableDeleteAllLayers,
    onRedliningClick,
    onLegendClick,
    onFilterClick,
    onDeleteClick,
    classes,
}) => {

    const { formatMessage } = useIntl()

    let redliningTooltip = formatMessage({id: "layertree.redlining"});
    let printLegendTooltip = formatMessage({id: "layertree.printlegend"});
    let visibleFilterTooltip = formatMessage({id: "layertree.visiblefilter"});
    let deleteAllLayersTooltip = formatMessage({id: "layertree.deletealllayers"});

    return (
        <span className={classes.root}>
            {enableRedlining &&
                <Tooltip title={redliningTooltip}>
                    <IconButton className={classes.iconButton} size="small" onClick={onRedliningClick}>
                        <RiMenuAddLine />
                    </IconButton>
                </Tooltip>
            }
            { enableLegend &&
                <Tooltip title={printLegendTooltip}>
                    <IconButton className={classes.iconButton} size="small" onClick={onLegendClick}>
                        <FiPrinter />
                    </IconButton>
                </Tooltip>
            }
            { enableVisibleFilter &&
                <Tooltip title={visibleFilterTooltip}>
                    <IconButton className={classes.iconButton} size="small" onClick={onFilterClick}>
                        <MdLayersClear/>
                    </IconButton>
                </Tooltip>
            }
            { enableDeleteAllLayers &&
                    <Tooltip title={deleteAllLayersTooltip}>
                    <IconButton className={classes.iconButton} size="small" onClick={onDeleteClick} >
                        <FiTrash2  />
                    </IconButton>
                </Tooltip>
            }
            
        </span>
    )
}

const styles = (theme) => ({
    root: {
        paddingLeft: theme.spacing(1)
    },
    iconButton: {
        fontSize: "1.4em",
        color: theme.palette.grey[600],
        padding: 6
    },
})

export default withStyles(styles)(LayerTreeToolbar)