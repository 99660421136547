/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {changeMousePositionState} from '../../actions/mousePosition';
import {changeZoomLevel} from '../../actions/map';
import displayCrsSelector from '../../components/selectors/displaycrs';
import {withStyles, Grid, Button} from "goodmap-core";
import {MdExpandLess} from "react-icons/md"
import {MdExpandMore} from "react-icons/md"
import classnames from 'classnames';
import Scales from './Scales';
import Coordinates from './Coordinates';
import BottomLinks from './BottomLinks';

const styles = (theme) => ({
    root: {
        position: "absolute",
        bottom: 0,
        borderWidth: 5,
        alignItems: "center",
        justifyContent: "space-evenly",
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: 500,
        width: "100%",
        padding: 0,
        height: 0,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        boxShadow: theme.shadows[4],
        background: theme.palette.common.white,
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
        zIndex: 8
    },
    button: {
        position: "absolute",
        top: -28,
        boxShadow: "none",
        left: 0,
        right: 0,
        margin: "auto",
        padding: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        "&:hover": {
            boxShadow: "none"
        }
    },
    showPanel:{
        padding: 8,
        height: "auto"
    }
})

const BottomBar = ({
    viewertitleUrl,
    termsUrl,
    displaycrs,
    map,
    fullscreen,
    additionalMouseCrs,
    changeMousePositionState,
    changeZoomLevel,
    displayCoordinates,
    displayScales,
    classes
}) => {
  
    const [displayMore, setDisplayMore] = useState(false)
   
    if(fullscreen) {
        return null;
    }

    const rootClasses = classnames({
        [classes.root]: true,
        [classes.showPanel]: displayMore
    })

    const classesButton = classnames({
        [classes.button]: true,
        [classes.buttonHidden]: !displayMore
    })

    return (
        <div className={rootClasses}>
            <Button 
                onClick={() => setDisplayMore(!displayMore)}
                variant="contained" 
                className={classesButton}
            >
                {displayMore ? <MdExpandMore size="2em"/> : <MdExpandLess size="2em"/>}
            </Button>
            {displayMore && (
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={8}>
                        {displayCoordinates &&
                            <Coordinates
                                projection={map.projection}
                                additionalMouseCrs={additionalMouseCrs}
                                changeMousePositionState={changeMousePositionState}
                                displaycrs={displaycrs}
                            />
                        }
                    </Grid>
                    <Grid item xs={4}>
                        {displayScales && 
                            <Scales
                                scales={map.scales}
                                changeZoomLevel={changeZoomLevel}
                                zoom={map.zoom}
                            />
                        }
                    </Grid>
                    {/* <Grid item xs={12}>
                        <BottomLinks
                            termsUrl={termsUrl}
                            viewertitleUrl={viewertitleUrl}
                        />
                    </Grid> */}
                </Grid>
            )}
        </div>
    );
    
};

BottomBar.defaultProps = {
    displayCoordinates: true,
    displayScales: true
}

BottomBar.propTypes = {
    viewertitleUrl: PropTypes.string,
    termsUrl: PropTypes.string,
    displaycrs: PropTypes.string,
    map: PropTypes.object,
    fullscreen: PropTypes.bool,
    additionalMouseCrs: PropTypes.array,
    changeMousePositionState: PropTypes.func,
    changeZoomLevel: PropTypes.func,
    displayCoordinates: PropTypes.bool,
    displayScales: PropTypes.bool
}

const selector = createSelector([state => state, displayCrsSelector], (state, displaycrs) => {
    let map = state && state.map && state.map ? state.map : null;
    return {
        displaycrs: displaycrs,
        map: map,
        fullscreen: state.display && state.display.fullscreen,
        additionalMouseCrs: state.theme && state.theme.current ? state.theme.current.additionalMouseCrs : []
    };
});

const BottomBarWithStyles = withStyles(styles)(BottomBar);

export default {
    BottomBarPlugin: connect(selector, {
        changeMousePositionState: changeMousePositionState,
        changeZoomLevel: changeZoomLevel
    })(BottomBarWithStyles)
};
