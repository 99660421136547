const translations = {
  "system.ThemeNotConfigured": "V aplikácii nie je nakonfigurovaná žiadna téma",
  "general.close": "Zavrieť",
  "appmenu.items.NewLayer": "Nová vrstva",
  "appmenu.items.Analyse": "Analýza katastra",
  "appmenu.items.CadastreAnalyse": "Analýza katastra",
  "appmenu.items.ExternalLink": "Externý link",
  "appmenu.items.DxfExport": "DXF Export",
  "appmenu.items.Help": "Nápoveda",
  "appmenu.items.LayerTree": "Vrstvy & Legenda",
  "appmenu.items.Measure": "Meranie",
  "appmenu.items.DataCollector": "Mapovanie",
  "appmenu.items.Print": "Tlač",
  "appmenu.items.RasterExport": "Raster Export",
  "appmenu.items.Share": "Zdieľať",
  "appmenu.items.ThemeSwitcher": "Téma",
  "appmenu.items.Tools": "Nástroje Mapy",
  "appmenu.items.IdentifyRegion": "Určiť oblasť",
  "appmenu.items.Redlining": "Voľné kreslenie",
  "appmenu.items.Editing": "Úpravy",
  "appmenu.items.Login": "Prihlásenie",
  "appmenu.items.Logout": "Odhlásenie",
  "appmenu.items.HeightProfile": "Profil",
  "appmenu.title": "Navigácia",
  "appmenu.menulabel": "Mapa & Nástroje",
  "search.coordinates": "Súradnice",
  "search.more": "Viac...",
  "search.search": "Hľadať",
  "search.searchall": "Nájdi objekt v mape...",
  "search.all": "Všetko",
  "search.themes": "Témy",
  "search.invisiblelayer": "Neviditeľné vrstvy",
  "search.enablelayer": "Aktivovať vrstvu",
  "search.existinglayer": "Existujúca vrstva",
  "bgswitcher.nobg": "Bez podkladu",
  "bottombar.mousepos_label": "Súradnice",
  "bottombar.scale_label": "Mierka",
  "bottombar.terms_label": "Podmienky použitia",
  "bottombar.viewertitle_label": "SKYMOVE s.r.o.",
  "copybtn.click_to_copy": "Skopírovať",
  "copybtn.copied": "Skopírované",
  "dxfexport.selectinfo": "Vyberte obdĺžnikom oblasť pre export...",
  "dxfexport.symbologyscale": "Mierka symbolov:",
  "editing.canceldelete": "Neodstraňovat",
  "editing.commit": "Potvrdiť",
  "editing.delete": "Odstrániť",      
  "editing.discard": "Vyradiť",
  "editing.draw": "Kresliť",
  "editing.feature": "Objekt",
  "editing.fields": "Pole",
  "editing.layer": "Vrstva",
  "editing.noeditablelayers": "Needitovateľná vrstva.",
  "editing.pick": "Výber",
  "editing.reallydelete": "Odstrániť",
  "editing.select": "Vybrať",
  "fileselector.placeholder": "Vybrať súbor...",
  "heightprofile.asl": "a.s.l.",
  "heightprofile.distance": "Vzdialenosť",
  "heightprofile.height": "Výška",
  "heightprofile.loading": "Výpočet výškového profilu...",
  "identify.querying": "Dopytovanie...",
  "identify.noresults": "Pre vybraný bod nie sú k dispozícii žiadne informácie",
  "identify.export": "Export",
  "identify.featureReport": "Report prvku",
  "identify.noattributes": "Bez atribútov",
  "identify.title": "Informacie o prvku",
  "identify.link": "Odkaz",
  "identify.label": "Text",
  "identify.computed_area": "Vypočítaná rozloha",
  "identify.computed_length": "Vypočítaná dĺžka",
  "identify.computed_coors": "Súradnice",
  "identifyregion.info": "Nakreslite polygón pre identifikáciu...",
  "importlayer.addlayer": "Pridať vrstvu",
  "importlayer.connect": "Pripojiť",
  "importlayer.filter": "Filter...",
  "importlayer.localfile": "Lokálny súbor",
  "importlayer.nofeatures": "Žiadne prvky neboli importované.",
  "importlayer.noresults": "Bez výsledkov alebo nevalídna URL",
  "importlayer.loading": "Loading",
  "importlayer.url": "url",
  "importlayer.urlplaceholder": "Vložit URL pre WMS, WFS...",
  "layerinfo.title": "Informacie o vrstve",
  "layerinfo.abstract": "Abstraktné",
  "layerinfo.attribution": "Atribúty",
  "layerinfo.keywords": "Klúčové slová",
  "layerinfo.dataUrl": "Data URL",
  "layerinfo.metadataUrl": "Metadata URL",
  "layerinfo.legend": "Legenda",
  "layerinfo.maxscale": "Max. mierka",
  "layerinfo.minscale": "Min. mierka",
  "layertree.layerFormAttributes": "Atribúty",
  "layertree.compare": "Porovnat vrchné vrstvy",
  "layertree.importlayer": "Importovať vrstvu",
  "layertree.maptip": "Zobrazovat bubliny nad mapou",
  "layertree.printlegend": "Tlač legendy",
  "layertree.deletealllayers": "Vymazať všetky vrstvy",
  "layertree.visiblefilter": "Zobraziť iba vidiťelné vrstvy",
  "layertree.separator": "Oddeľovač",
  "layertree.redlining": "Vytvoriť novú vrstvu",
  "layertree.export": "Exportovať vrstvu",
  "layertree.separatortooltip": "Nadpis oddeľovača",
  "layertree.zoomtolayer": "Zoom na vrstvu",
  "layertree.infotooltip": "Zobraziť informácie o vrstve",
  "layertree.editlayer": "Editácia vrstvy",
  "layertree.editlayerform": "Upraviť štruktúru vrstvy",
  "layertree.moveup": "Presunúť vyššie",
  "layertree.movedown": "Presunúť nižšie",
  "layertree.deletetooltip": "Odstrániť vrstvu",
  "layertree.optionstooltip": "Zobraziť panel s možnosťami",
  "layertree.exportToKml": "Exportovať KML",
  "locate.metersUnit": "mere",
  "locate.feetUnit": "stopy",
  "locate.popup": "Ste vnútri {distance} {unit} z tohoto bodu",
  "locate.statustooltip.DISABLED": "Poloha: vypnutá",
  "locate.statustooltip.LOCATING": "Poloha: načitáva sa...",
  "locate.statustooltip.ENABLED": "Poloha: zapnutá",
  "locate.statustooltip.FOLLOWING": "Poloha: nasledujúca",
  "locate.statustooltip.PERMISSION_DENIED": "Poloha: prístup zamietnutý",
  "map.loading": "Načitávanie...",
  "mapinfotooltip.elevation": "Nadmorská výška",
  "mapinfotooltip.title": "Poloha",
  "measureComponent.areaLabel": "Plocha",
  "measureComponent.bearingLabel": "Azimut",
  "measureComponent.lengthLabel": "Vzdialenosť",
  "measureComponent.pointLabel": "Súradnica",
  "measureComponent.azimut": "Azimut:",
  "measureComponent.coordinate": "Súradnica:",
  "measureComponent.distance": "Vzdialenosť:",
  "measureComponent.area": "Plocha:",
  "measureComponent.units": "Jednotky",
  "print.layout": "Rozloženie:",
  "print.grid": "Mriežka:",
  "print.maximize": "Maximálne",
  "print.minimize": "Minimálne",
  "print.nolayouts": "Vybraná téma nepodporuje tlač",
  "print.notheme": "Nie je vybraná téma",
  "print.resolution": "Rozlíšenie:",
  "print.rotation": "Rotácia:",
  "print.scale": "Mierka:",
  "print.submit": "Tlač",
  "print.output": "Výstupy",
  "print.wait": "Čakať",
  "rasterexport.format": "Formát:",
  "rasterexport.resolution": "Rozlíšenie:",
  "rasterexport.selectinfo": "Vyberte obdĺžnikom oblasť pre export...",
  "redlining.styles": "Symbolika",
  "redlining.add": "Pridať",
  "redlining.layerProps": "Atribúty",
  "redlining.save": "Uložiť",
  "redlining.border": "Okraj",
  "redlining.symbol": "Symbol",
  "redlining.marker": "Vložiť značku",
  "redlining.new_attribute": "Nový atribút",
  "redlining.configure_attributes": "Konfigurovať atribúty",
  "redlining.buffer": "Buffer",
  "redlining.bufferlayername": "Vrstva s buffrom",
  "redlining.bufferdistance": "Vzdialenosť buffra",
  "redlining.bufferlayer": "Vrstva buffra",
  "redlining.buffercompute": "Výpočet",
  "redlining.bufferselectfeature": "Označiť objekty",
  "redlining.delete": "Odstrániť",
  "redlining.draw": "Kresliť",
  "redlining.edit": "Upraviť",
  "redlining.fill": "Výplň",
  "redlining.attributes": "Upraviť atribúty",
  "redlining.freehand": "Voľná kresba",
  "redlining.label": "Popisok",
  "redlining.layer": "Vrstva",
  "redlining.line": "Línia",
  "redlining.outline": "Obrys",
  "redlining.pick": "Výber",
  "redlining.point": "Bod",
  "redlining.polygon": "Polygón",
  "redlining.size": "Veľkosť",
  "redlining.text": "Text",
  "redlining.width": "Šírka",
  "redlining.attributeNameConflict": "Atribút s týmto názvom už existuje",
  "redlining.textbordersize": "Veľkosť obrysu",
  "redlining.textbordercolor": "Farba obrysu",
  "redlining.textsize": "Veľkosť textu",
  "redlining.textcolor": "Farba textu",
  "redlining.textsettings": "Nastavenia textu",
  "redlining.symbology": "Symbolika",
  "redlining.nogeometry": "Aby ste mohli nastavovať symboliku, musíte najskôr označiť objekt v mape",
  "searchbox.coordinates": "Súradnice",
  "searchbox.filter": "Filter",
  "searchbox.layers": "Vrstvy",
  "searchbox.more": "Viac",
  "searchbox.morelayers": "Viac vrstviev",
  "searchbox.nodescription": "Popis",
  "searchbox.placeholder": "Placeholder",
  "searchbox.places": "Miesta",
  "searchbox.recent": "Nedvávne",
  "share.directLinkTitle": "Cez priame spojenie",
  "share.generatingpermalink": "Počkajte prosím, generuje sa odkaz na zdieľanie",
  "share.QRCodeLinkTitle": "QR kód",
  "share.socialIntro": "Na vašej obľúbenej sociálnej sieti",
  "tooltip.home": "Zobraziť celú mapu",
  "tooltip.zoomin": "Priblížit",
  "tooltip.zoomout": "Oddialiť",
  "tooltip.background": "Zmeniť podkladovou mapu",
  "themeswitcher.filter": "Filter...",
  "themeswitcher.addtotheme": "Pridat k aktuálnej téme",
  "themeswitcher.match.title": "Nadpis",
  "themeswitcher.match.keywords": "Kľučové slová",
  "themeswitcher.match.abstract": "Abstrakt",
  "themeswitcher.openintab": "Otvoriť v tabe",
  "vectorlayerpicker.attributes": "Atribúty",
  "datacollector.confirmation.title": "Zakreslené objekty",
  "help.title":"Mapová aplikácia Goodmap",
  "help.text":"Goodmap je moderná mapová aplikácia na zber, analýzu a správu priestorových dát. Aplikácia je jednoduchá a intuitívná. Viac informácii o aplikácii nájdente na našom e-learningu.",
  "help.button":"Prejsť na e-Learning",
  "appmenu.items.UserProfile": "Používateľský profil",
  "username": "Používateľské meno",
  "email": "E-mail",
  "logout": "Odhlásiť sa"
}

export default translations
