/* eslint-disable */
import React from "react"
import { Tooltip, IconButton, withStyles, Loader } from "goodmap-core"
import { FaRegSun, FaRegTrashAlt } from "react-icons/fa"
import clsx from "clsx"
import Icon from '../../components/Icon';

const LayerItem = ({
    classes,
    allowOptions,
    allowRemove,
    optionsIconTooltip,
    deleteIconTooltip,
    layerMenuToggled,
    removeLayer,
    disabled,
    outsideScaleRange,
    checkboxState,
    onClick,
    layer,
    sublayer,
    changeLayerProperty,
    showLegendIcons,
    onLegendMouseOver,
    onLegendMouseOut,
    onLegendTouchStart
}) => {

    let legend = null;
    if(showLegendIcons) {
        if(layer.legendUrl) {
            let request = layer.legendUrl + (layer.legendUrl.indexOf('?') === -1 ? '?' : '&') + "SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=" + (layer.version || "1.3.0") + "&FORMAT=image/png&LAYER=" + sublayer.name;
            legend = (
                <img 
                    className={classes.legendThumbnail} 
                    src={request + "&TYPE=thumbnail"} 
                    onMouseOver={(ev) => onLegendMouseOver(ev, request)} 
                    onMouseOut={onLegendMouseOut} 
                    onTouchStart={(ev) => onLegendTouchStart(ev, request)} 
                />
            );
        } else if(layer.color) {
            legend = (
                <span 
                    className={classes.legendColorThumbnail} 
                    style={{backgroundColor: layer.color}} 
                />
            );
        }
    }

    let title = null;
    if(layer.type === "separator") {
        title = (<input value={sublayer.title} onChange={changeLayerProperty}/>);
    } else {
        title = (<span className={classes.layerItemTitle} title={sublayer.title}>{sublayer.title}</span>);
    }

    let checkbox = null;
    if(layer.type === "placeholder") {
        checkbox = (<Loader />);
    } else if(layer.type === "separator") {
        checkbox = null;
    } else {
        checkbox = (<Icon className={classes.checkbox} fontSize={22} icon={checkboxState}
            //  onClick={onClick}
              />);
    }

    const containerClasses = {
        [classes.container]: true,
        [classes.layerItemDisabled]: disabled,
        [classes.outsideScaleRange]: outsideScaleRange
    };
    
    return (
        <div className={clsx(containerClasses)}>

            <div className={classes.root}>
                <span className={classes.checkboxGroup}>
                    {checkbox}
                    {legend}
                </span>
                {title}
            </div>

            <div>
                {allowOptions ? (
                    <Tooltip title={optionsIconTooltip}>
                        <IconButton className={classes.iconButton} size="small" onClick={layerMenuToggled}>
                            <FaRegSun size={20}/>
                        </IconButton>
                    </Tooltip>
                ) : null}
                {allowRemove ? (
                    <Tooltip title={deleteIconTooltip}>
                        <IconButton className={classes.iconButton} size="small" onClick={removeLayer}>
                            <FaRegTrashAlt size={20}/>
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>

        </div>
    )
}

LayerItem.propTypes = {

}

LayerItem.defaultProps = {

}

const styles = (theme) => ({
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    checkboxGroup: ({layer}) => ({
        width: (layer.color || layer.legendUrl) ? 70 : 35,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }),
    iconButton: {
        fontSize: "1.4em",
        color: theme.palette.grey[600],
        padding: theme.spacing(1)
    },
    layerItemDisabled: {
        color: "#999999"
    },
    outsideScaleRange: {
        fontStyle: "italic",
        color: "#999999"
    },
    layerItemTitle: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: "0 1 auto"
    },
    checkbox: {
        color: theme.palette.grey[600],
        fontSize: "1.2em",
        marginRight: theme.spacing(1),
        flex: "0 0 auto",
    },
    legendColorThumbnail: {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.grey[200],
        display: "inline-block",
        height: "1.5em",
        width: "1.5em",
        marginRight: "0.5em",
        flex: "0 0 auto",
    },
    legendTooltip: {
        position: "absolute",
        zIndex: 8,
        boxShadow: "0px 2px 5px rgba(136, 136, 136, 0.5)",
        marginLeft: "1px",
        padding: "0.25em",
        backgroundColor: "white"
    },
    legendThumbnail: {
        height: '1.5em',
        width: "auto",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.grey[200],
        maxWidth: "2em",
        marginRight: theme.spacing(1),
        flex: "0 0 auto",
    }
})

export default withStyles(styles)(LayerItem)