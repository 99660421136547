/* eslint-disable */
/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import assign from 'object-assign';
import uuid from 'uuid';
import ol from '@openlayers';
import isEmpty from 'lodash.isempty';
import {stringify} from 'wellknown';
import CoordinatesUtils from './CoordinatesUtils';
import ConfigUtils from './ConfigUtils';
import {getDefaultImageStyle} from 'ol/format/KML';
import tokml from 'tokml';

const VectorLayerUtils = {

    createPrintHighlighParams(layers, printCrs, dpi = 96, scaleFactor = 1.0) {
        let params = {
            geoms: [],
            styles: [],
            labels: [],
            labelFillColors: [],
            labelOultineColors: [],
            labelOutlineSizes: [],
            labelSizes: []
        }
        const defaultFeatureStyle = ConfigUtils.getConfigProp("defaultFeatureStyle");
        const ensureHex = (rgb) => (!Array.isArray(rgb) ? rgb : ('#' + (0x1000000 + (rgb[2] | (rgb[1] << 8) | (rgb[0] << 16))).toString(16).slice(1)));
        for(let layer of layers.slice(0).reverse()) {
            if(layer.type != 'vector' || (layer.features || []).length == 0 || layer.visibility === false) {
                continue;
            }
            for(let feature of layer.features) {
                if(!feature.geometry) {
                    continue;
                }
                let geometry = VectorLayerUtils.reprojectGeometry(feature.geometry, feature.crs || printCrs, printCrs);
                params.styles.push(VectorLayerUtils.createSld(geometry.type, feature.styleName, feature.styleOptions, layer.opacity, dpi, scaleFactor));
                params.labels.push(feature.properties && feature.properties.label || " ");
                const styleOptions = { ...defaultFeatureStyle, ...feature.styleOptions };
                if(feature.styleName === "text") {
                    // Make point a tiny square, so that QGIS server centers the text inside the polygon when labelling
                    let x = geometry.coordinates[0];
                    let y = geometry.coordinates[1];
                    geometry = {
                        type: "Polygon",
                        coordinates: [[
                            [x - 0.00001, y - 0.00001],
                            [x + 0.00001, y - 0.00001],
                            [x + 0.00001, y + 0.00001],
                            [x - 0.00001, y + 0.00001],
                            [x - 0.00001, y - 0.00001]
                        ]]
                    };
                    params.geoms.push(VectorLayerUtils.geoJSONToWkt(geometry));
                    params.labelFillColors.push(ensureHex(styleOptions.text.fill));
                    params.labelOultineColors.push(ensureHex(styleOptions.text.stroke));
                    params.labelOutlineSizes.push(styleOptions.text.borderSize / 2);
                    params.labelSizes.push(styleOptions.size);
                } else {
                    params.geoms.push(VectorLayerUtils.geoJSONToWkt(geometry));
                    params.labelFillColors.push(ensureHex(styleOptions.text.fill));
                    params.labelOultineColors.push(ensureHex(styleOptions.text.stroke));
                    params.labelOutlineSizes.push(styleOptions.text.borderSize / 2);
                    params.labelSizes.push(styleOptions.size);
                }
            }
        }
        return params;
    },
    createSld(geometrytype, styleName, styleOptions, layerOpacity, dpi = 96., scaleFactor = 1.0) {
        let opts = {};
        // Special cases
        if(styleName == 'text') {
            // Make geometry transparent
            opts = {
                stroke: {color: [0, 0, 0, 0.]},
                fill: {color: [0, 0, 0, 0.]},
                point: { radius: 6 }
            };
        } else if(styleName == 'marker') {
            opts = {
                stroke: {
                    color: [0, 0, 255, 1.],
                    width: 4,
                },
                fill: {
                    color: [255, 255, 255, 1.]
                },
                point: { radius: 6 }
            };
        } else {
            // Default style
            opts = assign({}, ConfigUtils.getConfigProp("defaultFeatureStyle"), styleOptions);
        }
        let dpiScale = dpi / 96. * scaleFactor;

        const ensureHex = (rgb) => (!Array.isArray(rgb) ? rgb : ('#' + (0x1000000 + (rgb[2] | (rgb[1] << 8) | (rgb[0] << 16))).toString(16).slice(1)));
        const opacity = (rgb) => ((!Array.isArray(rgb) ? 1. : (rgb[3] === undefined ? 1. : rgb[3])) * layerOpacity / 255.);

        let stroke = '<se:Stroke>' +
                     '<se:SvgParameter name="stroke">' + ensureHex(opts.stroke.color) + '</se:SvgParameter>' +
                     '<se:SvgParameter name="stroke-opacity">' + opacity(opts.stroke.color) + '</se:SvgParameter>' +
                     '<se:SvgParameter name="stroke-width">' + (opts.stroke.width * dpiScale) + '</se:SvgParameter>' +
                     '<se:SvgParameter name="stroke-linejoin">bevel</se:SvgParameter>' +
                     (!isEmpty(opts.strokeDash) ? '<CssParameter name="stroke-dasharray">' + opts.stroke-dash.join(' ') + '</CssParameter>' : '') +
                     '</se:Stroke>';
        let fill = '<se:Fill>' +
                   '<se:SvgParameter name="fill">' + ensureHex(opts.fill.color) + '</se:SvgParameter>' +
                   '<se:SvgParameter name="fill-opacity">' + opacity(opts.fill.color) + '</se:SvgParameter>' +
                   '</se:Fill>';

        let rule = null;
        if(geometrytype == "Point") {
            rule = '<se:PointSymbolizer>' +
                   '<se:Graphic>' +
                   '<se:Mark>' +
                   '<se:WellKnownName>circle</se:WellKnownName>' +
                   '<se:Stroke>' +
                   '<se:SvgParameter name="stroke">' + ensureHex(opts.stroke.color) + '</se:SvgParameter>' +
                   '<se:SvgParameter name="stroke-opacity">' + opacity(opts.stroke.color) + '</se:SvgParameter>' +
                   '<se:SvgParameter name="stroke-width">' + (opts.stroke.width * dpiScale) + '</se:SvgParameter>' +
                   '</se:Stroke>' +
                   fill +
                   '</se:Mark>' +
                   '<se:Size>' + (2 * opts.point.radius * dpiScale) + '</se:Size>' +
                   '</se:Graphic>' +
                   '</se:PointSymbolizer>';
        } else if(geometrytype == "LineString") {
            rule = '<se:LineSymbolizer>' +
                   stroke +
                   '</se:LineSymbolizer>';
        } else if(geometrytype == "Polygon") {
            rule = '<se:PolygonSymbolizer>' +
                   stroke +
                   fill +
                   '</se:PolygonSymbolizer>';
        }
        if(rule) {
            return '<?xml version="1.0" encoding="UTF-8"?>' +
                   '<StyledLayerDescriptor xmlns="http://www.opengis.net/sld" xmlns:ogc="http://www.opengis.net/ogc" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.1.0" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/1.1.0/StyledLayerDescriptor.xsd" xmlns:se="http://www.opengis.net/se">' +
                   '<UserStyle>' +
                   '<se:FeatureTypeStyle>' +
                   '<se:Rule>' +
                   rule +
                   '</se:Rule>' +
                   '</se:FeatureTypeStyle>' +
                   '</UserStyle>' +
                   '</StyledLayerDescriptor>';
        }
        return null;
    },
    reprojectGeometry(geometry, srccrs, dstcrs) {
        if(srccrs == dstcrs) {
            return geometry;
        }
        if(geometry.type === "Point") {
            let wgscoo = CoordinatesUtils.reproject(geometry.coordinates, srccrs, dstcrs);
            return {
                "type": geometry.type,
                "coordinates": wgscoo
            };
        } else if(geometry.type === "LineString" || geometry.type === "MultiPoint") {
            return {
                "type": geometry.type,
                "coordinates": geometry.coordinates.map(tuple => {
                    return CoordinatesUtils.reproject(tuple, srccrs, dstcrs);
                })
            };
        } else if(geometry.type === "Polygon" || geometry.type === "MultiLineString") {
            return {
                "type": geometry.type,
                "coordinates": geometry.coordinates.map(ring => {
                    return ring.map(tuple => {
                        return CoordinatesUtils.reproject(tuple, srccrs, dstcrs);
                    });
                })
            };
        } else if(geometry.type === "MultiPolygon") {
            return {
                "type": geometry.type,
                "coordinates": geometry.coordinates.map(part => {
                    return part.map(ring => {
                        return ring.map(tuple => {
                            return CoordinatesUtils.reproject(tuple, srccrs, dstcrs);
                        });
                    });
                })
            };
        } else {
            return geometry;
        }
    },
    geoJSONToWkt(geometry) {
        return stringify(geometry);
    },
    wktToGeoJSON(wkt, srccrs, dstcrs, id=uuid.v1()) {
        wkt = wkt.replace(/Point(\w+)/i, "Point $1")
                 .replace(/LineString(\w+)/i, "LineString $1")
                 .replace(/Polygon(\w+)/i, "Polygon $1")
                 .replace(/MultiSurface(\w*)/i, "GeometryCollection $1");
        try {
            let feature = new ol.format.WKT().readFeature(wkt, {
                dataProjection: srccrs,
                featureProjection: dstcrs
            });
            let featureObj = new ol.format.GeoJSON().writeFeatureObject(feature);
            featureObj.id = id;
            return featureObj;
        } catch(e) {
            console.warn("Failed to parse geometry: " + wkt);
            return null;
        }
    },
    geoJSONToKML(geojsonObject){
        const kml = tokml(geojsonObject);
        console.log(kml)
        return kml;
    },
    kmlToGeoJSON(kml) {
        let kmlFormat = new ol.format.KML({defaultStyle: [new ol.style.Style()]});
        let geojsonFormat = new ol.format.GeoJSON();
        let features = [];
        let fid = 0;
        for(let olFeature of kmlFormat.readFeatures(kml)) {
            let style = olFeature.getStyleFunction()(olFeature);
            style = style[0] || style;

            const strokeStyle = style.getStroke();
            const fillStyle = style.getFill();
            const textStyle = style.getText();
            let styleOptions = {
                stroke: {
                    color: strokeStyle && strokeStyle.getColor() || [255, 0, 0, 1],
                    width: strokeStyle && strokeStyle.getWidth() || 2,
                    dash: strokeStyle && strokeStyle.getLineDash() || []
                },
                fill: {
                    color: fillStyle ? fillStyle.getColor() : [255, 255, 255, 1],
                    fill: textStyle && textStyle.getFill() ? textStyle.getFill().getColor() : [0, 0, 0, 1]
                },
                icon: {
                    scale: 0.6
                },
                point: {
                    radius: 7
                },
                text: { 
                    stroke: {color: textStyle && textStyle.getStroke() ? textStyle.getStroke().getColor() : [255, 255, 255, 1]}
                }
            };
            if(style.getImage() && style.getImage() != getDefaultImageStyle() && style.getImage().getSrc()) {
                // FIXME: Uses private members of ol.style.Icon, style.getImage().getAnchor() returns null because style.getImage.getSize() is null because the the image is not yet loaded
                let anchor = style.getImage().anchor_ || [0.5, 0.5];
                let anchorOrigin = (style.getImage().anchorOrigin_ || "").split("-");
                if(anchorOrigin.includes("right")) {
                    anchor[0] = 1. - anchor[0];
                }
                if(anchorOrigin.includes("bottom")) {
                    anchor[1] = 1. - anchor[1];
                }
                styleOptions.image.src = style.getImage().getSrc();
                styleOptions.image.anchor = anchor;
            }
            let feature = geojsonFormat.writeFeatureObject(olFeature);
            feature = assign(feature, {
                styleName: styleOptions.image.src ? 'marker' : 'default',
                styleOptions: styleOptions,
                // id: fid++,
                crs: "EPSG:4326",
                properties: {}
            });
            let properties = olFeature.getProperties();
            let excludedProperties = ['visibility', olFeature.getGeometryName()];
            for(let key of Object.keys(properties)) {
                if(!excludedProperties.includes(key)) {
                    feature.properties[key] = properties[key];
                }
            }
            if(properties.name && feature.styleName === 'marker') {
                feature.properties.label = properties.name;
            }
            features.push(feature);
        }
        return features;
    }
};

export default VectorLayerUtils;
