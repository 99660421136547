import React from "react"
import { Fab, withStyles } from "goodmap-core"
import { useIntl } from "react-intl"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"

const LayerGroupMenu = ({
    classes,
    layer,
    path,
    allowRemove,
    reorderLayer
}) => {

    const {formatMessage} = useIntl()

    const moveUpTooltip = formatMessage({id: "layertree.moveup"});
    const moveDownTooltip = formatMessage({id: "layertree.movedown"});

    return (
        <div className={classes.itemContextMenuFrame} style={{marginRight: allowRemove ? '1.75em' : 0}}>
            <div className={classes.layerSettingsPanel}>
                <Fab 
                    tooltip={moveDownTooltip} 
                    className={classes.fabButton} 
                    onClick={(e) => {
                        e.stopPropagation()
                        reorderLayer(layer, path, +1)
                    }} 
                    size="small" 
                    color="primary" 
                >
                    <FaArrowDown size={16}/>
                </Fab>
                <Fab 
                    tooltip={moveUpTooltip} 
                    className={classes.fabButton} 
                    onClick={(e) => {
                        e.stopPropagation()
                        reorderLayer(layer, path, -1)
                    }} 
                    size="small" 
                    color="primary" 
                >
                    <FaArrowUp size={16} />
                </Fab>
            </div>
        </div>
    )
}

const styles = (theme) => ({
    root: {

    },
    fabButton: {
        margin: 4,
        width: 35,
        height: 35
    },
    itemContextMenuFrame: {
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center"
    },
    layerSettingsPanel: {
        flex: "0 0 auto",
        maxWidth: "99%",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 4,
        paddingRight: 4,
        boxShadow: "0 1px 4px 0 rgba(0,0,0,0.5)",
        fontWeight: "bold",
        alignItems: "center",
        backgroundColor: "white"
    },
})

export default withStyles(styles)(LayerGroupMenu)