import React, { useEffect } from "react"
import {makeStyles, MenuItem, TextField} from "goodmap-core"
import CoordinateDisplayer from "../../components/CoordinateDisplayer";
import CoordinatesUtils from "../../utils/CoordinatesUtils";
import pickBy from 'lodash.pickby';

const Coordinates = ({
    additionalMouseCrs,
    displaycrs,
    changeMousePositionState,
    projection
}) => {

    const classes = useStyles()
    debugger
    useEffect(() => {
        changeMousePositionState({crs: projection, position: null});
    }, [projection, changeMousePositionState])
    
    const availableCRS = pickBy(CoordinatesUtils.getAvailableCRS(), (key, code) => {
        return code === "EPSG:4326" ||
                code === projection ||
                additionalMouseCrs.indexOf(code) !== -1;
        }
    );

    return (
        <div className={classes.coordinatesContainer}>
            <CoordinateDisplayer className={classes.mouseCoordinatesInput} displaycrs={displaycrs} />
            <TextField InputProps={{classes: {input: classes.input}}} select variant="outlined" size="small" className={classes.crsSelector} onChange={ev => changeMousePositionState({crs: ev.target.value})} value={displaycrs}>
                {Object.keys(availableCRS).map(crs =>
                    (<MenuItem value={crs} key={crs}>{availableCRS[crs].label}</MenuItem>)
                )}
            </TextField>
        </div>
    );
}

Coordinates.defaultProps = {
    availableCRS: {},
    additionalMouseCrs: []
}

Coordinates.propTypes = {

}

const useStyles = makeStyles((theme) => ({
    coordinatesContainer: {
        color: theme.palette.common.white,
        display: "flex",
        alignItems: "center"
    },
    mouseCoordinatesInput: {
       marginRight: theme.spacing(1)
    },
    input: {
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4,
        paddingBottom: 4
    }
}))

export default Coordinates