/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import assign from 'object-assign';
import { changeMeasurementState } from '../actions/measurement.js';
import displayCrsSelector from '../components/selectors/displaycrs';
import TaskBar from '../components/TaskBar';
import Message from "../components/I18N/Message"
import { withStyles, Fab, Tooltip, TextField, MenuItem } from "goodmap-core";
import { MdSpaceBar, MdGamepad, MdCrop } from 'react-icons/md';

const styles = (theme) => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    displayContainer: {
        background: "#fff",
        borderRadius: 90,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    fab: {
        marginLeft: 4,
        background: "#0f84c8",
        marginRight: 4,
        "&:hover": {
            background: theme.palette.primary.dark,
        }
    }, 
    activeFab: {
        marginLeft: 4,
        marginRight: 4,
        background: theme.palette.primary.dark,
        "&:hover": {
            background: theme.palette.primary.dark,
        }
    },  
    result: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    select: {
        marginLeft: 8
    }
})

const Measure = ({
    measureState,
    mapcrs,
    classes,
    displaycrs,
    changeMeasurementState,
    showMeasureModeSwitcher
}) => {
    
    const onShow = (mode) => changeMeasurementState({geomType: mode || 'Point'});

    const onHide = () => changeMeasurementState({geomType: null});

    const setMeasureMode = (geomType) => {
        if(geomType !== measureState.geomType) {
            changeMeasurementState({geomType: geomType});
        }
    }

    const changeLengthUnit = (ev) => changeMeasurementState(assign({}, measureState, {lenUnit: ev.target.value}));
    const changeAreaUnit = (ev) => changeMeasurementState(assign({}, measureState, {areaUnit: ev.target.value}));
    
    const renderModeSwitcher = () => {
        if(!showMeasureModeSwitcher) {
            return null;
        }
      
        return (
            <>
                <Tooltip title={<Message msgId="measureComponent.pointLabel" />}>
                    <Fab 
                        className={measureState.geomType === "Point" ? classes.activeFab : classes.fab} 
                        size="small" 
                        color="primary" 
                        onClick={() => setMeasureMode("Point")} 
                        aria-label="point"
                    >
                        <MdGamepad/>
                    </Fab>
                </Tooltip>
                <Tooltip title={<Message msgId="measureComponent.lengthLabel"></Message>}>
                    <Fab 
                        className={measureState.geomType === "LineString" ? classes.activeFab : classes.fab} 
                        size="small" 
                        color="primary" 
                        onClick={() => setMeasureMode("LineString")} 
                        aria-label="linestring"
                    >
                        <MdSpaceBar/>
                    </Fab>
                </Tooltip>
                <Tooltip title={<Message msgId="measureComponent.areaLabel"></Message>}>
                    <Fab 
                        className={measureState.geomType === "Polygon" ? classes.activeFab : classes.fab} 
                        size="small" 
                        color="primary" 
                        onClick={() => setMeasureMode("Polygon")} 
                        aria-label="polygon"
                    >
                        <MdCrop/>
                    </Fab>
                </Tooltip>
            </>
        );
    }
    const renderResult = () => {
        let resultBody = null;
        if(measureState.geomType === "LineString") {
            resultBody = (
                <div className={classes.result}>
                    <Message msgId="measureComponent.units" />
                    <TextField
                        onChange={changeLengthUnit} 
                        value={measureState.lenUnit}    
                        classes={{root: classes.select}}    
                        select
                    >    
                        <MenuItem key="m" value="m">m</MenuItem>
                        <MenuItem key="ft" value="ft">ft</MenuItem>
                        <MenuItem key="km" value="km">km</MenuItem>
                        <MenuItem key="mi" value="mi">mi</MenuItem>
                    </TextField>
                </div>
            );
        } else if(measureState.geomType === "Polygon") {
            resultBody = (
                <div className={classes.result}>
                    <Message msgId="measureComponent.units" />
                    <TextField
                        onChange={changeAreaUnit} 
                        value={measureState.areaUnit}    
                        classes={{root: classes.select}}    
                        select
                    >    
                        <MenuItem key="sqm" value="sqm">m&#178;</MenuItem>
                        <MenuItem key="sqft" value="sqft">ft&#178;</MenuItem>
                        <MenuItem key="sqkm" value="sqkm">km&#178;</MenuItem>
                        <MenuItem key="sqmi" value="sqmi">mi&#178;</MenuItem>
                    </TextField>
                </div>
            );
        }
        return resultBody;
    }
    const renderBody = () => {
        const result = renderResult();
        return (
            <div className={classes.root}>
                <div className={classes.buttonsContainer}>
                    {renderModeSwitcher()}
                </div>
                {result &&
                    <div className={classes.displayContainer}> 
                        {result}
                    </div>
                }
            </div>
        );
    }
    return (
        <TaskBar task="Measure" onShow={onShow} onHide={onHide}>
            {renderBody()}
        </TaskBar>
    );
};

Measure.propTypes = {
    measureState: PropTypes.object,
    mapcrs: PropTypes.string,
    displaycrs: PropTypes.string,
    changeMeasurementState: PropTypes.func,
    showMeasureModeSwitcher: PropTypes.bool
}

Measure.defaultProps = {
    showMeasureModeSwitcher: true
}

const selector = createSelector([state => state, displayCrsSelector], (state, displaycrs) => ({
    measureState: state.measurement,
    mapcrs: state.map.projection,
    displaycrs: displaycrs
}));

const MeasureWithStyles = withStyles(styles)(Measure)

export default {
    MeasurePlugin: connect(selector, {
        changeMeasurementState: changeMeasurementState
    })(MeasureWithStyles)
}
