/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Message from "../components/I18N/Message"
import {setCurrentTask} from "../actions/task";
import {DisplayPanel, withStyles} from "goodmap-core"
import React, { useState, useEffect, useCallback } from "react"
import Icon from './Icon';
import { useIntl } from 'react-intl';

const styles = (theme) => ({
    dialogTitleTypography: {
        marginRight: 16,
        marginLeft: 16
    },
    displayPanel: {
        borderRadius: 4,
        overflow: "hidden",
        left: 8,
        top: 62,
        bottom: 8
    }
})

const Window = ({
    id,
    children,
    currentTask,
    onShow,
    classes,
    onClose,
    modal,
    padding,
    windowClasses,
    onHide,
    setCurrentTask,
    title,
    maxWidth,
    maxHeight,
    position,
    icon,
    taskMode,
    extraBeforeContent,
    extraTitlebarContent
}) => {
    
    const [render, setRender] = useState(true)
    const {formatMessage} = useIntl()
    // const matchTablet = useMediaQuery(theme => theme.breakpoints.down("sm"))

    useEffect(() => {
        let visible = (!taskMode || currentTask.id === id);
        if(visible) {
            onShow(currentTask.mode)
        } else {
            onHide()
        }
        setRender(visible)
    }, [currentTask.id, currentTask.mode, id, render])
   
    const closeClicked = useCallback(() => {
        if(taskMode && currentTask.id === id) {
            setCurrentTask(null);
        }
        onClose()
    }, [onClose, setCurrentTask, taskMode, currentTask, id])
    
    const renderRole = (role) => {
        return React.Children.toArray(children).filter((child) => child.props.role === role);
    }

    let visible = currentTask.id === id;
    let renderOrVisible = visible || render;
   
    let contents = null;
    if(renderOrVisible && typeof children === "function") {
        contents = children();
    }

    if(!renderOrVisible){
        return null;
    }

    return renderOrVisible ? (
        <>
            <DisplayPanel 
                padding={padding}
                classes={{paper: classes.displayPanel}}
                onClose={closeClicked}
                position={position} 
                maxHeight={maxHeight}
                maxWidth={maxWidth}
                fade={modal}
                title={formatMessage({id: title})}
            >
                {(renderOrVisible && (extraTitlebarContent || extraBeforeContent)) ? <div className={classes.extraTitlebarContent}>{extraTitlebarContent}{extraBeforeContent}</div> : null}
                {renderOrVisible ? (contents ? contents.body || null : renderRole("body")) : null}
            </DisplayPanel>
            {renderOrVisible ? (contents ? contents.extra || null : renderRole("extra")) : null}
        </>
    ) : null
};

const selector = (state) => ({
    currentTask: state.task
});

Window.defaultProps = {
    modal: false,
    position: "left",
    maxWidth: 330,
    taskMode: true,
    onClose: () => {},
    onShow: (mode) => {},
    onHide: () => {},
}

Window.propTypes = {
    id: PropTypes.string.isRequired,
    taskMode: PropTypes.bool,
    currentTask: PropTypes.object,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    width: PropTypes.string,
    setCurrentTask: PropTypes.func,
    title: PropTypes.string,
    icon: PropTypes.string,
    extraBeforeContent: PropTypes.object,
    extraTitlebarContent: PropTypes.object
}

const StyledWindow = withStyles(styles)(Window);

export default connect(selector, {
    setCurrentTask: setCurrentTask,
})(StyledWindow)
