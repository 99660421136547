import ol_interaction_Select from 'ol/interaction/Select';
import { useMemo, useEffect } from "react";
import {click} from 'ol/events/condition';

export const useSelectInteraction = ({ 
    layers = [],
    hitTolerance = 5,
    style,
    onSelect,
}) => {

    const selectInteraction = useMemo(() => {
        return new ol_interaction_Select({
            layers,
            condition: click,
            style,
            hitTolerance
        })
    }, [layers, style, hitTolerance])

    useEffect(() => {
        selectInteraction.on('select', onSelect);
        return () => {            
            selectInteraction.un('select', onSelect);
        }
    }, [selectInteraction, onSelect])

    return { selectInteraction }  
  
}