import React, { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addLayerFeatures, removeLayerFeatures } from "../../actions/layers"
import Interaction from "../interaction/Interaction"
import ol_style_Style from 'ol/style/Style';
import uuid from 'uuid'
import FeatureStyles from "../../components/map/openlayers/FeatureStyles";
import { useMap } from "../hooks/useMap";
import { updateDrawingState } from "../../actions/drawing";
import {extractStylesFromFeature, selectedStyle} from "../utils"
import { useFeatureUtils } from "../hooks/useFeatureUtils";
import { createGoodmapFeature } from "../utils";
import FeatureAttributesFormDialog from "../../plugins/Redlining/FeatureAttributesFormDialog";


const DrawingSupport = () => {

    const drawing = useSelector(state => state.drawing)
    const map = useMap()
    const dispatch = useDispatch()
    const { calculateGeometryData } = useFeatureUtils()
    
    const setStyle = useCallback((feature, type) => {
        const olStyle = FeatureStyles[drawing.styleName](feature, drawing.style);

        if(type === "selected"){
            feature.setStyle([olStyle, selectedStyle])
        } else {
            feature.setStyle(olStyle)
        }

    }, [drawing.style, drawing.styleName])

    
    const searchRedliningLayer = useCallback((layerId) => {
        let redliningLayer = null;
        map.getLayers().forEach(olLayer => {
            if(olLayer.get('msId') === layerId) {
                redliningLayer = olLayer;
            }
        });
        return redliningLayer;
    }, [map])

    const redliningLayer = useMemo(() => searchRedliningLayer(drawing.layer.id), [drawing.layer.id, searchRedliningLayer]);


    const activeFeature = useMemo(() => {
        if(!drawing.selectedFeature) return;
        const fid = drawing.selectedFeature.id
        const f = redliningLayer.getSource().getFeatureById(fid)
        return f;
    }, [drawing.selectedFeature, redliningLayer])

    const getGeomType = useCallback(() => {
        if(drawing.geomType === "Point" || drawing.geomType === "Marker" || drawing.geomType === "Text"){
            return "Point"
        } else {
            return drawing.geomType
        }
    }, [drawing.geomType])

     /**
     * CREATE OR UPDATE FEATURE IN ACTIVE DRAWING LAYER
     */
      const createOrUpdateFeature = useCallback((feature) => {
        dispatch(addLayerFeatures({id: drawing.layer.id}, [feature]))
    }, [dispatch, drawing.layer.id])


    const upsertSelectedFeature = useCallback((feature) => {
        if(feature){
            const goodmapFeature = createGoodmapFeature({
                feature, 
                style: drawing.style, 
                styleName: drawing.styleName
            });
            createOrUpdateFeature(goodmapFeature) // prida feature do vrstvy
            dispatch(updateDrawingState({selectedFeature: goodmapFeature}))
        } else {
            dispatch(updateDrawingState({selectedFeature: null}))
        }
    }, [dispatch, createOrUpdateFeature, drawing.style, drawing.styleName])

    /**
     * EFFECTS
     */
    useEffect(() => {
        if(!drawing.active){
            upsertSelectedFeature()
        } else if(activeFeature && drawing.action === "Delete"){
            dispatch(removeLayerFeatures(drawing.layer.id, [activeFeature.getId()]))
            dispatch(updateDrawingState({action: "Pick", geomType: null}))
            upsertSelectedFeature()
        } else if(activeFeature) {
            setStyle(activeFeature, "selected")
            upsertSelectedFeature(activeFeature)
        }
           
        return () => {
            activeFeature && setStyle(activeFeature)
        }
    }, [setStyle, drawing.active, drawing.action, upsertSelectedFeature, activeFeature, drawing.layer, dispatch])

   
    /**
     * CALCULATE GEOM DATA
     */
    const calcGeomData = useCallback((feature) => {
        const geometry = feature.getGeometry();
        const type = geometry.getType();
        const geomData = calculateGeometryData(feature);
        if(type === "Polygon"){
            feature.set("computed_area", geomData.area)
        } else if (type === "LineString"){
            feature.set("computed_length",geomData.length)
        } else if (type === "Point"){
            feature.set("computed_coors", geomData.coors)
        }
    }, [calculateGeometryData])


    const handleAttributeDialogClose = useCallback(() => {
        upsertSelectedFeature()
        dispatch(updateDrawingState({action: "Pick"}))
    }, [dispatch, upsertSelectedFeature])

    
    /**
     * SAVE ATTRIBUTES
     */
    const handleAttributesSave = useCallback((updatedGmFeature) => {
        activeFeature.setProperties(updatedGmFeature.properties)
        createOrUpdateFeature(updatedGmFeature)
        handleAttributeDialogClose()
    }, [activeFeature, handleAttributeDialogClose, createOrUpdateFeature])

    /**
     * CREATE OR UPDATE DRAWING LAYER FEATURE
     */
    const upsertDrawingLayerFeature = useCallback((feature) => {
        calcGeomData(feature)
        upsertSelectedFeature(feature)
    }, [calcGeomData, upsertSelectedFeature])

    /**
     * Handle DRAW start
     */
    const handleDrawStart = useCallback((e) => {
        activeFeature && setStyle(activeFeature)
        const feature = e.feature;
        feature.setId(uuid.v4());
        setStyle(feature, "selected")
    }, [setStyle, activeFeature])
    
    /**
     * Handle DRAW end
     */
    const handleDrawEnd = useCallback((e) => {
        const feature = e.feature;
        upsertDrawingLayerFeature(feature)
    }, [upsertDrawingLayerFeature])

    /**
     * Handle MODIFY end
     */
    const handleModifyEnd = useCallback((e) => {
        upsertDrawingLayerFeature(activeFeature)
    }, [ activeFeature, upsertDrawingLayerFeature ])


    /**
     * Handle SELECT
     */
    const handleSelectFeature = useCallback((e) => {
        if(!e.selected.length) {
            upsertSelectedFeature();
            return;
        }
        const feature = e.selected[0];
        const newState = extractStylesFromFeature(feature, drawing.style)
        upsertSelectedFeature(feature);
        dispatch(updateDrawingState({...newState}))

    }, [dispatch, drawing.style, upsertSelectedFeature])

    const defaultDrawStyle = useMemo(() => new ol_style_Style(),[]);

    if(!drawing.active) return null;

    return (
        <>
            <Interaction 
                select={{
                    active: drawing.action === "Pick",
                    layers: redliningLayer ? [redliningLayer] : [],
                    onSelect: handleSelectFeature
                }}
                modify={{
                    active: activeFeature ? true : false,
                    features: activeFeature ? [activeFeature] : [],
                    modifyEnd: handleModifyEnd,
                    modifyStart: () => {}
                }}
                draw={{
                    temporaryPickMode: true,
                    active: drawing.action === "Draw",
                    geometryType: getGeomType(),
                    style: defaultDrawStyle,
                    drawStart: handleDrawStart,
                    drawEnd: handleDrawEnd
                }}
            />
            {
                <FeatureAttributesFormDialog 
                    onClose={handleAttributeDialogClose} 
                    onSubmit={handleAttributesSave} 
                    open={(drawing.selectedFeature && drawing.action === "ShowAttributes")}
                />
            }
        </>
    )
}

DrawingSupport.defaultProps = {

}

DrawingSupport.propTypes = {
    
}

export default DrawingSupport