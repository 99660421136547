/**
 * Copyright 2015, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { TOGGLE_NAV} from '../actions/global';

function global(state = {
    toggleNav: false
}, action) {
    switch (action.type) {
        case TOGGLE_NAV: {
            return {
                ...state,
                toggleNav: action.state === undefined ? !state.toggleNav : action.state
            }
        }
        default:
            return state;
    }
}

export default global;
