/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import {
    DRAWING_ENABLE,
    UPDATE_DRAWING_STATE,
    UPDATE_DRAWING_STYLE
} from '../actions/drawing';

function drawing(state = {
    active: false,
    created: [],
    updated: [],
    removed: [],
    action: null,
    geomType: null,
    selectedFeature: null,
    styleName: "default",
    style: {
        stroke: { width: 2, color: [255, 0, 0, 1] },
        icon: { src: "PIN_Red.svg", size: 2, scale: 4 },
        point: { radius: 5 },
        fill: { color: [255, 255, 255, 1] },
        text: { 
            value: "", 
            size: 11,
            borderSize: 4,
            fill: [255, 0, 0, 1],
            stroke: [255, 255, 255, 0.75] 
        }
    },
    layer: {
        id: "drawing",
        title: "Drawing",
        properties: []
        // TODO: mergnut so state vectorlayer.js (reducers, actions), pre LayerDefinition dialog
    }
}, action) {
    switch (action.type) {
        case UPDATE_DRAWING_STATE:
            return {
                ...state,
                ...action.data
            }
        case UPDATE_DRAWING_STYLE:
            
            const newStyle = {
                ...state.style,
                ...action.style,
                stroke: { ...state.style.stroke, ...action.style.stroke},
                icon: { ...state.style.icon, ...action.style.icon},
                point: { ...state.style.point, ...action.style.point},
                fill: { ...state.style.fill, ...action.style.fill},
                text: { ...state.style.text, ...action.style.text},
            };

            return {
                ...state, 
                style: newStyle
            }
        case DRAWING_ENABLE:
            return {
                ...state,
                active: action.active
            }
        default:
            return state;
    }
}

export default drawing;
