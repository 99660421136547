import { createMuiTheme } from "goodmap-core";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      dark: "#0047a6",
      main: "#0065c1",
      light: "#5488df"
    },
    secondary: {
      dark: "#00377d",
      main: "#005ba5",
      light: "#538ddf"
    },
    error: {
      main: "#ff0000",
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontSize: "6rem"
    },
    h2: {
      fontSize: "3.75rem"
    },
    h3: {
      fontSize: "3rem"
    },
    h4: {
      fontSize: "2.125rem"
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 600
    },
    h6: {
      fontSize: "1.1rem"
    },
    body1: {
      fontSize: "1rem"
    },
    body2: {
      fontSize: "0.875rem"
    },
    button: {
      fontSize: "0.875rem"
    }
  }
});

export default theme;