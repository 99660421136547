/**
* Copyright 2017, Sourcepole AG.
* All rights reserved.
*
* This source code is licensed under the BSD-style license found in the
* LICENSE file in the root directory of this source tree.
*/

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '../Icon'
import './style/ToggleSwitch.css'

class ToggleSwitch extends React.Component {
    static propTypes = {
        active: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        name: PropTypes.string
    }
    render() {
        let classNames = classnames({
            "ToggleSwitch": true,
            "toggle-switch-active": this.props.active,
            "toggle-switch-inactive": !this.props.active
        });
        return (
            <div className={classNames} onClick={() => this.props.onChange(!this.props.active)}>
                <span className="toggle-switch-yes"><Icon icon="ok" /></span>
                <span className="toggle-switch-slider"><Icon icon="menu-hamburger" /></span>
                <span className="toggle-switch-no"><Icon icon="remove" /></span>
                {/* Ensure toggle switch appears in form.elements */}
                <input type="checkbox" name={this.props.name} value={this.props.active} style={{visibility: 'none'}} />
            </div>
        );
    }
};

export default ToggleSwitch;
