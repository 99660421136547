import React from "react"
import DrawInteraction from "./DrawInteraction"
import ModifyInteraction from "./ModifyInteraction"
import SelectInteraction from "./SelectInteraction"

const Interaction = ({
    select,
    modify,
    draw
}) => {

    return (
        <>
            {
                select.active && <SelectInteraction 
                   {...select}
                />
            }
            {
                draw.active && <DrawInteraction 
                    {...draw}
                />
            }
            {
                modify.active && <ModifyInteraction 
                   {...modify}
                />
            }
        </>
    )
}

Interaction.propTypes = {

}

Interaction.defaultProps = {
    draw: {},
    modify: {},
    select: {}
}

export default Interaction