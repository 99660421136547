/* eslint-disable */
import React from "react";
import {withStyles, Fab} from "goodmap-core";
import { object, any, string } from "prop-types";
import Icon from './Icon';

const styles = (theme) => ({
    root: ({ position }) => ({
        position: "absolute",
        zIndex: 1,
        color: theme.palette.primary.contrastText,
        ...position
    })
})

const MapButton = ({
    classes, 
    tooltip, 
    icon, 
    type, 
    children, 
    fontSize,
    ...rest
}) => {
    return(
        <Fab placement={"top"} tooltip={tooltip} aria-label={tooltip} color="primary" size="small" {...rest} className={classes.root}>
            {children || icon && <Icon icon={icon} fontSize={fontSize} type={type} />}
        </Fab>
    ) 
}

MapButton.propTypes = {
    classes: object,
    tooltip: string,
    children: any,
    icon: string,
    position: object
}

MapButton.defaultProps = {
    position: {},
    fontSize: 16
}

export default withStyles(styles)(MapButton)
