/**
 * Copyright 2015, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import LocaleUtils from '../utils/LocaleUtils'
import messages from "../i18n"

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const LOCALE_LOAD_ERROR = 'LOCALE_LOAD_ERROR';

export const changeLocale = (data) => {
    return {
        type: CHANGE_LOCALE,
        messages: data.messages,
        locale: data.locale
    };
}

export const localeError = (e) => {
    return {
        type: LOCALE_LOAD_ERROR,
        error: e
    };
}

export const loadLocale = (translationFolder, language) => {
    return (dispatch) => {
        let locale = language;
        if (!locale) {
            locale = LocaleUtils.getUserLocale();
        }

        dispatch(changeLocale({
            locale: locale, 
            messages: messages[locale]
        }));
        // return axios.get((translationFolder || ConfigUtils.getConfigProp('translationsPath')) + '/data.' + locale).then((response) => {
        //     if (typeof response.data === "string") {
        //         try {
        //             JSON.parse(response.data);
        //         } catch(e) {
        //             console.warn('Locale file broken  for (' + language + '): ' + e.message);
        //             dispatch(localeError('Locale file broken  for (' + language + '): ' + e.message));
        //         }
        //     } else {
        //         dispatch(changeLocale(response.data));
        //     }
        // }).catch((e) => {
        //     dispatch(localeError(e));
        // });
    };
}
