/* eslint-disable */
/**
 * Copyright 2015-2016, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {changeLocateState} from '../actions/locate';
import { useEffect } from "react";
import { useIntl } from "react-intl";
import MapButton from "../components/MapButton"
import { Loader } from 'goodmap-core/dist';
import locate from '../reducers/locate'

const LocateButton = ({
    locateState,
    changeLocateState,
    position
}) => {
   
    const {formatMessage} = useIntl()
    
    useEffect(() => {
            if(!navigator.geolocation) {
                changeLocateState("PERMISSION_DENIED");
            } else {
                navigator.geolocation.getCurrentPosition(() => {
                    // OK!
                }, (err) => {
                    if (err.code === 1) {
                        changeLocateState("PERMISSION_DENIED");
                    }
                });
            }
    }, [changeLocateState])
   
    const onClick = useCallback(() => {
        if(locateState === "DISABLED") {
            changeLocateState("ENABLED");
        } else if(locateState === "ENABLED") {
            changeLocateState("FOLLOWING");
        } else {
            changeLocateState("DISABLED");
        }
    }, [changeLocateState, locateState])


    const tooltip = useMemo(() => formatMessage({id: "locate.statustooltip." + locateState}), [locateState])
    
    return (
        <MapButton 
            icon={"MdGpsFixed"}
            tooltip={tooltip} 
            disabled={locateState === "PERMISSION_DENIED"}
            position={{bottom: (`${5 + 3.5 * position}em`), right: 8}} 
            onClick={onClick}
        >
            {locateState === "LOCATING" && <Loader/>}
        </MapButton>
    );
};

LocateButton.defaultProps = {
    position: 2
}

LocateButton.propTypes = {
    locateState: PropTypes.string,
    changeLocateState: PropTypes.func,
    position: PropTypes.number
}

export default {
    LocateButtonPlugin: connect(state => ({
        locateState: state.locate.state || 'DISABLED'
    }), {
        changeLocateState: changeLocateState
    })(LocateButton),
    reducers: {locate: locate}
};
