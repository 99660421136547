/* eslint-disable */
/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setCurrentTask} from '../actions/task';
import MapButton from "../components/MapButton";
import {useIntl} from "react-intl"

const TaskButton = ({
    task,
    mode,
    icon,
    tooltip,
    position,
    currentTask,
    setCurrentTask,
    mapClickAction,
}) => {
   
    const { formatMessage } = useIntl();

    return (
        <MapButton 
            icon={icon}
            tooltip={tooltip && formatMessage({id: tooltip})}
            position={{bottom: (`${5 + 3.5 * position}em`), right: 8}} 
            onClick={() => {
                setCurrentTask(currentTask === task ? null : task, mode, mapClickAction);
            }}
        />
    )
    
};

TaskButton.defaultProps = {
    mode: null,
    tooltip: "",
    position: 1
}

TaskButton.propTypes = {
    task: PropTypes.string,
    mode: PropTypes.string,
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    position: PropTypes.number,
    currentTask: PropTypes.string,
    setCurrentTask: PropTypes.func,
    mapClickAction: PropTypes.string,
}

const selector = (state) => ({
    currentTask: state.task && state.task.id || null
});

export default {
    TaskButtonPlugin: connect(selector, {
      setCurrentTask: setCurrentTask,
  })(TaskButton),
    reducers: {
    }
};
