import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import TaskBar from '../../components/TaskBar';
import BufferSupport from "./BufferSupport"
import RedliningTools from './RedliningTools';
import RedliningControls from './RedliningControls';
import { useDispatch, useSelector } from 'react-redux';
import { updateDrawingState } from '../../actions/drawing';

const Redlining = () => {

    const dispatch = useDispatch()
    const {action} = useSelector(state => state.drawing)
    const [{showControls}, setState] = useState({showControls: false})

    const onShow = useCallback(() => {
        dispatch(updateDrawingState({active: true}))
    }, [dispatch])

    
    const onHide = useCallback(() => {
        dispatch(updateDrawingState({
            active: false, 
            action: null,
            geomType: null
        }))
    }, [dispatch])


    const handleShowControls = useCallback(() => {
        if(action !== "Draw" && action !== "Pick"){
            dispatch(updateDrawingState({action: "Draw", geomType: "Point" }))
        }
        setState(s => ({
            ...s, 
            showControls: !showControls
        }))
    }, [action, showControls, dispatch])

    
    return (
        <>
            <TaskBar 
                onShow={onShow}
                onHide={onHide}
                extraContent={
                    <RedliningControls 
                        handleShowControls={handleShowControls} 
                        open={showControls} 
                    />
                }
                task="Redlining" 
            >
               <RedliningTools 
                    showControls={showControls}
                    handleShowControls={handleShowControls}
               />
            </TaskBar>
        </>
    );
};

Redlining.propTypes = {
    allowGeometryLabels: PropTypes.bool,
    plugins: PropTypes.object
}

Redlining.defaultProps = {
    allowGeometryLabels: true,
    plugins: [BufferSupport]
}

const RedliningPlugin = {
    RedliningPlugin: Redlining
}

export default RedliningPlugin
