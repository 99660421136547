import React, { useCallback, useState } from "react"
import {
    makeStyles,
    ExpansionPanel,
    ExpansionPanelDetails,
    List,
    ListItem,
    ListItemText,
    Pagination,
    ExpansionPanelSummary,
    Typography
} from "goodmap-core"
import { 
    MdDescription, 
    MdExpandMore, 
    MdHome, 
    MdList, 
    MdPerson
} from "react-icons/md"
import { useDispatch } from "react-redux"
import { addLayerFeatures, LayerRole } from '../../actions/layers';

const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    chip: {
        margin: theme.spacing(1)
    },
    resultContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    panelSummary: {
        display: "flex",
        alignItems: "center"
    },
    expansionPanelDetail: {
        padding: 0
    },
    expansionPanelTitle: {
        lineHeight: 1.9,
        paddingLeft: theme.spacing(2)
    },
}))

const Result = ({
    setPagination,
    data,
    exportButton,
    pagination
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const [ expanded, setExpanded ] = useState(false);


    const highlightParcel = (parcel) => {
        const layer = { id: "parcelselection", role: LayerRole.SELECTION, styleName: 'highlight' };
        dispatch(addLayerFeatures(layer, [parcel], true));
    }

    const getPageCount = (itemsCount) => {
        
        if(isNaN(itemsCount)){
            return 0
        }

        let count = itemsCount / PAGE_SIZE;
        const modulo = itemsCount % PAGE_SIZE;
        if(count <= 1){
            return 1;
        }
        count = parseInt(count);
        if(modulo > 0){
            count++    
        }    
        return count;
    }

    const handleChange = (panel, event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const handleSetPagination = useCallback((resource, page) => {
        setPagination({
            ...pagination,
            [resource]: page - 1
        })
    }, [pagination, setPagination])


    return (
        <div className={classes.root}>
            <ExpansionPanel expanded={expanded === 'panel1'} onChange={(event, isExpanded) => {
                handleChange('panel1', event, isExpanded)
            }}>
                <ExpansionPanelSummary
                    className={classes.panelSummary}
                    expandIcon={<MdExpandMore/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <MdList fontSize="2em" />
                    <Typography className={classes.expansionPanelTitle}>Parcely</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanelDetail}>
                     <List dense>
                        {
                            data.parcels.items.map(parcel => (
                                <ListItem 
                                    onMouseOver={() => highlightParcel(parcel)} 
                                    button
                                >
                                    <ListItemText 
                                        primary={`${parcel.parcel}, Výmera: ${parcel.area} m2, LV: ${parcel.folio}`}  
                                    />                                
                                </ListItem>
                            ))
                        }                        
                    </List> 
             </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel2'} onChange={(event, isExpanded) => {
                handleChange('panel2', event, isExpanded)
            }}>
                <ExpansionPanelSummary
                    className={classes.panelSummary}
                    expandIcon={<MdExpandMore/>}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <MdPerson fontSize="2em" />
                    <Typography className={classes.expansionPanelTitle}>Účastníci</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanelDetail}>
                    <List dense>
                    {
                        data.participants.items.map(participant => (
                            <ListItem button>
                                <ListItemText primary={participant.vla} />                                
                            </ListItem>
                        ))
                    }                     
                    <Pagination count={getPageCount(data.participants.count)} page={pagination.participant + 1} onChange={(e, value) => handleSetPagination("participant", value)} />
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel3'} onChange={(event, isExpanded) => {
                handleChange('panel3', event, isExpanded)
            }}>
                <ExpansionPanelSummary
                    className={classes.panelSummary}
                    expandIcon={<MdExpandMore/>}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <MdDescription fontSize="2em" />
                    <Typography className={classes.expansionPanelTitle}>Listy vlastníctva</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanelDetail}>
                    <List dense>
                    {
                        data.folios.items.map(folio => (
                            <ListItem button>
                                <ListItemText
                                    primary={`${folio.folio} - ku. ${folio.ku}`}
                                />                                
                            </ListItem>
                        ))
                    }
                    <Pagination count={getPageCount(data.folios.count)} page={pagination.folio + 1} onChange={(e, value) => handleSetPagination("folio", value)} />
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel4'} onChange={(event, isExpanded) => {
                handleChange('panel4', event, isExpanded)
            }}>
                <ExpansionPanelSummary
                    className={classes.panelSummary}
                    expandIcon={<MdExpandMore/>}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <MdHome fontSize="2em" />
                    <Typography className={classes.expansionPanelTitle}>Stavby</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanelDetail}>
                    <List dense>
                    {
                        data.constructions.items.map(construction => (
                            <ListItem button>
                                <ListItemText
                                    primary={`Stavba č. ${construction.pec}, výmera: ${construction.area} m2, ${construction.pkk}`}
                                />                                
                            </ListItem>
                        ))
                    }
                    <Pagination count={getPageCount(data.constructions.count)} page={pagination.construction + 1} onChange={(e, value) => handleSetPagination("construction", value)} />
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel5'} onChange={(event, isExpanded) => {
                handleChange('panel5', event, isExpanded)
            }}>
                <ExpansionPanelSummary
                    className={classes.panelSummary}
                    expandIcon={<MdExpandMore/>}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <MdDescription fontSize="2em" />
                    <Typography className={classes.expansionPanelTitle}>Právne vzťahy</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanelDetail}>
                    <List dense>
                    {
                        data.legals.items.map(legal => (
                            <ListItem button>
                                <ListItemText
                                    primary={`${legal.poz}, lv: ${legal.folio}, ku: ${legal.ku}`}
                                />                                
                            </ListItem>
                        ))
                    }
                    <Pagination count={getPageCount(data.legals.count)} page={pagination.legal + 1} onChange={(e, value) => handleSetPagination("legal", value)} />
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            {exportButton}
        </div>
    )
}

export default Result