/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import Window from "../../components/Window";
import {Button, useUserInfo, List, ListItem, 
//    Logout, 
ListItemText, makeStyles, useAuth} from "goodmap-core";
import { useIntl } from 'react-intl';
import {BiLogOut} from "react-icons/bi"

const UserProfile = (props) => {
   
    const { formatMessage } = useIntl();
    const {keycloak} = useAuth()
    const userInfo = useUserInfo()
    const classes = useStyles()


    return (
        <Window modal maxWidth={500} maxHeight={300} position="center" padding={1} id="UserProfile" width="20em" title="appmenu.items.UserProfile"
            icon="info">
            {() => ({
                body: (
                    <div>
                        <List>
                            <ListItem>
                                <ListItemText primary={formatMessage({id: "username"})} secondary={userInfo.name} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={formatMessage({id: "email"})} secondary={userInfo.email} />
                            </ListItem>
                        </List>
                        <div className={classes.actions}>
                            {/* <Logout> */}
                                <Button variant="contained" onClick={() => keycloak.logout()} color="primary" endIcon={<BiLogOut/>}>{formatMessage({id: "logout"})}</Button>
                            {/* </Logout> */}
                        </div>
                    </div>
                )
            })}
        </Window>
    );
};

UserProfile.defaultProps = {}

UserProfile.propTypes = { }

const useStyles = makeStyles((theme) => ({
    root: {
        
    },
    actions: {
        display: "flex",
        padding: theme.spacing(1),
        justifyContent: "flex-end"
    }
}))

export default {
    UserProfilePlugin: UserProfile,
};
