import { useMap } from "./useMap"
import ol_geom_Polygon from 'ol/geom/Polygon'
import {getDistance, getArea} from 'ol/sphere'
import CoordinatesUtils from "../../utils/CoordinatesUtils";

export const useFeatureUtils = () => {
    const map = useMap()

    const reprojectedCoordinates = (coordinates) => {
        const view = map.getView();
        return coordinates.map((coordinate) => {
            return CoordinatesUtils.reproject(coordinate, view.getProjection().getCode(), 'EPSG:4326');
        });
    }
    
    const calculateGeodesicDistances = (coordinates) => {
        let rc = reprojectedCoordinates(coordinates);
        let lengths = [];
        for (let i = 0; i < rc.length - 1; ++i) {
            lengths.push(getDistance(rc[i], rc[i + 1]));
        }
        return lengths;
    }
    
    const calculateGeodesicArea = (coordinates) => {
        const rc = reprojectedCoordinates(coordinates);
        const geom = new ol_geom_Polygon([rc]);
        return Math.abs(getArea(geom, {projection: 'EPSG:4326'}));
    }

    const calculateGeometryData = (feature) => {
        const geometry = feature.getGeometry();
        const coordinates = geometry.getCoordinates();
        const type = geometry.getType();
        let measure = null;
        const geomData = {}
        if(type === "Polygon"){
            measure = calculateGeodesicArea(coordinates[0])
            measure = `${measure.toFixed(2)} m2`;
            geomData.area = measure;
        } else if (type === "LineString"){
            const values = calculateGeodesicDistances(coordinates)
            measure= values.reduce((next, prev) => next + prev)
            measure = `${measure.toFixed(2)} m`;
            geomData.length = measure;
        } else if (type === "Point"){
            measure = reprojectedCoordinates([coordinates])
            measure = measure && measure[0].map(x => x.toFixed(5)).join(", ")
            geomData.coors = measure;
        }
        return geomData;
    }

    return {
        reprojectedCoordinates,
        calculateGeodesicArea,
        calculateGeodesicDistances,
        calculateGeometryData
    }
}