/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
 import {injectIntl} from "react-intl";
 import { Search as MapSearch } from "goodmap-core";
 import {withStyles} from "goodmap-core";
 import { SearchProviders, searchProviderFactory } from "./searchProviders"
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import assign from 'object-assign'
import {createSelector} from 'reselect'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import ol from '@openlayers'
import MapUtils from '../../utils/MapUtils'
import LayerUtils from '../../utils/LayerUtils'
import CoordinatesUtils from '../../utils/CoordinatesUtils'
import {LayerRole, addLayerFeatures, removeLayer, addLayer, addThemeSublayer, changeLayerProperty} from '../../actions/layers'
import {zoomToPoint} from '../../actions/map'
import {addSearchResults, changeSearch, startSearch, searchMore, setCurrentSearchResult, SearchResultType} from "../../actions/search"
import {setCurrentTask} from '../../actions/task'
import {setCurrentTheme} from '../../actions/theme'
import {showNotification} from '../../actions/windows'
import searchProvidersSelector from '../../components/selectors/searchproviders'
import displayCrsSelector from '../../components/selectors/displaycrs'
import ThemeUtils from '../../utils/ThemeUtils'
import VectorLayerUtils from '../../utils/VectorLayerUtils'
import {UrlParams} from "../../utils/PermaLinkUtils"
import { toggleNavigation } from "../../actions/global";


const styles = (theme) => ({
    searchRoot: {
        position: "absolute",
        top: theme.spacing(1),
        left: theme.spacing(1),
    },
    root: {
        position: "absolute",
        top: 8,
        outline: 'none',
        right: 10,
        boxShadow: "0 1px 4px rgba(0,0,0,.3)",
        flex: "1 1 auto",
        display: "inline-flex",
        alignItems: "center",
        verticalAlign: "middle",
        maxWidth: 330,
        width: "100%",
        zIndex: 1,
        [theme.breakpoints.down("xs")]: {
            maxWidth: "none",
            width: "auto",
            paddingLeft: 40,
            left: 10
        }
    },
    searchBarContainer: {
        width: "100%",
        display: "inline-flex"
    },
    searchBarWrapper: {
        position: "relative",
        display: "inline-block",
        flex: "1 1 auto",
        height: 40,
    },
    searchBar: {
        flex: "1 1 auto",
        height: 40,
        padding: "0.25em 0.75em",
        border: "none",
        paddingLeft: "12px !important",
        width: 0,
        minWidth: "10ch",
    },
    searchBarAddon: {
        flex: "0 0 auto",
        height: 40,
        width: 40,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        position: "relative",
        alignItems: "center",
        display: "flex",
        justifyContent: "center"
    },
    searchBarAddonFilter: {
        borderLeft: "0.2px solid white"
    },
    searchBarAddonActive: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    searchBarAddonFilterActive: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    searchBarMenuIcon:{
        position: "absolute",
        right: "0.125em",
        bottom: "0.125em",
        fontSize: "70%",
    },
    searchbarProviderSelection: {
        position: "absolute",
        top: "100%",
        right: "-1px",
        backgroundColor: "#fff",
        color: theme.palette.common.black,
        listStyleType: "none",
        margin: 0,
        padding: 0,
        "& li": {
            padding: "0.5em",
            whiteSpace: "nowrap",
        }
    },
    searchbarProviderSelectionAll: {
        borderBottom: "1px solid #ccc",
    },
    searchbarProviderSelectionActive: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        
    }
})

class Search extends React.Component {
    static propTypes = {
        searchText: PropTypes.string,
        activeProviders: PropTypes.array, // The active provider keys
        pendingProviders: PropTypes.array, // Providers for which results are pending
        searchProviders: PropTypes.object, // All available search providers
        startupSearch: PropTypes.bool,
        results: PropTypes.array,
        currentResult: PropTypes.object,
        theme: PropTypes.object,
        themes: PropTypes.object,
        map: PropTypes.object,
        displaycrs: PropTypes.string,
        changeSearch: PropTypes.func,
        startSearch: PropTypes.func,
        searchMore: PropTypes.func,
        panToResult: PropTypes.func,
        addLayerFeatures: PropTypes.func,
        removeLayer: PropTypes.func,
        addLayer: PropTypes.func,
        addThemeSublayer: PropTypes.func,
        setCurrentTask: PropTypes.func,
        setCurrentTheme: PropTypes.func,
        showNotification: PropTypes.func,
        searchOptions: PropTypes.object,
        layers: PropTypes.array,
        changeLayerProperty: PropTypes.func,
        startupParams: PropTypes.object
    }
    static contextTypes = {
        messages: PropTypes.object
    }
    state = {
        focused: false, showfields: false, providerSelectionVisible: false
    }
    componentDidMount() {
        this.input = null;
        this.searchTimer = 0;
        this.preventBlur = false;
        this.blurred = false;

        let sp = UrlParams.getParam('sp');
        this.props.changeSearch(UrlParams.getParam('st') || "", sp ? sp.split(",") : null);
    }
    componentWillReceiveProps(newProps) {
        // If search text changed, clear result
        if(newProps.searchText !== this.props.searchText) {
            this.props.removeLayer('searchselection');
        }
        // If the theme changed, reset search and select provider
        if(newProps.theme && (newProps.theme !== this.props.theme || !isEqual(Object.keys(newProps.searchProviders), Object.keys(this.props.searchProviders)))) {
            // Only reset search text if the theme was changed (as opposed to the initial theme loaded)
            let searchText = this.props.theme ? "" : newProps.searchText;

            // Ensure search providers references valid providers
            let activeProviders = newProps.activeProviders;
            if(!newProps.searchOptions.showProviderSelection || isEmpty(newProps.searchProviders)) {
                activeProviders = null;
            } else {
                activeProviders = (activeProviders || []).filter(key => newProps.searchProviders[key] !== undefined);
                if(isEmpty(activeProviders)) {
                    activeProviders = newProps.searchOptions.providerSelectionAllowAll ? null : [newProps.searchProviders[0].key];
                }
            }

            newProps.changeSearch(searchText, activeProviders);

            // If initial theme loaded and a search text is defined, fire off the search
            if(!this.props.theme) {
                this.search(assign({}, newProps, {activeProviders}), true);
            }
        }
        else if(newProps.results && newProps.results !== this.props.results && isEmpty(newProps.pendingProviders)) {
            // If results changed and a unique result is returned, select it automatically if it is a Place result
            if(newProps.results.length === 1 && newProps.results[0].items.length == 1) {
                let item = newProps.results[0].items[0];
                if((item.type || SearchResultType.PLACE) === SearchResultType.PLACE) {
                    this.showResult(item, newProps.startupSearch, newProps.startupSearch);
                }
            }
            // If multiple results are available and search field is not focused, focus it (unless explicitly blurred before)
            else if(this.input && !this.blurred) {
                this.input.focus();
            }
        }
    }
    killEvent = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
    }
    search = (props, startup=false)  => {
        if(props.searchText) {
            this.setState({invisibleLayerQuery: null});
            props.startSearch(props.searchText, {displaycrs: props.displaycrs}, this.activeProviders(props), startup);
        }
    }
    resetSearch = () => {
        this.setState({focused: false, showfields: false, invisibleLayerQuery: null});
        this.props.changeSearch("", this.props.activeProviders);
    }
    onChange = (ev) => {
        this.props.changeSearch(ev.target.value, this.props.activeProviders);
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => this.search(this.props), 500);
    }
    checkShowFields = (ev) => {
        if((this.props.activeProviders || []).length === 1 && this.props.searchProviders[this.props.activeProviders[0]].fields) {
            this.setState({showfields: true, focused: false});
            ev.preventDefault();
        }
    }
    onFocus = () => {
        if(!this.state.showfields && this.props.searchText && !this.props.results) {
            this.search(this.props);
        }
        this.setState({focused: true});
        this.blurred = false;
    }
    onBlur = (ev) => {
        if(this.preventBlur && this.input) {
            this.input.focus();
        } else {
            this.setState({focused: false});
        }
        this.blurred = true;
    }
    onKeyDown = (ev) => {
        if(ev.keyCode === 13) {
            this.search(this.props);
        } else if(ev.keyCode === 27) {
            ev.target.blur();
        }
    }
    activeProviders = (props) => {
        let keys = isEmpty(props.activeProviders) ? Object.keys(props.searchProviders) : props.activeProviders;
        return keys.reduce((result, key) => {
            if(props.searchProviders[key]) {
                result[key] = props.searchProviders[key];
            }
            return result;
        }, {});
    }
    checkProvider = (providerKey) => {
        let keys = isEmpty(this.props.activeProviders) ? Object.keys(this.props.searchProviders) : this.props.activeProviders;
        return keys.includes(providerKey)
    }
    render() {
        let placeholder = "";
        if(this.props.searchOptions.showProvidersInPlaceholder || !isEmpty(this.props.activeProviders)) {
            placeholder = this.props.intl.formatMessage({id: "search.search"});
            let providers = this.activeProviders(this.props);
            if(!isEmpty(providers)) {
                placeholder +=  ": " + Object.values(providers).map(prov => {
                    return prov.labelmsgid ? this.props.intl.formatMessage({id:prov.labelmsgid}) : prov.label;
                }).join(", ");
            }
        } else {
            placeholder = this.props.intl.formatMessage({id: "search.searchall"})
        }
      
        this.formfields = {};
        
        const renderSearchProviders = () => {
            if(!this.props.searchOptions.showProviderSelection){
                return []
            }
            const providers = this.props.searchProviders;
            const providersArray = Object.entries(providers).map(([key, prov]) => {
                return ({
                    id: key,
                    active: this.checkProvider(key),
                    text: prov.labelmsgid ? this.props.intl.formatMessage({id:prov.labelmsgid}) : prov.label
                })
            })
            if(this.props.searchOptions.providerSelectionAllowAll){
                return [{id: "search.all", text: "Hľadať všetko"}, ...providersArray]
            }
            return providers;
        }

        const renderResult = () => {
            const r = this.props.results;
            console.log(r)
            if(!r || !r.length){
                return []
            }
            return r.reduce((prev,next) => {
                return [...prev, ...next.items.map((i) => ({
                    ...i,
                    action: "",
                    content: i.text,
                    geom: "geom",
                    description: null
                }))]
            }, [])
            
        }

        return <MapSearch 
            onMenuClick={this.props.toggleNavigation}
            placeholder={placeholder}
            classes={{root: this.props.classes.searchRoot}} 
            ref={el => this.input = el}
            value={this.props.searchText}
            onMenuItemClick={(item) => {
                if(item.id === "search.all"){
                    this.props.changeSearch("", null)
                } else {
                    this.props.changeSearch("", [item.id])
                }
            }}
            onMouseDown={this.checkShowFields}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            showClose={true}
            onProfileButtonClick={() => this.props.setCurrentTask('UserProfile')}
            menuItems={renderSearchProviders()}
            onCloseClick={() => alert("onCloseClick")}
            results={renderResult()}
            onResultClick={(item) => {
                this.showResult(item)
            }}
        />
    }
    showResult = (item, zoom=true, startupSearch=false) => {
        let resultType = item.type || SearchResultType.PLACE;
        if(resultType !== SearchResultType.PLACE && !this.props.searchOptions.zoomToLayers) {
            zoom = false;
        }
        if(zoom) {
            let bbox = item.bbox ? item.bbox.slice(0) : [];
            let crs = item.crs;
            let x = !isEmpty(item.bbox) ? 0.5 * (item.bbox[0] + item.bbox[2]) : item.x;
            let y = !isEmpty(item.bbox) ? 0.5 * (item.bbox[1] + item.bbox[3]) : item.y;

            // find max zoom level greater than min scale
            let maxZoom = MapUtils.computeZoom(this.props.map.scales, this.props.searchOptions.minScale);

            if(resultType !== SearchResultType.PLACE && item.layer) {
                const maxbbox = (layer, bounds) => {
                    if(layer.sublayers) {
                        for(let sublayer in layer.sublayers) {
                            maxbbox(layer.sublayers[sublayer], bounds);
                        }
                    } else {
                        const newbounds = CoordinatesUtils.reprojectBbox(layer.bbox.bounds, layer.bbox.crs, this.props.map.projection);
                        if(bounds.length) {
                            bounds[0] = Math.min(newbounds[0], bounds[0]);
                            bounds[1] = Math.min(newbounds[1], bounds[1]);
                            bounds[2] = Math.max(newbounds[2], bounds[2]);
                            bounds[3] = Math.max(newbounds[3], bounds[3]);
                        } else {
                            bounds.push(...newbounds);
                        }
                    }
                }
                maxbbox(item.layer, bbox);
                crs = this.props.map.projection;
                x = 0.5 * (bbox[0] + bbox[2]);
                y = 0.5 * (bbox[3] + bbox[1]);
            }

            // zoom to result using max zoom level
            let newZoom;
            if(!isEmpty(bbox) && bbox[0] !== bbox[2] && bbox[1] !== bbox[3]) {
                let mapbbox = CoordinatesUtils.reprojectBbox(bbox, crs, this.props.map.projection)
                newZoom = Math.max(0, MapUtils.getZoomForExtent(mapbbox, this.props.map.resolutions, this.props.map.size, 0, maxZoom) - 1);
            } else {
                newZoom = MapUtils.computeZoom(this.props.map.scales, item.scale || 0);
                newZoom = Math.max(0, Math.min(newZoom, maxZoom));
            }
            if(startupSearch) {
                if(this.props.startupParams.s) {
                    newZoom = MapUtils.computeZoom(this.props.map.scales, this.props.startupParams.s);
                }
            }
            this.props.panToResult([x, y], newZoom, crs);
        }
        if(resultType === SearchResultType.PLACE) {
            this.props.removeLayer("searchselection");
            let text = item.label !== undefined ? item.label : item.text;
            text = text.replace(/<[^>]*>/g, '')
            if(item.provider && this.props.searchProviders[item.provider].getResultGeometry) {
                this.props.searchProviders[item.provider].getResultGeometry(item, (item, geometry, crs) => { this.showFeatureGeometry(item, geometry, crs, text)});
            } else {
                let layer = {
                    id: "searchselection",
                    role: LayerRole.SELECTION
                };
                let marker = this.createMarker([item.x, item.y], item.crs, text);
                this.props.addLayerFeatures(layer, [marker], true);
            }
            this.props.setCurrentSearchResult(item);
        } else if(resultType === SearchResultType.THEMELAYER) {
            this.props.addThemeSublayer(item.layer);
            // Show layer tree to notify user that something has happened
            this.props.setCurrentTask('LayerTree');
        } else if(resultType === SearchResultType.EXTERNALLAYER) {
            // Check if layer is already in the LayerTree
            let sublayers = LayerUtils.getSublayerNames(item.layer);
            let existing = this.props.layers.find(l => {
                return l.type === item.layer.type && l.url === item.layer.url && !isEmpty(LayerUtils.getSublayerNames(l).filter(v => sublayers.includes(v)))
            });
            if(existing) {
                let text = this.props.intl.formatMessage({id: "search.existinglayer"}) + ":" + item.layer.title;
                this.props.showNotification("existinglayer", text);
            }
            this.props.addLayer(item.layer);
            // Show layer tree to notify user that something has happened
            this.props.setCurrentTask('LayerTree');
        } else if(resultType === SearchResultType.THEME) {
            this.props.setCurrentTheme(item.theme, this.props.themes);
        }

        // if item specifies a layer, query user to make it visible if not visible
        let invisibleLayerQuery = null;
        if(resultType === SearchResultType.PLACE && item.layer) {
            let sublayerpath = null;
            let sublayer = null;
            let layer = this.props.layers.find(layer => {
                sublayerpath = [];
                sublayer = LayerUtils.searchSubLayer(layer, 'name', item.layer, sublayerpath);
                return sublayer !== null;
            });
            if(sublayer && !sublayer.visibility) {
                invisibleLayerQuery = {layer, sublayerpath};
            }
        }
        this.setState({invisibleLayerQuery});
    }
    showFeatureGeometry = (item, geometry, crs, text) => {
        if(item === this.props.currentResult && !isEmpty(geometry)) {
            let features = [];
            let highlightFeature = VectorLayerUtils.wktToGeoJSON(geometry, crs, this.props.map.projection);
            if(highlightFeature) {
                let center = this.getFeatureCenter(highlightFeature);
                features = [highlightFeature, this.createMarker(center, item.crs, text)];
            } else {
                features = [this.createMarker([item.x, item.y], item.crs, text)];
            }
            let layer = {
                id: "searchselection",
                role: LayerRole.SELECTION
            };
            this.props.addLayerFeatures(layer, features, true);
        }
    }
    createMarker = (center, crs, text) => {
        return {
            geometry: {type: 'Point', coordinates: center},
            styleName: 'marker',
            id: 'searchmarker',
            crs: crs,
            properties: { label: text }
        };
    }
    getFeatureCenter = (feature) => {
        let geojson = new ol.format.GeoJSON().readFeature(feature);
        let geometry = geojson.getGeometry();
        let type = geometry.getType();
        let center;
        switch (type) {
            case "Polygon":
                center = geometry.getInteriorPoint().getCoordinates();
                break;
            case "MultiPolygon":
                center = geometry.getInteriorPoints().getClosestPoint(ol.extent.getCenter(geometry.getExtent()));
                break;
            case "Point":
                center = geometry.getCoordinates();
                break;
            case "MultiPoint":
                center = geometry.getClosestPoint(ol.extent.getCenter(geometry.getExtent()));
                break;
            case "LineString":
                center = geometry.getCoordinateAt(0.5);
                break;
            case "MultiLineString":
                center = geometry.getClosestPoint(ol.extent.getCenter(geometry.getExtent()));
                break;
            case "Circle":
                center = geometry.getCenter();
                break;
        }
        return center;
    }
    addThemeLayers = (ev, theme) => {
        ev.stopPropagation();
        this.props.addLayer(ThemeUtils.createThemeLayer(theme, this.props.themes, LayerRole.USERLAYER));
        // Show layer tree to notify user that something has happened
        this.props.setCurrentTask('LayerTree');
    }
};

const SearchWithStyles = withStyles(styles)(Search);

export default {
    SearchPlugin: connect(
        createSelector([state => state, displayCrsSelector, searchProvidersSelector(SearchProviders, searchProviderFactory)], (state, displaycrs, searchProviders) => ({
            searchText: state.search.text,
            activeProviders: state.search.providers,
            pendingProviders: state.search.pendingProviders,
            startupSearch: state.search.startup,
            results: state.search.results,
            currentResult: state.search.currentResult,
            map: state.map,
            displaycrs: displaycrs,
            theme: state.theme.current,
            themes: state.theme.themes,
            layers: state.layers.flat || [],
            searchProviders: searchProviders,
            startupParams: state.localConfig.startupParams
        })
    ), {
        changeSearch: changeSearch,
        startSearch: startSearch,
        searchMore: searchMore,
        toggleNavigation: toggleNavigation,
        setCurrentSearchResult: setCurrentSearchResult,
        panToResult: zoomToPoint,
        addLayerFeatures: addLayerFeatures,
        removeLayer: removeLayer,
        addLayer: addLayer,
        addThemeSublayer: addThemeSublayer,
        changeLayerProperty: changeLayerProperty,
        setCurrentTask: setCurrentTask,
        setCurrentTheme: setCurrentTheme,
        showNotification: showNotification
    })(injectIntl(SearchWithStyles)),
    reducers: {
        display: require("../../reducers/display"),
        search: require("../../reducers/search")
    }
}
