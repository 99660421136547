/**
 * Copyright 2015, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import ol from '@openlayers';
import clsx from "clsx";
import { withStyles } from "goodmap-core";

class ScaleBar extends React.Component {
    static propTypes = {
        map: PropTypes.object,
        // See https://openlayers.org/en/latest/apidoc/ol.control.ScaleLine.html
        options: PropTypes.object
    }
    static defaultProps = {
        map: null,
        options: {}
    }
    static defaultOpt = {
        minWidth: 64,
        units: 'metric'
    }
    componentDidMount() {
        this.scalebar = new ol.control.ScaleLine({
            className: clsx("ol-scale-line", this.props.classes.root),
            ...ScaleBar.defaultOpt, 
            ...this.props.options
        });
        if (this.props.map) {
            this.props.map.addControl(this.scalebar);
        }
    }
    render() {
        return null;
    }
};

const styles = (theme) => ({
    root: {
        backgroundColor: "transparent",
        color: `${theme.palette.primary.light}`,
        borderColor: `${theme.palette.primary.light}`,
        position: "absolute",
        bottom: 0,
        left: 0
    }
})

export default withStyles(styles)(ScaleBar);
