/* eslint-disable */
/**
 * Copyright 2018, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useGoodmapContext} from 'goodmap-core';

const Authentication = ({task}) => {
    
    const config = useGoodmapContext();

    useEffect(() => {
        if (task === "Login")
            window.location.href = config.API_URL + "/auth/login?redirect_uri=" + encodeURIComponent(window.location.href);
        else if (task === "Logout")
            window.location.href = config.API_URL + "/auth/logout?redirect_uri=" + encodeURIComponent(window.location.href);
    }, [task])
    
    return null;
};

Authentication.propTypes = {
    task: PropTypes.string
}

export default {
    AuthenticationPlugin: connect(state => ({
        task: state.task ? state.task.id : null
    }), { })(Authentication),
    reducers: { }
};
