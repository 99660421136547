/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { array, object, func, any, bool } from "prop-types";
import { 
    TextField, 
    makeStyles, 
    Checkbox,
    Typography,
    FileInput,
    DisplayPanel,
    FormControlLabel,
    Button
} from "goodmap-core";
import { useSelector } from 'react-redux';

const HIDDEN_ATTRIBUTES = ["computed_area", "computed_length", "isText", "label"]

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    button: {
        padding: 8,
        height: 36,
        width: 36,
        marginTop: 4,
        marginLeft: 4
    }
}))

const FeatureAttributesFormDialog = ({
    onClose,
    open,
    onSubmit,
    storageUrl,
    labels
}) => {
    const classes = useStyles();
    const [properties, setProperties] = useState();
    const { selectedFeature, layer } = useSelector(state => state.drawing)
    const [errors, setErrors] = useState({});
    const [ showLabelAttribute, setShowLabelAttribute ] = useState(true);

    useEffect(() => {
        selectedFeature && setProperties(selectedFeature.properties || {})
    }, [selectedFeature])

    const validate = useCallback((props) => {
        const err = {};
        layer.properties.forEach((attribute) => {
            const value = props[attribute.name]
            if(attribute.required && !value){
                err[attribute.name] = "Toto pole je povinné"
            }  
            if((attribute.type === "text" || attribute.type === "longtext") && attribute.max && value && value.length > attribute.max){
                err[attribute.name] = `Maximálna dĺžka textu je ${attribute.max} znakov`;
            } 
            if((attribute.type === "text" || attribute.type === "longtext") && attribute.min && value && value.length < attribute.min){
                err[attribute.name] = `Minimálna dĺžka textu je ${attribute.min} znakov`;
            }  
            if(attribute.type === "number" && attribute.max && value > attribute.max){
                err[attribute.name] = `Maximálna hodnota je ${attribute.max}`;
            }
            if(attribute.type === "number" && attribute.min && value < attribute.min){
                err[attribute.name] = `Minimálna hodnota je ${attribute.max}`;
            }
            // if(attribute.type === "file" && value && !value.length){
            //     err[attribute.name] = `Je potrebné pridať aspon jednu prílohu`;
            // } 
            if(attribute.regex && value && !new RegExp(attribute.regex, attribute.regexOperator).test(value)){
                err[attribute.name] = attribute.regexMessage || "Hodnota nie je správna";
            }
        })
        setErrors(err);
        if(Object.keys(err).length > 0)
            return false;

        return true
    },[layer.properties, setErrors])

    const setFeatureAttributes = useCallback(() => {
        const isValid = validate(properties);
        if(isValid){
            const activeFeatureProps = selectedFeature.properties;
            const newProperties = {...activeFeatureProps, ...properties}
            selectedFeature.properties = newProperties
            onSubmit(selectedFeature);
        }
    }, [properties, selectedFeature, validate])
    
    const renderCalculatedData = () => {
        if(properties && properties.computed_area){
            return <Typography>{labels.area} {properties.computed_area}</Typography>
        }
        if(properties.computed_length){
            return <Typography>{labels.distance} {properties.computed_length}</Typography>
        }
        if(properties.computed_coors){
            return <Typography>{labels.coordinates} {properties.computed_coors}</Typography>
        }
        return null;
    }

    const renderAttributes = () => (
        <div className={classes.root}>
        {
            layer.properties.map(attribute => {
                if(HIDDEN_ATTRIBUTES.includes(attribute.name)){
                    return null;
                }
                if(attribute.type === "longtext"){
                        return (
                            <TextField
                                fullWidth
                                size="small"
                                multiline
                                error={errors[attribute.name]}
                                defaultValue={properties[attribute.name]}
                                InputLabelProps={{shrink: true}}
                                margin="normal"
                                variant="outlined"
                                value={properties[attribute.name]}
                                onChange={(e) => {
                                    setProperties({...properties, [attribute.name]: e.target.value})
                                }}
                                label={errors[attribute.name] || attribute.name}
                            />
                        )
                    }

                    if(attribute.type === "int"){
                        return (
                            <TextField
                                fullWidth
                                size="small"
                                error={errors[attribute.name]}
                                type="number"
                                defaultValue={properties[attribute.name]}
                                InputLabelProps={{shrink: true}}
                                step={1}
                                margin="normal"
                                variant="outlined"
                                value={properties[attribute.name]}
                                onChange={(e) => setProperties({...properties, [attribute.name]: e.target.value})}
                                label={errors[attribute.name] || attribute.name}
                            />
                        )
                    }

                    if(attribute.type === "file"){
                        return (
                            <FileInput 
                                url="/documents"
                                error={errors[attribute.name]}
                                label={errors[attribute.name] || attribute.name}
                                force={true}
                                storageUrl={storageUrl}
                                files={properties[attribute.name]}
                                onUploadSuccess={(files) => {
                                    setProperties({...properties, [attribute.name]: files})
                                }}
                            />
                        )
                    }

                    return (
                        <TextField
                            fullWidth
                            size="small"
                            type={attribute.type}
                            InputLabelProps={{shrink: true}}
                            margin="normal"
                            error={errors[attribute.name]}
                            value={properties[attribute.name]}
                            defaultValue={properties[attribute.name]}
                            variant="outlined"
                            onChange={(e) => setProperties({...properties, [attribute.name]: e.target.value})}
                            label={errors[attribute.name] || attribute.name}
                        />
                    )}
                )
            }
            {
                renderCalculatedData()
            }
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showLabelAttribute}
                        onChange={(e) =>  setShowLabelAttribute(!showLabelAttribute)}
                        name="label"
                        color="primary"
                    />
                }
                label={labels.addLabel}
            />
            {showLabelAttribute &&
                <TextField
                    fullWidth
                    size="small"
                    type="text"
                    InputLabelProps={{shrink: true}}
                    margin="normal"
                    defaultValue={properties["label"]}
                    variant="outlined"
                    onChange={(e) => setProperties({...properties, "label": e.target.value})}
                    label={"Popisok"}
                />
            }
            <div>
                <Button onClick={setFeatureAttributes} variant="outlined">{labels.save}</Button>
            </div>
        </div>
    )

    return open ?
            <DisplayPanel 
                position="center"
                fade={true}
                maxWidth={500}
                maxHeight={500}
                onClose={onClose}
                title={labels.title}>
                    {renderAttributes()}
            </DisplayPanel> : null
};

FeatureAttributesFormDialog.defaultProps = {
    onClose: () => {},
    open: false,
    labels: {
        area: "Výmera:",
        distance: "Dĺžka:",
        coordinates: "Súradnice:",
        addLabel: "Pridať popisok",
        save: "Uložiť",
        title: "Atribúty"
    }
}

FeatureAttributesFormDialog.propTypes = {
    onSubmit: func,
    onClose: func,
    onChangeProps: func,
    open: bool,
    labels: object
}

export default FeatureAttributesFormDialog;
