/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Message from '../../components/I18N/Message';
import Icon from '../../components/Icon';
import MapUtils from '../../utils/MapUtils';
import {setActiveLayerInfo} from '../../actions/layerinfo';
import MiscUtils from '../../utils/MiscUtils';
import { Dialog } from 'goodmap-core';
import { useIntl } from 'react-intl';
import '../../components/style/LayerInfoWindow.css'

const LayerInfoWindow = ({
    layer,
    sublayer,
    setActiveLayerInfo,
    windowSize,
    map,
    bboxDependentLegend
}) => {
    
    const { formatMessage } = useIntl()
    const renderLink = (text, url) => {
        return url ? (<a href={url} target="_blank">{text}</a>) : text ? text : null;
    }

    const renderScale = (scale) => {
        if(scale === 0) {
            return "0";
        } else if(scale >= 1) {
            return "1:" + Math.round(scale);
        } else {
            return Math.round(1./scale) + ":1";
        }
    }
    const onClose = () => {
        setActiveLayerInfo(null, null);
    }

    const renderRow = (title, content, html=false) => {
        if(content) {
            return (
                <tr>
                    <td><Message msgId={title} />:</td>
                    {html ? (
                        <td dangerouslySetInnerHTML={{__html: MiscUtils.addLinkAnchors(content)}}></td>
                    ) : (<td>{content}</td>)}
                </tr>
            );
        }
        return null;
    }
    
    if(!layer || !sublayer) {
        return null;
    }
    let legend = null;
    if(layer.legendUrl) {
        let requestParams = "SERVICE=WMS" +
                            "&REQUEST=GetLegendGraphic" +
                            "&VERSION=" + (layer.version || "1.3.0") +
                            "&FORMAT=image/png" +
                            "&LAYER=" + sublayer.name +
                            "&CRS=" + map.projection +
                            "&SCALE=" + Math.round(MapUtils.computeForZoom(map.scales, map.zoom)) +
                            "&WIDTH=" + map.size.width +
                            "&HEIGHT=" + map.size.height;
        if(bboxDependentLegend) {
            requestParams += "&BBOX=" + map.bbox.bounds.join(",");
        }
        let request = layer.legendUrl + (layer.legendUrl.indexOf('?') === -1 ? '?' : '&') + requestParams;
        legend = (<img className="layer-info-window-legend" src={request} />);
    } else if(layer.color) {
        legend = (<span className="layer-info-window-coloricon" style={{backgroundColor: layer.color}} />);
    }
    return (
        <Dialog open={true} title={formatMessage({id: "layerinfo.title"})} icon={<Icon fontSize={20} icon="info-sign"/>} onClose={onClose}
            initialWidth={windowSize.width} initialHeight={windowSize.height}>
            <div role="body" className="layer-info-window-body">
                <h4 className="layer-info-window-title">{sublayer.title}</h4>
                <div className="layer-info-window-frame">
                    <table className="layer-info-window-table">
                        <tbody>
                        {renderRow("layerinfo.abstract", sublayer.abstract, true)}
                        {sublayer.attribution ? renderRow("layerinfo.attribution", renderLink(sublayer.attribution.Title, sublayer.attribution.OnlineResource)) : null}
                        {renderRow("layerinfo.keywords", sublayer.keywords)}
                        {renderRow("layerinfo.dataUrl", renderLink(sublayer.dataUrl, sublayer.dataUrl))}
                        {renderRow("layerinfo.metadataUrl", renderLink(sublayer.metadataUrl, sublayer.metadataUrl))}
                        {sublayer.minScale !== undefined ? renderRow("layerinfo.minscale", renderScale(sublayer.minScale)) : null}
                        {sublayer.maxScale !== undefined ? renderRow("layerinfo.maxscale", renderScale(sublayer.maxScale)) : null}
                        {renderRow("layerinfo.legend", legend)}
                        </tbody>
                    </table>
                </div>
            </div>
        </Dialog>
    );
};

LayerInfoWindow.propTypes = {
    layer: PropTypes.object,
    sublayer: PropTypes.object,
    setActiveLayerInfo: PropTypes.func,
    windowSize: PropTypes.object,
    map: PropTypes.object,
    bboxDependentLegend: PropTypes.bool
}

LayerInfoWindow.defaultProps = {

}

const selector = state => ({
    map: state.map,
    layer: state.layerinfo.layer || null,
    sublayer: state.layerinfo.sublayer || null
});

export default connect(selector, {
    setActiveLayerInfo: setActiveLayerInfo
})(LayerInfoWindow);
