import React, { useCallback } from "react"
import {Button, makeStyles, useApi, useGoodmapContext} from "goodmap-core"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    }
}))

const ExportButton = ({
    state
}) => {

    const [, fetch] = useApi({}, {manual: true})
    const config = useGoodmapContext();
    const classes = useStyles()

    const exportData = useCallback(async () => {

        const result = await fetch({
            url: `/cadastre/token`,
            method: "post",
            data: {
                geometry: { type: "GeometryCollection", geometries: state.intersectionFeatures },
                geomType: state.selectedGeometryType,
                date: state.selectedDate
            }
        });
        
        if(result.data.ticket){
            window.location.href = `${config.API_URL}/cadastre/analyseArea/export?ticket=${result.data.ticket}`
        }
    }, [state, config.API_URL, fetch])

    return (
        <Button 
            className={classes.root}
            variant="contained"
            color="primary" 
            onClick={exportData}
        >
                Export do XLSX
        </Button>
    )

}

export default ExportButton