/**
* Copyright 2017, Sourcepole AG.
* All rights reserved.
*
* This source code is licensed under the BSD-style license found in the
* LICENSE file in the root directory of this source tree.
*/

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classnames from 'classnames'
import Icon from '../Icon'
import Message from '../../components/I18N/Message'
import {Button, withStyles} from "goodmap-core"
import './style/ButtonBar.css'

const styles = (theme) => ({
    root: {

    },
    button: {
        borderRadius: 0,
        // paddingLeft: 4,
        // paddingRight: 4,
        minWidth: "auto",
    },
    buttonPressed: {
        background: theme.palette.primary.dark,
        "&:hover": {
            background: theme.palette.primary.dark,
        }
    }
})

class ButtonBar extends React.Component {
    static propTypes = {
        buttons: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
            tooltip: PropTypes.string,
            icon: PropTypes.string,
            data: PropTypes.object,
            extraClasses: PropTypes.string,
            type: PropTypes.string,
            disabled: PropTypes.bool
        })),
        active: PropTypes.string,
        onClick: PropTypes.func,
        mobile: PropTypes.bool,
        disabled: PropTypes.bool
    }
    static defaultProps = {
        disabled: false
    }
    render() {
        return (
            <div className={"ButtonBar" + (this.props.disabled ? " buttonbar-disabled" : "")}>
                {this.props.buttons.map(button => {
                    let classes = classnames({
                        [this.props.classes.button]: true,
                        [this.props.classes.buttonPressed]: this.props.active === button.key
                    });
                    classes += button.extraClasses ? ' ' + button.extraClasses : '';
                    return (
                        <span className="buttonbar-button-container"  key={button.key}>
                            <Button color="primary" variant="contained" type={button.type || "button"} disabled={button.disabled} className={classes} onClick={button.type !== "submit" ? (ev) => this.props.onClick(button.key, button.data) : null}>
                                {button.icon ? (<Icon icon={button.icon} />) : null}
                                {button.label && (!this.props.mobile || !button.icon) ? (<Message msgId={button.label} />) : null}
                            </Button>
                            {button.tooltip ? (<span className="buttonbar-button-tooltip">
                                <Message msgId={button.tooltip} />
                            </span>) : null}
                        </span>
                    );
                })}
            </div>
        )
    }
};

const selector = (state) => ({
    mobile: state.browser ? state.browser.mobile : false,
});

const ButtonBarWithStyles = withStyles(styles)(ButtonBar)

export default connect(selector, {})(ButtonBarWithStyles);
