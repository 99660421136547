/* eslint-disable */
/**
 * Copyright 2016, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

// Needed for IE11 to avoid 'Promise not defined' error in axios
// import "babel-polyfill";
import React, { useState, useEffect, useMemo} from "react"
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import assign from 'object-assign';
import PluginsContainer from '../components/PluginsContainer';
import {themesLoaded,setCurrentTheme} from '../actions/theme'
import ConfigUtils from '../utils/ConfigUtils'
import MapUtils from '../utils/MapUtils'
import PluginsUtils from '../utils/PluginsUtils'
import {UrlParams, resolvePermaLink} from '../utils/PermaLinkUtils'
import ThemeUtils from '../utils/ThemeUtils'
import {setCurrentTask} from '../actions/task';
import Provider from "./Provider";
import LocaleUtils from "../utils/LocaleUtils";
import CoordinatesUtils from "../utils/CoordinatesUtils";
import StandardStore from './store';
import Proj4js from 'proj4';
import { register } from 'ol/proj/proj4';
import {loadLocale} from '../actions/locale'
import {changeBrowserProperties} from '../actions/browser'
import {localConfigLoaded, setStartupParameters} from '../actions/localConfig'

const AppInitComponent = ({
    appConfig,
    initialParams,
    mapSize,
    themesLoaded,
    setCurrentTheme
}) => {
   
    const [ initialized, setInitialized ] = useState(false)
    useEffect(() => {
        if(mapSize && !initialized) {
            init();
        }
    }, [initialized])

    useEffect(() => {
        if(mapSize && !initialized) {
            init();
        }
    }, [mapSize, initialized])
   
    const init = () => {
        setInitialized(true);

        let themes = appConfig.theme.themes || {};
        themesLoaded(themes);

        // Resolve permalink and restore settings
        resolvePermaLink(initialParams, (params, state) => {
            let theme = ThemeUtils.getThemeById(themes,  params.t);
            if(!theme) {
                if(ConfigUtils.getConfigProp("dontLoadDefaultTheme")) {
                    return;
                }
                theme = ThemeUtils.getThemeById(themes, themes.defaultTheme);
                params = {};
            }
            let layerParams = params.l !== undefined ? params.l.split(",").filter(entry => entry) : null;
            if(layerParams && ConfigUtils.getConfigProp("urlReverseLayerOrder")) {
                layerParams.reverse();
            }
            let visibleBgLayer = params.bl || params.bl === '' ? params.bl : null;
            let initialView = null;
            if(params.c && params.s !== undefined) {
                let coords = params.c.split(/[;,]/g).map(x => parseFloat(x));
                let scales = theme.scales || themes.defaultScales;
                let zoom = MapUtils.computeZoom(scales, params.s);
                if(coords.length === 2) {
                    initialView = {
                        center: coords,
                        zoom: zoom,
                        crs: params.crs || theme.mapCrs};
                }
            } else if(params.e) {
                let bounds = params.e.split(/[;,]/g).map(x => parseFloat(x));
                if(bounds.length === 4) {
                    initialView = {
                        bounds: bounds,
                        crs: params.crs || theme.mapCrs
                    };
                }
            }

            UrlParams.clear();

            try {
                setCurrentTheme(
                    theme, 
                    themes, 
                    false, 
                    initialView, 
                    layerParams, 
                    visibleBgLayer, 
                    state.layers, 
                    appConfig.general.themeLayerRestorer, 
                    appConfig.general.externalLayerRestorer
                );
            } catch(e) {
                console.log(e.stack);
            }
        });
    }

    return null;
}

AppInitComponent.propTypes = {
    appConfig: PropTypes.object,
    initialParams: PropTypes.object,
    mapSize: PropTypes.object,
    themesLoaded: PropTypes.func,
    setCurrentTheme: PropTypes.func,
    addLayer: PropTypes.func
}

const AppInit = connect(state => {
    return ({
        mapSize: state.map.size,
        layers: state.layers.flat
    })}, {
    themesLoaded,
    setCurrentTask,
    setCurrentTheme
})(AppInitComponent);


const GoodmapApp = ({
    appConfig
}) => {
   
    const initialParams = useMemo(() => UrlParams.getParams())

    const init = () => {
        const browserProperties = ConfigUtils.getBrowserProperties();
        const config = appConfig.config;
        const supportedLocales = appConfig.general.supportedLocales;

        store.dispatch(changeBrowserProperties(browserProperties));

        LocaleUtils.setSupportedLocales(supportedLocales);
        ConfigUtils.loadConfiguration(config)
        store.dispatch(localConfigLoaded(config));
        store.dispatch(loadLocale(null, LocaleUtils.getUserLocale()));
        
        for(let proj of config.projections || []) {
            if(Proj4js.defs(proj.code) === undefined) {
                Proj4js.defs(proj.code, proj.proj);
            }
            CoordinatesUtils.setCrsLabels({[proj.code]: proj.label});
        }
        register(Proj4js);
    }

    const store = useMemo(() => StandardStore(
        appConfig.general.initialState || {}, 
        appConfig.general.pluginsDef.plugins, 
         {onPersist: init }, 
        appConfig.general.actionLogger
    ), [])

    useEffect(() => {
        store.dispatch(setStartupParameters(initialParams));
        init();
    }, [])

    const plugins = assign(PluginsUtils.getPlugins(appConfig.general.pluginsDef.plugins));
    const pluginsConfig = appConfig.general.pluginsDef.cfg;

    return (
        <Provider store={store} initialParams={initialParams}>
            <AppInit initialParams={initialParams} appConfig={appConfig}/>
            <PluginsContainer 
                plugins={plugins} 
                pluginsAppConfig={pluginsConfig || {}} 
            />
        </Provider>
    );
}

GoodmapApp.appConfig = {
    appConfig: PropTypes.object
}

export default GoodmapApp
