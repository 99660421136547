/**
* Copyright 2016, GeoSolutions Sas.
* All rights reserved.
*
* This source code is licensed under the BSD-style license found in the
* LICENSE file in the root directory of this source tree.
*/

import {connect} from 'react-redux'
import {changeSelectionState} from '../../actions/selection'
import SelectionSupport from '../../components/map/openlayers/SelectionSupport'

export default connect((state) => ({
    selection: state.selection || {}
}), {
    changeSelectionState
})(SelectionSupport);
