import { useEffect } from "react";
import { useMap } from "../hooks/useMap";
import Vector from "ol/layer/Vector"
import VectorSource from 'ol/source/Vector';

const VectorLayer = ({
    source, 
    style, 
    features
}) => {

    const map = useMap();

    useEffect(() => {
        if(!features || !features.length)
            return
        source.addFeatures(features)
      }, [features, source])

    useEffect(() => {
        const vector = new Vector({
            source,
            style,
            visible: true,
            zIndex: 9999
        });

        map.addLayer(vector);

        return () => {
            map.removeLayer(vector)
        }
    }, [ map, style, source])

    return null
}

VectorLayer.defaultProps = {
    handleSelect: () => {},
    source: new VectorSource()
}

export default VectorLayer