import { Divider, Grid, IconButton, makeStyles, TextField, Typography } from "goodmap-core"
import React, { useCallback } from "react"
import ColorButton from "../../components/widgets/ColorButton"
import SymbolButton from "../../components/widgets/SymbolButton"
import { useDispatch, useSelector } from "react-redux"
import { updateDrawingStyle } from "../../actions/drawing"
import { useIntl } from "react-intl"
import { MdClose } from "react-icons/md"

const RedliningControls = ({ 
    open,
    handleShowControls
}) => {

    const { style, geomType, selectedFeature } = useSelector(state => ({
        style: state.drawing.style,
        selectedFeature: state.drawing.selectedFeature,
        geomType: state.drawing.geomType
    }))

    const dispatch = useDispatch()

    const { formatMessage } = useIntl()

    const classes = useStyles()

    const updateStyle = useCallback((diff) => {
        dispatch(updateDrawingStyle(diff))
    }, [dispatch])

    const getSizeMessage = (geomType) => {

        if (geomType === "LineString") {
            return formatMessage({id: "redlining.width"})
        } 
        
        if (geomType === "Polygon" || geomType === "Point") {
            return formatMessage({id: "redlining.border"})
        }
    }

    if(!open) return null

    return (
        <div className={classes.root}>
            <div className={classes.heading} xs={12} item>
                <Typography>{formatMessage({id: "redlining.symbology"})}</Typography>
                <IconButton onClick={handleShowControls} size="medium">
                    <MdClose/>
                </IconButton>
            </div>
            <Divider className={classes.divider} />
            {!selectedFeature ? <Typography>{formatMessage({id: "redlining.nogeometry"})}</Typography> :
            <Grid container spacing={1}>                
            {geomType === 'Marker' ? (
                <Grid item xs={6} className={classes.styleControl}>
                    <Typography>{formatMessage({id: "redlining.symbol"})}:&nbsp;</Typography>
                    <SymbolButton 
                        icon={style.icon.src} 
                        onIconChanged={(icon) => updateStyle({ icon: { src: icon}})} 
                    />
                </Grid>
            ) : null}
            {geomType === 'Marker' ? null : [
                    <Grid xs={6} item className={classes.styleControl}>                                
                        <Typography>{formatMessage({id: "redlining.outline"})}:&nbsp;</Typography>                          
                        <ColorButton 
                            color={style.stroke.color} 
                            onColorChanged={(color) => updateStyle({ stroke: { color } })} 
                        />
                    </Grid>,
                    <Grid xs={6} item className={classes.styleControl}>
                        {geomType === 'LineString' ? null : (
                            <>
                                <Typography>{formatMessage({id: "redlining.fill"})}:&nbsp;</Typography>  
                                <ColorButton 
                                    color={style.fill.color}
                                    onColorChanged={(color) => updateStyle({ fill: { color } })} 
                                />
                            </>
                        )}
                    </Grid>
                ]
            }
            {(geomType && geomType !== "Marker") &&
                <Grid xs={6} item className={classes.styleControl}>
                    <Typography>{getSizeMessage(geomType)}:&nbsp;</Typography>
                    <TextField 
                        classes={{root: classes.numericInput}}
                        variant="outlined"
                        type="number"
                        size="small"
                        min={1} 
                        max={99} 
                        precision={0} 
                        step={1}
                        value={style.stroke.width} 
                        onChange={(e) => updateStyle({ 
                            stroke: { width: e.target.value }, 
                        })} 
                    />
                </Grid>
            }
            {(geomType === "Point" || geomType === "Marker") &&
                <Grid xs={6} item className={classes.styleControl}>
                    <Typography>{formatMessage({id: "redlining.size"})}:&nbsp;</Typography>
                    <TextField 
                        classes={{root: classes.numericInput}}
                        variant="outlined"
                        type="number"
                        size="small"
                        min={1} 
                        max={99} 
                        precision={0} 
                        step={1}
                        value={style.point.radius} 
                        onChange={(e) => updateStyle({ 
                            point: { radius: e.target.value },
                            icon:  { scale: e.target.value } 
                        })} 
                    />
                </Grid>
            }
                <Grid xs={12} item>
                    <Divider className={classes.divider} />
                    <Typography>{formatMessage({id: "redlining.textsettings"})}</Typography>
                </Grid>
                <Grid xs={6} item className={classes.styleControl}>
                    <Typography>{formatMessage({id: "redlining.textbordersize"})}:&nbsp;</Typography>
                    <TextField 
                        classes={{root: classes.numericInput}}
                        variant="outlined"
                        type="number"
                        size="small"
                        min={1} 
                        max={99} 
                        precision={0} 
                        step={1}
                        value={style.text.borderSize} 
                        onChange={(e) => updateStyle({ 
                            text: { borderSize: parseInt(e.target.value) },
                        })} 
                    />
                </Grid>
                <Grid xs={6} item className={classes.styleControl}>
                    <Typography>{formatMessage({id: "redlining.textbordercolor"})}:&nbsp;</Typography>
                    <ColorButton 
                        color={style.text.stroke}
                        onColorChanged={(color) => updateStyle({ text: { stroke: color}})} 
                    />
                </Grid>
                <Grid xs={6} item className={classes.styleControl}>
                    <Typography>{formatMessage({id: "redlining.textsize"})}:&nbsp;</Typography>
                    <TextField 
                        classes={{root: classes.numericInput}}
                        variant="outlined"
                        type="number"
                        size="small"
                        min={1} 
                        max={99} 
                        precision={0} 
                        step={1}
                        value={style.text.size} 
                        onChange={(e) => updateStyle({ 
                            text: { size: parseInt(e.target.value) },
                        })} 
                    />
                </Grid>
                <Grid xs={6} item className={classes.styleControl}>
                    <Typography>{formatMessage({id: "redlining.textcolor"})}:&nbsp;</Typography>
                    <ColorButton 
                        color={style.text.fill}
                        onColorChanged={(color) => updateStyle({ text: { fill: color }})} 
                    />
                </Grid>
            </Grid>
            }
        </div>
    );
}

RedliningControls.defaultProps = {

}

RedliningControls.propTypes = {
    
}

const useStyles = makeStyles((theme) => ({
    root: {        
        alignItems: "center",             
        position: "absolute",
        top: 70,
        right: theme.spacing(7),
        maxWidth: 400,
        padding: theme.spacing(1),
        width: "100%",
        borderRadius: 8,
        background: theme.palette.grey[200],
        margin: "auto",
        boxShadow: theme.shadows[1]
    },
    heading: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between"
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    styleControl: {
        alignItems: "center",
        display: "flex",
    },
    numericInput: {
        maxWidth: 100
    }
}))

export default RedliningControls