/* eslint-disable */
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Window from '../../components/Window';
import TaskBar from '../../components/TaskBar';
import Result from './Result';
import CadastreAnalyseForm from './Form';
import ExportButton from './ExportButton';
import { useApi } from 'goodmap-core/dist';
import { addLayerFeatures, LayerRole } from '../../actions/layers';
import VectorLayerUtils from '../../utils/VectorLayerUtils';
import DrawInteraction from '../../ol/interaction/DrawInteraction'
import VectorLayer from '../../ol/layers/VectorLayer';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON'
import uuid from 'uuid'
import { getDefaultStyle } from '../../ol/layers/styles';

const createGmFeature = ({feature, properties}) => {
    
    const format = new GeoJSON();
    const f = format.writeFeatureObject(feature);
    
    if(properties){
        f.properties = properties;
    }
   
    return f;
}



const CadastreAnalyse = () => {
    
    const dispatch = useDispatch();
    const { layers = { flat: []}, task} = useSelector(state => state);
    const [, fetchData] = useApi({}, {manual: true})
    const [ data, setData ] = useState();
    const [state, setState] = useState({
        selectedGeometryType: 3,
        availableDates: [],
        selectedDate: "",
        geometryType: "Polygon",
        selectedLayer: "drawing",
        intersectionFeatures: []
    })

    const vectorSource =  useMemo(() => new VectorSource(), []);

    const handleChange = useCallback((diff) => {
        setState((state) => ({...state, ...diff}))
    }, [])

    const [ pagination, setPagination ] = useState({
        participant: 0,
        folio: 0,
        construction: 0,
        legal: 0
    })

    useEffect(() => {
        if(state.selectedLayer && state.selectedLayer !== "drawing" ){
            const layer = layers.flat.find(l => l.role === LayerRole.USERLAYER && l.id === state.selectedLayer)
            if(!layer) return;
            const geoms = (layer.features || []).map(f => VectorLayerUtils.reprojectGeometry(f.geometry, f.crs || 'EPSG:3857', 'EPSG:3857'));
            handleChange({
                intersectionFeatures: geoms
            })
        }
    }, [handleChange, state.selectedLayer, layers])

    const layerParcelsC = useMemo(() => ({ id: "parcelsC", role: LayerRole.SELECTION, styleName: 'dashedYellow' }), [])
    const layerParcelsE =  useMemo(() => ({ id: "parcelsE", role: LayerRole.SELECTION, styleName: 'dashedGreen' }), [])

    const fetchCadastralAnalyse = useCallback(async () => {
        const result  = await fetchData({
            url: `/cadastre/analyseArea`,  
            method: "post",
            data: {
                geometry: { type: "GeometryCollection", geometries: state.intersectionFeatures },
                paging: pagination,
                geomType: state.selectedGeometryType,
                date: state.selectedDate
            }
        });

        const parcelsCFeatures = result.data.parcels.items.filter(x => x.reg === "c").map(parcel => ({
            geometry: parcel.geometry,
            crs: result.crs
        }))

        const parcelsEFeatures = result.data.parcels.items.filter(x => x.reg === "e").map(parcel => ({
            geometry: parcel.geometry,
            crs: result.crs
        }))

        dispatch(addLayerFeatures(layerParcelsE, parcelsEFeatures, true));
        dispatch(addLayerFeatures(layerParcelsC, parcelsCFeatures, true));
        setData(result.data)
    }, [pagination, state.intersectionFeatures, state.selectedGeometryType, state.selectedDate])

    const analyse = useCallback(() => {
        if((state.intersectionFeatures.length) && task.id === "CadastreAnalyse")
            fetchCadastralAnalyse();
    }, [fetchCadastralAnalyse, task.id, state.intersectionFeatures.length])

    const onHide = useCallback(() => {
        vectorSource.clear()
    }, [vectorSource])

    const onShow = () => {

    }

    const handleDrawStart = useCallback((e) => {
        vectorSource.clear()
        const feature = e.feature;
        feature.setId(uuid.v4());
    }, [vectorSource])

    const handleDrawEnd = useCallback((e) => {
        vectorSource.clear()
        const feature = e.feature;
        const style = getDefaultStyle({stroke: {color: "red", width: 2}, fill: {color: [255,0,0,0.1]}});
        feature.setStyle(style)
        vectorSource.addFeature(feature)
        const f = createGmFeature({feature})
        handleChange({ intersectionFeatures: [f.geometry] })
    }, [handleChange, vectorSource])

    return (
        <>
            <TaskBar 
                disableBackground={true} 
                task="CadastreAnalyse" 
                onShow={onShow} 
                onHide={onHide}
            >
                <CadastreAnalyseForm 
                    availableDates={state.availableDates}
                    task={task}
                    handleChange={handleChange}
                    selectedLayer={state.selectedLayer}
                    layerParcelsC={layerParcelsC}
                    layerParcelsE={layerParcelsE}
                    selectedGeometryType={state.selectedGeometryType}
                    layers={layers}
                    state={state}
                    analyse={analyse}
                />
            </TaskBar>
            {(state.selectedLayer === "drawing" && task.id === "CadastreAnalyse") && (
                <>
                    <VectorLayer source={vectorSource} />
                    <DrawInteraction 
                        geometryType={state.geometryType}
                        drawEnd={handleDrawEnd}
                        drawStart={handleDrawStart}
                    />
                    {/* <ModifyInteraction 
                        features={vectorSource.getFeatures()}
                        geometryType="Polygon"
                        modifyStart={() => {}}
                        modifyEnd={() => {}}
                    /> */}
                </>
            )}
            {data &&
                <Window
                    padding={0}
                    modal={false} 
                    variant="right" 
                    id="CadastreAnalyse" 
                    width="20em" 
                    title="appmenu.items.Analyse"
                >
                {() => ({ 
                    body: (
                        <Result 
                            exportButton={
                                <ExportButton 
                                    state={{
                                        intersectionFeatures: state.intersectionFeatures, 
                                        selectedGeometryType: state.selectedGeometryType, 
                                        selectedDate: state.selectedDate
                                    }} 
                                />
                            }
                            data={data}
                            setPagination={setPagination}
                            pagination={pagination}
                        />
                    ) 
                }) }
                </Window>
            }
        </>
    );
};

CadastreAnalyse.defaultProps = {

}

export default { CadastreAnalysePlugin: CadastreAnalyse }