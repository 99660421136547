/* eslint-disable */
/**
* Copyright 2019, Sourcepole AG.
* All rights reserved.
*
* This source code is licensed under the BSD-style license found in the
* LICENSE file in the root directory of this source tree.
*/

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import assign from 'object-assign'
// import buffer from '@turf/buffer').default;
import uuid from 'uuid'
import NumericInput from 'react-numeric-input'
import {LayerRole,addLayer,addLayerFeatures} from '../../actions/layers'
import Message from '../../components/I18N/Message'
import VectorLayerPicker from '../../components/widgets/VectorLayerPicker'
import LocaleUtils from '../../utils/LocaleUtils'
import VectorLayerUtils from '../../utils/VectorLayerUtils'


class BufferSupport extends React.Component {
    static propTypes = {
        projection: PropTypes.string,
        layers: PropTypes.array,
        redlining: PropTypes.object,
        addLayer: PropTypes.func,
        addLayerFeatures: PropTypes.func
    }
    state = {
        bufferDistance: 0,
        bufferLayer: null
    }
    static contextTypes = {
        messages: PropTypes.object
    }
    componentWillMount() {
        this.setState({bufferLayer: {
            id: "buffer",
            title: LocaleUtils.getMessageById(this.context.messages, "redlining.bufferlayername"),
            role: LayerRole.USERLAYER
        }});
    }
    componentWillReceiveProps(newProps) {
        if(this.state.bufferLayer && !newProps.layers.find(layer => layer.id === this.state.bufferLayer.id)) {
            this.setState({bufferLayer: {
                id: "buffer",
                title: LocaleUtils.getMessageById(this.context.messages, "redlining.bufferlayername"),
                role: LayerRole.USERLAYER
            }});
        }
    }
    render() {
        if(!this.props.redlining.selectedFeature) {
            return (
                <div className="redlining-message">
                    <Message msgId="redlining.bufferselectfeature" />
                </div>
            );
        }
        let enabled = this.state.bufferDistance != 0;
        let layers = this.props.layers.filter(layer => layer.type === "vector" && layer.role === LayerRole.USERLAYER);
        // Ensure list contains current  target layer
        if(!layers.find(layer => layer.id === this.state.bufferLayer.id)) {
            layers = [this.state.bufferLayer, ...layers];
        }
        return (
            <div className="redlining-controlsbar">
                <span>
                    <span><Message msgId="redlining.bufferdistance" /> [m]:&nbsp;</span>
                        <NumericInput mobile strict
                            min={-99999} max={99999} precision={0} step={1}
                            value={this.state.bufferDistance} onChange={(nr) => this.setState({bufferDistance: nr})}/>
                </span>
                <span>
                    <span><Message msgId="redlining.bufferlayer" />:&nbsp;</span>
                    <VectorLayerPicker
                        value={this.state.bufferLayer.id} layers={layers}
                        onChange={layer => this.setState({bufferLayer: layer})}
                        addLayer={this.props.addLayer} />
                </span>
                <span>
                    <button onClick={this.computeBuffer} className="button" disabled={!enabled}>
                        <Message msgId="redlining.buffercompute" />
                    </button>
                </span>
            </div>
        );
    }
    computeBuffer = () => {
        let feature = this.props.redlining.selectedFeature;
        if(!feature || !feature.geometry || !this.state.bufferLayer) {
            return;
        }
        let wgsGeometry = VectorLayerUtils.reprojectGeometry(feature.geometry, this.props.projection, "EPSG:4326");
        let wgsFeature = assign({}, feature, {geometry: wgsGeometry});
        // let output = buffer(wgsFeature, this.state.bufferDistance, {units: 'meters'});
        let output = () => {}
        if(output && output.geometry) {
            output.geometry = VectorLayerUtils.reprojectGeometry(output.geometry, "EPSG:4326", this.props.projection);
            output.id = uuid.v4();
            output.styleName = 'default';
            output.styleOptions = {
                stroke: {
                    color: [0, 0, 255, 1],
                    width: 2,
                    dash: [4]
                },
                fill: {
                    color: [0, 0, 255, 0.33]
                },
                point: {
                    radius: 10
                },
                text: {
                    fill: "black",
                    stroke: "white"
                }
                // fillColor: [0, 0, 255, 0.5],
                // strokeColor: [0, 0, 255, 1]
            };
            this.props.addLayerFeatures(this.state.bufferLayer, [output]);
        }
    }
};

// cfg: {
//     key: "Buffer",
//     tooltip: "redlining.buffer",
//     icon: "buffer",
//     data: {action: "Buffer", geomType: null}
// },
export default connect((state) => ({
    projection: state.map.projection,
    layers: state.layers.flat,
    redlining: state.redlining
}), {
    addLayerFeatures: addLayerFeatures,
    addLayer: addLayer
})(BufferSupport)