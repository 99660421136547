import React from "react"
import {makeStyles} from "goodmap-core"
import Message from "../../components/I18N/Message";

const BottomLinks = ({
    termsUrl,
    viewertitleUrl
}) => {

    const classes = useStyles()

    return (
        <>
            {(viewertitleUrl || termsUrl) &&
                <div className={classes.links}>
                     {
                        viewertitleUrl && (
                            <a href={viewertitleUrl} rel="noreferrer" target="_blank">
                                <Message className="viewertitle_label" msgId="bottombar.viewertitle_label" />
                            </a>
                        )
                    }
                    {(viewertitleUrl && termsUrl) ? (<span dangerouslySetInnerHTML={{__html: "&nbsp;|&nbsp;"}}></span>) : null}
                    { 
                        termsUrl && (
                            <a href={termsUrl}  rel="noreferrer" target="_blank">
                                <Message className="terms_label" msgId="bottombar.terms_label" />
                            </a>
                        )
                    }
                </div>
            }
        </>
    );
}

BottomLinks.defaultProps = {

}

BottomLinks.propTypes = {

}

const useStyles = makeStyles((theme) => ({
    links: {
        display: "flex",
        color: theme.palette.common.white,
        justifyContent: "flex-end",
        alignItems: "center",
        "& p": {
            fontSize: "x-small"
        },
        "& a": {
            color: theme.palette.primary.main,
        }
    },
}))

export default BottomLinks