/* eslint-disable */
import React from "react"
import { withStyles } from "goodmap-core"

const LegendTooltip = ({
   legendTooltip,
   onLoad,
   onTouchStart,
   classes
}) => {

    return (
        <img
            className={classes.root} 
            style={{
                left: legendTooltip.x,
                top: legendTooltip.y,
                maxWidth: (window.innerWidth - legendTooltip.x - 2),
                maxHeight: (window.innerHeight - legendTooltip.y - 2),
                visibility: 'hidden'
            }} 
            src={legendTooltip.img} 
            onTouchStart={onTouchStart} 
            onLoad={onLoad}>
        </img>
    )
}

const styles = (theme) => ({
    root: {
        position: "absolute",
        zIndex: 20,
        boxShadow: "0px 2px 5px rgba(136, 136, 136, 0.5)",
        marginLeft: "1px",
        padding: "0.25em",
        backgroundColor: "white"
    }
})

export default withStyles(styles)(LegendTooltip)