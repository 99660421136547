/**
 * Copyright 2015, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {Typography} from "goodmap-core"

const Message = ({
    msgId,
    msgParams,
    ...rest
}) => {
   
    const {formatMessage} = useIntl()
   
    return (
        <Typography {...rest}>
            {formatMessage({id: msgId, values: msgParams})}
        </Typography>
    )
   
};

Message.propTypes = {
    msgId: PropTypes.string.isRequired,
    msgParams: PropTypes.object
}

Message.defaultProps = {

}

export default Message;
