
export const CHANGE_COLLECTOR_STATE = 'CHANGE_COLLECTOR_STATE';

export const changeCollectorState = (collectorState) => {
    return {
        type: CHANGE_COLLECTOR_STATE,
        data: collectorState
    };
}

