/* eslint-disable */
/**
 * Copyright 2018, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {default as proj4js} from 'proj4';
import axios from 'axios';
import ConfigUtils from "../utils/ConfigUtils";
import CoordinatesUtils from '../utils/CoordinatesUtils';
import LocaleUtils from "../utils/LocaleUtils";
import MapUtils from '../utils/MapUtils';
import CopyButton from '../components/widgets/CopyButton';
import Icon from '../components/Icon';
import displayCrsSelector from '../components/selectors/displaycrs';
import { useState } from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
require('./style/MapInfoTooltip.css');

const MapInfoTooltip = ({
    map,
    displaycrs,
    elevationPrecision,
    includeWGS84,
    enabled
}) => {
    
    const [state, setState] = useState({ coordinate: null, elevation: null, extraInfo: null })
    const {formatMessage} = useIntl()
    const handleSetState = (diff) => { setState(s => ({...s, ...diff})) }

    useEffect(() => {
        if(!enabled) {
            clear();
            return;
        }
        let newPoint = map.clickPoint;
        if(!newPoint || newPoint.button !== 2) {
            clear()
        } else {
            handleSetState({coordinate: newPoint.coordinate, elevation: null});
            let serviceParams = {pos: newPoint.coordinate.join(","), crs: map.projection};
            let elevationService = (ConfigUtils.getConfigProp("elevationServiceUrl") || "").replace(/\/$/, '');
            let elevationPrecisionLocal = elevationPrecision;
            if(elevationService) {
                axios.get(elevationService + '/getelevation', {params: serviceParams}).then(response => {
                    handleSetState({elevation: Math.round(response.data.elevation * Math.pow(10, elevationPrecisionLocal))/Math.pow(10, elevationPrecisionLocal)});
                }).catch(e => {});
            }
            let mapInfoService = ConfigUtils.getConfigProp("mapInfoService");
            if(mapInfoService) {
                axios.get(mapInfoService, {params: serviceParams}).then(response => {
                    handleSetState({extraInfo: response.data.results});
                }).catch(e => {});
            }
        }
    }, [enabled, map.clickPoint, map.projection])
   
   
    const clear = () => {
        handleSetState({coordinate: null, height: null, extraInfo: null});
    }

    if(!state.coordinate) {
        return null;
    }

    let info = [];

    let projections = [displaycrs];
    if(!projections.includes(map.projection)) {
        projections.push(map.projection);
    }
    if(includeWGS84 && !projections.includes("EPSG:4326")) {
        projections.push("EPSG:4326");
    }
    projections.map(crs => {
        let coo = CoordinatesUtils.reproject(state.coordinate, map.projection, crs);
        let digits = proj4js.defs(crs).units === 'degrees'? 4 : 0;
        info.push([
            CoordinatesUtils.getCrsLabels()[crs] || crs,
            coo.map(x => LocaleUtils.toLocaleFixed(x, digits)).join(", ")
        ]);
    });

    if(state.elevation) {
        info.push([
            formatMessage({id: "mapinfotooltip.elevation"}),
            state.elevation + " m"
        ]);
    }

    if(state.extraInfo) {
        info.push(...state.extraInfo);
    }
    let title = formatMessage({id: "mapinfotooltip.title"});
    let pixel = MapUtils.getHook(MapUtils.GET_PIXEL_FROM_COORDINATES_HOOK)(state.coordinate);
    let style = {
        left: pixel[0] + "px",
        top: pixel[1] + "px"
    };
    let text = info.map(entry => entry.join(": ")).join("\n");
    return (
        <div id="MapInfoTooltip" style={style}>
            <div className="mapinfotooltip-window">
                <div className="mapinfotooltip-titlebar">
                    <span className="mapinfotooltip-title">{title}</span>
                    <CopyButton text={text} buttonClass="mapinfotooltip-button" />
                    <Icon icon="remove" className="mapinfotooltip-button" onClick={clear}/>
                </div>
                <div className="mapinfotooltip-body">
                    <table>
                        <tbody>
                            {info.map((entry,index) => (
                                <tr key={"row" + index}>
                                    <td><b>{entry[0]}:</b></td>
                                    <td>{entry[1]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

MapInfoTooltip.propTypes = {
    map: PropTypes.object,
    displaycrs: PropTypes.string,
    elevationPrecision: PropTypes.number,
    includeWGS84: PropTypes.bool,
    enabled: PropTypes.bool
}

MapInfoTooltip.defaultProps = {
    elevationPrecision: 0,
    includeWGS84: true
}

const selector = createSelector([state => state, displayCrsSelector], (state, displaycrs) => ({
    enabled: state.identify.tool !== null,
    map: state.map ? state.map : null,
    displaycrs: displaycrs
}));

export default {
    MapInfoTooltipPlugin: connect(selector, {})(MapInfoTooltip)
}
