/* eslint-disable */
/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {zoomToExtent} from '../actions/map';
import { useIntl } from "react-intl";
import MapButton from "../components/MapButton";

const HomeButton = ({
    currentTheme,
    position,
    zoomToExtent
}) => {
    
    const {formatMessage} = useIntl()
    
    const resetExtent = () => {
        if(currentTheme) {
            let bbox = currentTheme.initialBbox;
            zoomToExtent(bbox.bounds, bbox.crs);
        }
    }

    const tooltip = formatMessage({id: "tooltip.home"});
    return (
        <MapButton 
            icon="FaHome" 
            type="fa"
            tooltip={tooltip} 
            position={{bottom: (`${5 + 3.5 * position}em`), right: 8}} 
            onClick={resetExtent} 
        />
    );
    
};

HomeButton.defaultProps = {
    position: 5
}

HomeButton.propTypes = {
    currentTheme: PropTypes.object,
    position: PropTypes.number,
    zoomToExtent: PropTypes.func
}


export default {
    HomeButtonPlugin: connect((state) => ({
        currentTheme: state.theme ? state.theme.current : null
    }), {
        zoomToExtent: zoomToExtent
    })(HomeButton),
    reducers: {
        map: require("../reducers/map")
    }
};
