/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import Message from '../components/I18N/Message';
import {setCurrentTask} from '../actions/task';
import Icon from '../components/Icon';
import {withStyles, List, ListItem, Collapse, ListItemText, ListItemIcon, ListSubheader, SideNav, Typography } from "goodmap-core";
import React, { useState, useRef, useEffect, Fragment } from "react";
import { toggleNavigation } from '../actions/global';
import { useIntl } from 'react-intl';
import logo from "../assets/logo.png"

const COLLAPSIBLE = false;

const styles = (theme) => ({
    listItemIcon: {
        minWidth: 40
    },
    menuList: {
        background: theme.palette.common.white
    },
    listItem: {
        // padding: 0,
        // paddingLeft: theme.spacing(2)
    },
    collapse: {
        paddingLeft: 16,
        background: theme.palette.grey[100]   
    },
    sideNavFade: {
        zIndex: 99
    },
    sideBarRoot: {
        zIndex: 100,
    },
    sideBarPaper: {
        maxWidth: 250,
        zIndex: 100
    },
    caption: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.875rem",
        padding: theme.spacing(2)
    }
})

const AppMenu = ({
    buttonContents,
    menuItems,
    appMenuClearsTask,
    currentTaskBlocked,
    setCurrentTask,
    currentTheme,
    showOnStartup,
    onMenuToggled,
    keepMenuOpen,
    classes
}) => {
    
    const {toggleNav} = useSelector(state => state.global)
    const dispatch = useDispatch()

    const [{submenusVisible}, setState] = useState({
        submenusVisible: []
    })

    const {formatMessage} = useIntl();


    const menuItemsOrdered = [...menuItems.filter(x => !x.subitems || !x.subitems.length), ...menuItems.filter(x => x.subitems && x.subitems.length)]
    useEffect(() => {
        if(showOnStartup) 
            toggleMenu();
    }, [showOnStartup])
  
    const toggleMenu = () => {
        // if(menuVisible) setCurrentTask(null);
        if(!toggleNav && appMenuClearsTask)
            setCurrentTask(null);

        onMenuToggled(!toggleNav);
        dispatch(toggleNavigation(!toggleNav))
        setState({ submenusVisible: [] });
    }

    const onSubmenuClicked = (key, level) => {
        let a = submenusVisible[level] === key ? [] : [key];
        dispatch(toggleNavigation(true))
        setState({ submenusVisible: submenusVisible.slice(0, level).concat(a) });
    }

    const onMenuitemClicked = (item) => {
        if(!keepMenuOpen) {
            toggleMenu();
        }
        if(item.url) {
            // openExternalUrl(item.url, item.target, "appmenu.items." + item.key);
        } else {
            setCurrentTask(item.task || item.key, item.mode, item.mapClickAction || (item.identifyEnabled ? "identify" : null));
        }
    }

    const isSubmenuOpen = (idx) => {
        const a = submenusVisible.find(x => x === idx);
        if(a){
            return true
        }
        return false
    }

    const renderMenuItems = (items, level) => {
        if(!items) return null;
        
        return items.map(item => {
            if(item.themeWhitelist && !item.themeWhitelist.includes(currentTheme.title)) {
                return null;
            }
            if(item.subitems) {
                return (
                    COLLAPSIBLE ? <Fragment key={item.key}>
                        <ListItem 
                            className={classes.listItem}
                            button 
                            onClick={ev => onSubmenuClicked(item.key, level)}
                        >
                            <ListItemIcon 
                                className={classes.listItemIcon}
                            >
                                <Icon icon={item.icon} fontSize={22}/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={<Message msgId={"appmenu.items." + item.key} />} 
                                secondary={item.title} 
                            />
                        </ListItem>
                        <Collapse 
                            className={classes.collapse} 
                            in={isSubmenuOpen(item.key)} 
                            timeout="auto"
                            unmountOnExit
                        >
                            <List dense={true} component="div" disablePadding>
                                {renderMenuItems(item.subitems, level + 1)}
                            </List>
                        </Collapse>
                    </Fragment> : 
                    <Fragment key={item.key}>
                        {(item.subitems && item.subitems.length > 0) ? 
                            <Message classes={{root: classes.caption}} msgId={"appmenu.items." + item.key} /> :
                            <ListItem
                                className={classes.listItem}
                                button 
                                onClick={ev => onSubmenuClicked(item.key, level)}
                            >
                                <ListItemIcon 
                                    className={classes.listItemIcon}
                                >
                                    <Icon icon={item.icon} fontSize={22}/>
                                </ListItemIcon>
                                <ListItemText 
                                    primary={<Message msgId={"appmenu.items." + item.key} />} 
                                    secondary={item.title} 
                                />
                            </ListItem>
                        }
                        {renderMenuItems(item.subitems, level + 1)}

                    </Fragment>
                );
            } else {
                return (
                    <ListItem className={classes.listItem} button key={item.key + (item.mode || "")} onClick={ev => onMenuitemClicked(item)} >
                        <ListItemIcon className={classes.listItemIcon}>
                            <Icon icon={item.icon} fontSize={22}/>
                        </ListItemIcon>
                        <ListItemText 
                            primary={<Message msgId={`appmenu.items.${item.key}`} />} 
                            secondary={item.comment ? <Message msgId={`appmenu.items.${item.key}${item.mode || ""}_comment`} /> : null}
                        />
                    </ListItem>
                );
            }
        });
    }

    return (
        <>
            <SideNav 
                logo={logo}
                open={toggleNav}
                fade={true}
                classes={{root: classes.sideBarRoot, paper: classes.sideBarPaper, fade: classes.sideNavFade}}
                onClose={toggleMenu} 
                title="Menu" 
            >
                <List 
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {formatMessage({id: "appmenu.title"})}
                        </ListSubheader>
                    }
                    disablePadding={true} 
                    dense={true} 
                    className={classes.menuList}
                >
                    {renderMenuItems(menuItemsOrdered, 0)}
                </List> 
            </SideNav>
        </>
    );
};

AppMenu.defaultProps = {
    buttonContents: null,
    appMenuClearsTask: false,
    showOnStartup: false,
    onMenuToggled: () => {}
}

AppMenu.propTypes = {
    buttonContents: PropTypes.object,
    menuItems: PropTypes.array,
    appMenuClearsTask: PropTypes.bool,
    currentTaskBlocked: PropTypes.bool,
    setCurrentTask: PropTypes.func,
    currentTheme: PropTypes.object,
    showOnStartup: PropTypes.bool,
    onMenuToggled: PropTypes.func,
    keepMenuOpen: PropTypes.bool,
    classes: PropTypes.object
}

const AppMenuWithStyles = withStyles(styles)(AppMenu);

export default {
    AppMenuPlugin: connect(state => (
        {
            currentTaskBlocked: state.task && state.task.blocked || false,
            currentTheme: state.theme.current || {}
        }
    ), {
        setCurrentTask: setCurrentTask
    })(AppMenuWithStyles)
}