/**
* Copyright 2018, Sourcepole AG.
* All rights reserved.
*
* This source code is licensed under the BSD-style license found in the
* LICENSE file in the root directory of this source tree.
*/

import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {withStyles} from "goodmap-core"
import './style/ColorButton.css'

const styles = () => ({
    buttonIcon: {
        borderRadius: "0.25em",
        overflow: "hidden",
        width: "2em",
        height: "2em",
        margin: "0.15em",
        display: "inline-block",
        cursor: "pointer",
        "& img": {
            height: "95%",
            width: "95%"
        }
    },
    pickerContainer: {
        height: 200,
        overflow: "auto"
    }
})

const defaultIcons = [
    "Cross_Circle_Black.svg",
    "Cross_Circle_Blue.svg",
    "Cross_Circle_Green.svg",
    "Cross_Circle_Red.svg",
    "Cross_Circle_White.svg",
    "Cross_Circle_Yellow.svg",
    "Excavator_Circle_Black.svg",
    "Excavator_Circle_Blue.svg",
    "Excavator_Circle_Green.svg",
    "Excavator_Circle_Red.svg",
    "Excavator_Circle_White.svg",
    "Excavator_Circle_Yellow.svg",
    "Garbage_Circle_Black.svg",
    "Garbage_Circle_Blue.svg",
    "Garbage_Circle_Green.svg",
    "Garbage_Circle_Red.svg",
    "Garbage_Circle_White.svg",
    "Garbage_Circle_Yellow.svg",
    "Gas_Circle_Black.svg",
    "Gas_Circle_Blue.svg",
    "Gas_Circle_Green.svg",
    "Gas_Circle_Red.svg",
    "Gas_Circle_White.svg",
    "Gas_Circle_Yellow.svg",
    "Lamp_Circle_Black.svg",
    "Lamp_Circle_Blue.svg",
    "Lamp_Circle_Green.svg",
    "Lamp_Circle_Red.svg",
    "Lamp_Circle_White.svg",
    "Lamp_Circle_Yellow.svg",
    "PIN_Black.svg",
    "PIN_Blue.svg",
    "PIN_Circles_Black.svg",
    "PIN_Circles_Blue.svg",
    "PIN_Circles_Green.svg",
    "PIN_Circles_Red.svg",
    "PIN_Circles_White.svg",
    "PIN_Circles_Yellow.svg",
    "PIN_Green.svg",
    "PIN_Red.svg",
    "PIN_Volumes_Black.svg",
    "PIN_Volumes_Blue.svg",
    "PIN_Volumes_Green.svg",
    "PIN_Volumes_Red.svg",
    "PIN_Volumes_White.svg",
    "PIN_Volumes_Yellow.svg",
    "PIN_White.svg",
    "PIN_Yellow.svg",
    "Pothole_Triangle_Black.svg",
    "Pothole_Triangle_Blue.svg",
    "Pothole_Triangle_Green.svg",
    "Pothole_Triangle_Red.svg",
    "Pothole_Triangle_White.svg",
    "Pothole_Triangle_Yellow.svg",
    "RIP_Circle_Black.svg",
    "RIP_Circle_Blue.svg",
    "RIP_Circle_Green.svg",
    "RIP_Circle_Red.svg",
    "RIP_Circle_White.svg",
    "RIP_Circle_Yellow.svg",
    "Warning_Triangle_Black.svg",
    "Warning_Triangle_Blue.svg",
    "Warning_Triangle_Green.svg",
    "Warning_Triangle_Red.svg",
    "Warning_Triangle_White.svg",
    "Warning_Triangle_Yellow.svg",
    "Water_Circle_Black.svg",
    "Water_Circle_Blue.svg",
    "Water_Circle_Green.svg",
    "Water_Circle_Red.svg",
    "Water_Circle_White.svg",
    "Water_Circle_Yellow.svg"
];


const SymbolButton = ({
    icon: currentIcon,
    onIconChanged,
    classes
}) => {

    const [state, setState] = useState({
        pickerVisible: false,
        icon: null
    });
    
    const pickerEl = useRef(null)

    
    const togglePicker = useCallback((ev) => {
        ev.stopPropagation();
        setState({icon: null, pickerVisible: !state.pickerVisible});
    }, [state.pickerVisible, setState])


    const checkClosePicker = useCallback((ev) => {
        if(pickerEl.current && !pickerEl.current.contains(ev.target)) {
            togglePicker(ev);
        }
    }, [togglePicker, pickerEl])


    useEffect(() => {
        state.pickerVisible && document.addEventListener('click', checkClosePicker);
        return () => {
            document.removeEventListener('click', checkClosePicker);
        }
    }, [state.pickerVisible, checkClosePicker])


    const selectIcon = (icon) => {
        setState({icon: null});
        onIconChanged(icon);
    }

    return (
        <span className="ColorButton">
            <span className={classes.buttonIcon} onClick={togglePicker}>
                <img alt="icon" src={`/markers/${currentIcon}`}/>
            </span>
            <div ref={pickerEl} className="colorbutton-picker" style={{
                visibility: state.pickerVisible ? 'visible' : 'hidden'
            }}>
                <div className={classes.pickerContainer}>
                    {defaultIcons.map((icon, idx) => (
                        <span onClick={() => selectIcon(icon)}  className={classes.buttonIcon} key={"color" + idx}>
                            <img alt="icon" src={`/markers/${icon}`}/>
                        </span>
                    ))}
                </div>
            </div>
        </span>
    )
};

SymbolButton.propTypes = {
    icon: PropTypes.array,
    onIconChanged: PropTypes.func
}

export default withStyles(styles)(SymbolButton);
