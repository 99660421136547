import ol_style_Circle from 'ol/style/Circle';
import ol_style_Fill from 'ol/style/Fill';
import ol_style_Stroke from 'ol/style/Stroke';
import ol_style_Text from 'ol/style/Text';
import ol_geom_MultiPoint from 'ol/geom/MultiPoint';
import ol_style_RegularShape from 'ol/style/RegularShape'
import ol_style_Style from 'ol/style/Style';
import ol_format_GeoJSON from 'ol/format/GeoJSON';

export const createGoodmapFeature = ({feature, style, styleName, properties}) => {
    const format = new ol_format_GeoJSON();
    const f = format.writeFeatureObject(feature);
    
    if(style)
        f.styleOptions = style;

    if((style && style.styleName) || styleName)
        f.styleName = styleName || "default";
    
    if(properties){
        delete properties.geometry;
        f.properties = {...properties, styleName };
    } else {
        const p = feature.getProperties();
        delete p.geometry;
        f.properties = {...p, styleName };
    }
   
    return f;
}



export const extractStylesFromFeature = (feature, baseStyle) => {

    const styleName = feature.get("styleName");
    const fstyle = feature.getStyle().length ? feature.getStyle()[0] : feature.getStyle(); 
   
    if(styleName === "text"){
        const text = fstyle.getText();
        const textValue = text.getText();
        const fillColor = text.getFill().getColor();
        const strokeColor = text.getStroke().getColor();
        const scale = text.getScale();

        return {
            geomType: 'Text',
            styleName: 'text',
            style: {   
                styleName: 'text', 
                ...baseStyle,
                stroke: { width: scale, color: strokeColor },
                point: { radius: scale },
                fill: { color: fillColor },
                text: { value: textValue, fill: fillColor, stroke: strokeColor }
            }
        }

    } else if(styleName === "marker"){
        debugger
        const st = fstyle.getImage().getImage().src;
        // const strokeColor = fstyle.getStroke().getColor();
        const text = fstyle.getText();
        const iconScale = fstyle.getImage().getScale();
        const pointSize = fstyle.getImage().getScale();
        const font = text.getFont();
        const textSize = font.substring(0, font.indexOf("pt"))
        const textStrokeWidth = parseInt(text.getStroke().getWidth());
        const textFillColor = text.getFill().getColor();
        const textValue = text.getText();
        const textStrokeColor = text.getStroke().getColor();
        // eslint-disable-next-line
        const iconSrc = st.replace(/^.*[\\\/]/, '');
        debugger
        return {
            geomType: feature.getGeometry().getType(),
            styleName: "marker",
            style: {
                ...baseStyle,
                // stroke: { width: pointSize, color: strokeColor },
                icon: { src: iconSrc, size: pointSize, scale: iconScale / 0.1 },
                point: { radius: pointSize },
                // fill: { color: strokeColor },
                text: { 
                    value: textValue,
                    size: textSize,
                    borderSize: textStrokeWidth,
                    fill: textFillColor, 
                    stroke: textStrokeColor 
                }
            },
        };
    } else {
        const strokeColor = fstyle.getStroke().getColor();
        const strokeWidth = fstyle.getStroke().getWidth();
        const text = fstyle.getText();
        const font = text.getFont();
        const textSize = font.substring(0, font.indexOf("pt"))
        const textStrokeWidth = parseInt(text.getStroke().getWidth());
        const textFillColor = text.getFill().getColor();
        const textValue = text.getText();
        const textStrokeColor = text.getStroke().getColor();
        const pointSize = fstyle.getImage().getRadius();
        const fillColor = fstyle.getFill().getColor();
        return {
            geomType: feature.getGeometry().getType(),
            styleName: "default",
            style: {
                ...baseStyle,
                stroke: { width: strokeWidth, color: strokeColor },
                point: { radius: pointSize },
                fill: { color: fillColor },
                text: { 
                    value: textValue,
                    size: textSize,
                    borderSize: textStrokeWidth,
                    fill: textFillColor, 
                    stroke: textStrokeColor 
                }
            }
        }
    }
}

export const defaultStyle = new ol_style_Style({
    image: new ol_style_Circle({
        radius: 7,
        fill: new ol_style_Fill({ color: 'rgba(255, 0, 0, 0.1)' }),
        stroke: new ol_style_Stroke({ color: '#ff0000', width: 1 })
    }),
    fill: new ol_style_Fill({ color: 'rgba(255, 0, 0, 0.1)' }),
    stroke: new ol_style_Stroke({ color: '#ff0000', width: 1 }),
    text: new ol_style_Text({
        font: '12px Calibri,sans-serif',
        fill: new ol_style_Fill({ color: '#000' }),
        stroke: new ol_style_Stroke({ color: '#fff', width: 3 })
    })
});

export const selectedStyle = new ol_style_Style({
    image: new ol_style_RegularShape({
        fill: new ol_style_Fill({color: 'white'}),
        stroke: new ol_style_Stroke({color: 'red', width: 2}),
        points: 4,
        radius: 5,
        angle: Math.PI / 4
    }),
    geometry: (f) => {
        if(f.getGeometry().getType() === "Point") {
            return new ol_geom_MultiPoint([f.getGeometry().getCoordinates()]);
        } else if(f.getGeometry().getType() === "LineString") {
            return new ol_geom_MultiPoint(f.getGeometry().getCoordinates());
        } else {
            return new ol_geom_MultiPoint(f.getGeometry().getCoordinates()[0]);
        }
    }
});

export const selectedTextStyle = (feature, opts) => new ol_style_Style({
    text: new ol_style_Text({
        font: '10pt sans-serif',
        text: feature.getProperties()["label"] || "",
        scale: opts.strokeWidth,
        fill: new ol_style_Fill({color: opts.fillColor}),
        stroke: new ol_style_Stroke({color: [0,0,0,0.5], width: 4})
    })
});

export const styleOptions = (style) => {
    return {
        stroke: {
            color: [0, 0, 255, 1],
            width: 2,
            dash: [4]
        },
        fill: {
            color: [0, 0, 255, 0.33]
        },
        point: {
            radius: 10
        },
        text: {
            fill: "black",
            stroke: "white"
        }
        // strokeColor: style.borderColor,
        // strokeWidth: 1 + 0.5 * style.size,
        // fillColor: style.fillColor,
        // iconSrc: style.marker ? `/assets/markers/${style.marker}` : "/assets/markers/PIN_Red.svg",
        // scale: style.size * 0.3,
        // circleRadius: 5 + style.size,
        // strokeDash: []
    };
}