/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Window from '../components/Window';
import ThemeList from '../components/ThemeList';
import ConfigUtils from "../utils/ConfigUtils";

const ThemeSwitcher = ({
    activeTheme,
    showLayerAfterChangeTheme,
    collapsibleGroups
}) => {
 
    const allowAddingOtherThemes = ConfigUtils.getConfigProp("allowAddingOtherThemes", activeTheme) ===  true;
    
    return (
        <Window 
            padding={1} 
            position="center" 
            id="ThemeSwitcher" 
            modal
            maxHeight={350}
            maxWidth={500} 
            title="appmenu.items.ThemeSwitcher"
            icon="themes" 
        >
            {() => ({
                body: (
                    <ThemeList
                        showLayerAfterChangeTheme={showLayerAfterChangeTheme}
                        collapsibleGroups={collapsibleGroups}
                        allowAddingOtherThemes={allowAddingOtherThemes}
                        activeTheme={activeTheme}
                    />
                )
            })}
        </Window>
    );
};

ThemeSwitcher.propTypes = {
    activeTheme: PropTypes.object,
    width: PropTypes.string,
    showLayerAfterChangeTheme: PropTypes.bool,
    collapsibleGroups: PropTypes.bool
}

ThemeSwitcher.defaultProps = {
    showLayerAfterChangeTheme: false
}

const selector = (state) => ({
    activeTheme: state.theme ? state.theme.current : null
});

export default {
    ThemeSwitcherPlugin: connect(selector, {})(ThemeSwitcher)
};
