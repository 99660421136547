/**
 * Copyright 2016, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
import {combineReducers} from 'redux';
import merge from 'deepmerge';
import DebugUtils from '../utils/DebugUtils';
// import PluginsUtils from '../utils/PluginsUtils';
import {CHANGE_BROWSER_PROPERTIES} from '../actions/browser';
import localConfig from '../reducers/localConfig';
import locale from '../reducers/locale';
import locate from '../reducers/locate';
import browser from '../reducers/browser';
import identify from '../reducers/identify';
import global from '../reducers/global';
import map from '../reducers/map';
import drawing from '../reducers/drawing';
import windows from '../reducers/windows';
import layers from '../reducers/layers';
import collector from '../reducers/collector';
import display from '../reducers/display'
import editing from '../reducers/editing'
import layerinfo from '../reducers/layerinfo'
import measurement from '../reducers/measurement'
import mousePosition from '../reducers/mousePosition'
import redlining from '../reducers/redlining'
import search from '../reducers/search'
import selection from '../reducers/selection'
import task from '../reducers/task'
import theme from '../reducers/theme'
import vectorLayer from '../reducers/vectorLayer'

const store = (initialState = {defaultState: {}, mobile: {}}, plugins, storeOpts, actionLogger) => {
    const allReducers = combineReducers({
        localConfig,
        locale,
        global,
        browser,
        identify,
        map,
        locate,
        layers,
        windows,
        collector,
        display,
        drawing,
        editing,
        layerinfo,
        measurement,
        mousePosition,
        redlining,
        search,
        selection,
        task,
        theme,
        vectorLayer

        // ...PluginsUtils.getPluginReducers(plugins)
    });

    const defaultState =  merge({
        ...allReducers({}, {type: null}),
    }, initialState.defaultState);
    const mobileOverride = initialState.mobile;

    const rootReducer = (state, action) => {
        let newState = {
            ...allReducers(state, action),
        };
        if(actionLogger) {
            actionLogger(action, newState);
        }
        if (action && action.type === CHANGE_BROWSER_PROPERTIES && newState.browser.mobile) {
            newState = merge(newState, mobileOverride);
        }

        return newState;
    };
    return DebugUtils.createDebugStore(rootReducer, defaultState);
};

export default store;
