import React from "react"
import { Fab, withStyles } from "goodmap-core"
import { useIntl } from "react-intl";
import { FaArrowDown, FaArrowUp, FaDrawPolygon, FaEdit, FaFileExport } from "react-icons/fa";
import Icon from "../../components/Icon";
import { LayerRole } from "../../actions/layers";
import { MdEdit, MdInfoOutline, MdZoomOutMap } from "react-icons/md";

const LayerMenu = ({
    filterVisibleLayers,
    allowReordering,
    layer,
    sublayer,
    path,
    reorderLayer,
    zoomToExtent,
    classes,
    editUserLayerStructure,
    exportRedliningLayerToKml,
    exportRedliningLayerToJSON,
    allowRemove,
    layerTransparencyChanged,
    setActiveLayerInfo,
    editSystemLayer,
    editUserLayer
}) => {

    const { formatMessage } = useIntl()

    const editLayerTooltip = formatMessage({id: "layertree.editlayer"});
    const editLayerFormTooltip = formatMessage({id: "layertree.editlayerform"});
    const moveUpTooltip = formatMessage({id: "layertree.moveup"})
    const moveDownTooltip = formatMessage({id: "layertree.movedown"});
    const zoomToLayerTooltip = formatMessage({id: "layertree.zoomtolayer"});
    const exportTooltip = formatMessage({id:"layertree.export"});
    const exportTooltipKml = formatMessage({id:"layertree.exportToKml"});
    const infoTooltip = formatMessage({id: "layertree.infotooltip"});

    return (
        <div className={classes.itemContextMenuFrame} style={{marginRight: allowRemove ? '1.75em' : 0}}>
            <div className={classes.layerSettingsPanel}>
                <div className={classes.layerSettingsPanelTop}>
                    {(sublayer.bbox && sublayer.bbox.bounds && sublayer.bbox.crs) &&
                         <Fab 
                            tooltip={zoomToLayerTooltip} 
                            className={classes.fabButton} 
                            onClick={(e) => {
                                e.stopPropagation()
                                zoomToExtent(sublayer.bbox.bounds, sublayer.bbox.crs)}
                            } 
                            size="small" 
                            color="primary" 
                        >
                            <MdZoomOutMap size={16}/>
                        </Fab>
                    }                       
                    {(allowReordering && !filterVisibleLayers) &&
                        <>
                            <Fab 
                                key="layertree-item-move-down" 
                                tooltip={moveDownTooltip} 
                                className={classes.fabButton} 
                                onClick={(e) => {
                                    e.stopPropagation()
                                    reorderLayer(layer, path, +1)
                                }} 
                                size="small" 
                                color="primary"
                            >
                                <FaArrowDown size={16}/>
                            </Fab>
                            <Fab 
                                key="layertree-item-move-up" 
                                tooltip={moveUpTooltip} 
                                className={classes.fabButton} 
                                onClick={(e) => {
                                    e.stopPropagation()
                                    reorderLayer(layer, path, -1)
                                }} 
                                size="small" 
                                color="primary" 
                            >
                                <FaArrowUp size={16}/>
                            </Fab>
                        </>
                    }
                    {(layer.type === "wms" || layer.type === "wfs") && (
                        <Fab 
                            tooltip={infoTooltip} 
                            className={classes.fabButton} 
                            onClick={(e) => {
                                e.stopPropagation()
                                setActiveLayerInfo(layer, sublayer)
                            }}
                            size="small" 
                            color="primary" 
                        >
                            <MdInfoOutline size={16} />
                        </Fab>
                    )}
                    {(layer.type === 'vector') && (
                        <>
                            <Fab 
                                tooltip={exportTooltip} 
                                className={classes.fabButton} 
                                onClick={(e) => {
                                    e.stopPropagation()
                                    exportRedliningLayerToJSON(layer)}
                                } 
                                size="small" 
                                color="primary"
                            >
                                <FaFileExport size={16}/>
                            </Fab>
                            <Fab tooltip={exportTooltipKml} className={classes.fabButton} onClick={(e) => {
                                e.stopPropagation()
                                exportRedliningLayerToKml(layer)}
                            } size="small" color="primary" >
                                <FaFileExport size={16}/>
                            </Fab>
                        </>
                    )}            
                    {(layer.role === LayerRole.USERLAYER) && (
                        <Fab 
                            tooltip={editLayerFormTooltip}
                            className={classes.fabButton}
                            onClick={(e) => {
                                e.stopPropagation()
                                editUserLayer(layer)
                            }} 
                            size="small" 
                            color="primary" 
                        >
                            <MdEdit size={16} />
                        </Fab>
                    )}            
                    {(layer.role === LayerRole.USERLAYER) && (
                        <Fab 
                            tooltip={editLayerFormTooltip} 
                            className={classes.fabButton} 
                            onClick={(e) => {
                                e.stopPropagation()
                                editUserLayerStructure(layer)
                            }} 
                            size="small" 
                            color="primary" 
                        >
                            <FaEdit size={16}/>
                        </Fab>
                    )}            
                    {(layer.role === LayerRole.SYSTEMLAYER) && (
                            <Fab 
                                tooltip={editLayerTooltip} 
                                className={classes.fabButton} 
                                onClick={(e) => {
                                    e.stopPropagation()
                                    editSystemLayer(layer)
                                }} 
                                size="small" 
                                color="primary" 
                            >
                                <FaDrawPolygon size={16}/>
                            </Fab>
                    )}                                    
                </div>
                <div onClick={(e) => e.stopPropagation()} className={classes.layerSettingsPanelBottom}>
                    <Icon fontSize={20} icon="MdOpacity" />
                    <input 
                        className="layertree-item-transparency-slider" 
                        type="range" 
                        min="0" 
                        max="255" 
                        
                        step="1" 
                        defaultValue={255-sublayer.opacity} 
                        onMouseUp={(ev) => {
                            // ev.stopPropagation(); 
                            layerTransparencyChanged(layer, path, ev.target.value)
                        }} 
                        onTouchEnd={(ev) => {
                            // ev.stopPropagation()
                            layerTransparencyChanged(layer, path, ev.target.value)
                        }} />
                </div>
            </div>
        </div>
    );
}

const styles = (theme) => ({
    root: {

    },
    itemContextMenuFrame: {
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center"
    },
    layerSettingsPanel: {
        flex: "0 0 auto",
        maxWidth: "99%",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 4,
        paddingRight: 4,
        boxShadow: "0 1px 4px 0 rgba(0,0,0,0.5)",
        fontWeight: "bold",
        alignItems: "center",
        backgroundColor: "white"
    },
    layerSettingsPanelTop: {
        display: "flex"
    },
    layerSettingsPanelBottom: {
        display: "flex",
        paddingTop: 8,
    },
    fabButton: {
        margin: 4,
        width: 35,
        height: 35
    }
})

export default withStyles(styles)(LayerMenu)