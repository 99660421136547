// import React from "react"

const DataCollector = () => {



    return null
}

const plugin = { DataCollectorPlugin: DataCollector }
export default plugin