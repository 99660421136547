import { useEffect } from "react";
import {useModifyInteraction} from "../hooks/useModifyInteraction"
import { useMap } from "../hooks/useMap";


const ModifyInteraction = ({
   modifyEnd,
   modifyStart,
   features
}) => {

    const map = useMap()

    const { modifyInteraction } = useModifyInteraction({
        features, 
        onModifyStart: modifyStart,
        onModifyEnd: modifyEnd
    })

    useEffect(() => {
        map.addInteraction(modifyInteraction);
        return () => {
            map.removeInteraction(modifyInteraction);
        }
    }, [map, modifyInteraction])


    return null;
        
}

ModifyInteraction.propTypes = {

}

ModifyInteraction.defaultProps = {

}

export default ModifyInteraction