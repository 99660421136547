/* eslint-disable */
/**
 * Copyright 2015, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from "react-intl";
// import messages from "../../i18n/index"

const Localized = ({
    children,
    locale,
    messages,
    loadingError
}) => {
   
    if (locale) {
        if (typeof children === 'function') {
            children = children();
        }

        return (
            <IntlProvider 
                key={locale} 
                locale={locale}
                messages={messages}
            >
                {children}
            </IntlProvider>
        );
    } else if (loadingError) {
        return <div className="loading-locale-error">{loadingError}</div>;
    }

    return null;
    
 };

 Localized.propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.object,
    loadingError: PropTypes.string,
    locale: PropTypes.string,
    messages: PropTypes.object
 }

 export default connect((state) => ({
     locale: state.locale.current,
     messages: state.locale.messages,
     loadingError: state.locale.localeError
 }), {})(Localized);
