/* eslint-disable */
import React, { useEffect } from "react"
import { useGoodmapContext, useGoodmapAction, useApi } from "goodmap-core"
import { withStyles } from "goodmap-core";
import AppMenu from '../plugins/AppMenu';
import ThemeSwitcherPlugin from '../plugins/ThemeSwitcher';
import SharePlugin from '../plugins/Share';
import PrintPlugin from '../plugins/Print';
import LayerTreePlugin from '../plugins/LayerTree';
import HelpPlugin from '../plugins/Help';
import UserProfilePlugin from '../plugins/UserProfile';
import EditingPlugin from '../plugins/Editing';
import IdentifyPlugin from '../plugins/Identify';
import MapCopyrightPlugin from '../plugins/MapCopyright';
import MeasurePlugin from '../plugins/Measure';
import DxfExportPlugin from '../plugins/DxfExport';
import RasterExportPlugin from '../plugins/RasterExport';
import RedliningPlugin from '../plugins/Redlining';
import MapTipPlugin from '../plugins/MapTip';
import BottomBarPlugin from '../plugins/BottomBar';
import MapComparePlugin from '../plugins/MapCompare';
import HeightProfilePlugin from '../plugins/HeightProfile';
import MapInfoTooltipPlugin from '../plugins/MapInfoTooltip';
import DataCollectorPlugin from '../plugins/DataCollector';
import IdentifyRegionPlugin from '../plugins/IdentifyRegion';
import StartupMarkerPlugin from '../plugins/StartupMarker';
import HomeButtonPlugin from '../plugins/HomeButton';
import LocateButtonPlugin from '../plugins/LocateButton';
import ZoomInPlugin from '../plugins/ZoomButtons';
import ZoomOutPlugin from '../plugins/ZoomButtons';
import TaskButtonPlugin from '../plugins/TaskButton';
import BackgroundSwitcherPlugin from '../plugins/BackgroundSwitcher';
import SearchPlugin from '../plugins/Search';
import APIPlugin from "../plugins/API";
import AuthenticationPlugin from "../plugins/Authentication";
import CadastreAnalysePlugin from "../plugins/CadastreAnalyse";
import { useLogger, Loader } from "goodmap-core";
import Logo from "../assets/logo.png"
import languages from "./languages"

const general =  {
    initialState: {
        defaultState: {},
        mobile: {}
    },
    pluginsDef: {
        plugins: {
            // APIPlugin,
            // AuthenticationPlugin,
            // MapPlugin,
            HomeButtonPlugin,
            UserProfilePlugin,
            LocateButtonPlugin,
            ZoomInPlugin,
            ZoomOutPlugin,
            TaskButtonPlugin,
            BackgroundSwitcherPlugin,
            SearchPlugin,
            BottomBarPlugin,
            AppMenu,
            MeasurePlugin,
            ThemeSwitcherPlugin,
            LayerTreePlugin,
            IdentifyPlugin,
            MapTipPlugin,
            DataCollectorPlugin, 
            SharePlugin,
            MapCopyrightPlugin,
            PrintPlugin,
            HelpPlugin,
            DxfExportPlugin,
            RasterExportPlugin,
            RedliningPlugin,
            EditingPlugin,
            MapComparePlugin,
            HeightProfilePlugin,
            MapInfoTooltipPlugin,
            IdentifyRegionPlugin,
            StartupMarkerPlugin,
            CadastreAnalysePlugin            
        },
        cfg: {}
    },
    actionLogger: (action) => { },
    themeLayerRestorer: (missingLayers, theme, callback) => {
        const newLayers = [];
        const newLayerNames = {};
        callback(newLayers, newLayerNames);
    },
    supportedLocales: languages
};

const AppConfig = ({ children, classes }) => {

    const config = useGoodmapContext();
    const { setSystemMessages } = useGoodmapAction();
    const logger = useLogger();
    const [{ data, loading, error }, refetch] = useApi({url: `/configs/goodmap`});
  
    useEffect(() => {
        if(!loading && (!data || !data.config)){
            setSystemMessages([{
                text: "No theme configured.", 
                type: "warning"
            }])
        }
    }, [data, loading])
    
    if(loading) return (
        <div className={classes.loader}>
            <img src={Logo} alt="logo" className={classes.logo} />
             <Loader />
        </div>
    )

    if(!data || !data.config)
        return null

    try {

        const theme = JSON.parse(data.config)
        const generalConfig = JSON.parse(data.general)
        const mobileConfig = JSON.parse(data.mobile_config)
        const desktopConfig = JSON.parse(data.desktop_config)

        return children({
            theme, 
            config: {
                ...generalConfig, 
                plugins: {
                    desktop: desktopConfig,
                    mobile: mobileConfig
                }
            }, 
            general
        })

    } catch(ex){
        logger.error(ex);
        setSystemMessages([{
            text: "Vyskytla sa neočakávaná chyba.", 
            type: "error"
        }])
        return null;
    }
}

const styles = (theme) => ({
    root: {},
    message: {
        marginTop: theme.spacing(2)
    },
    logo: {
        height: "auto",
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    loader: {
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: 200,
        height: 200,
        top: "50%",
        left: "50%",
        transform: "translate(-100px, -100px)"
    }
})

export default withStyles(styles)(AppConfig);