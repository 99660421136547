/**
 * Copyright 2017, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { CHANGE_COLLECTOR_STATE } from '../actions/collector';

const assign = require('object-assign');

function collector(state = {
    created: [],
    updated: [],
    removed: [],
    action: null,
    geomType: null,
    definition: null,
    styleName: "default",
    style: {
        borderColor: [255, 0, 0, 1],
        size: 2,
        scale: 1,
        marker: "PIN_Red.svg",
        fillColor:  [255, 255, 255, 1],
        text: "",
    },
    layer: null,
    layerTitle: '',
    selectedFeature: null,
    currentFeature: null
}, action) {
    switch (action.type) {
        case CHANGE_COLLECTOR_STATE:
            return assign({}, state, action.data);
        default:
            return state;
    }
}

export default collector;
