/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import sortBy from 'lodash.sortby';
import ConfigUtils from "../utils/ConfigUtils";
import Message from '../components/I18N/Message';
import {LayerRole, changeLayerProperty} from '../actions/layers';
import Icon from '../components/Icon';
import { useState } from "react";
import { useIntl } from "react-intl";
import MapButton from "../components/MapButton"
import './style/BackgroundSwitcher.css'

const BackgroundSwitcher = ({
    position,
    layers,
    toggleBackgroundswitcher,
    changeLayerProperty
}) => {
    
    const [visible, setVisible] = useState(false)
    const {formatMessage} = useIntl()
   
    const renderLayerItem = (layer, visible) => {
        let assetsPath = ConfigUtils.getConfigProp("assetsPath");
        let itemclasses = classnames({
            "background-switcher-item": true,
            "background-switcher-item-active": visible
        });
        return (
            <div key={layer ? layer.name : "empty"} className={itemclasses} onClick={() => backgroundLayerClicked(layer)}>
                <div className="background-layer-title">
                    {layer ? (<span>{layer.title}</span>) : (<Message msgId={"bgswitcher.nobg"} />)}
                </div>
                <div className="background-layer-thumbnail">
                    <img src={layer ? assetsPath + "/" + layer.thumbnail : "data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="} />
                </div>
            </div>
        );
    }

    const renderGroupItem = (entry) => {
        let assetsPath = ConfigUtils.getConfigProp("assetsPath");
        let layer = layer = (entry.layers.find(layer => layer.visibility === true) || entry.layers.find(layer => layer.default === true)) || entry.layers[entry.layers.length-1];

        let itemclasses = classnames({
            "background-switcher-item": true,
            "background-switcher-item-active": layer.visibility
        });
        return (
            <div key={layer.name} className={itemclasses}>
                <div className="background-layer-title">
                    <span>{layer.title}</span><Icon icon="chevron-down" />
                </div>
                <div className="background-layer-thumbnail">
                    <img src={assetsPath + "/" + layer.thumbnail} onClick={() => backgroundLayerClicked(layer)} />
                </div>
                <div className="background-group-menu">
                    {entry.layers.map(l => (
                        <div key={l.name} className={l.visibility === true ? "background-group-menu-item-active" : ""}
                            onClick={() => backgroundLayerClicked(l)}
                            onMouseEnter={ev => updateGroupItem(ev, l)}
                            onMouseLeave={ev => updateGroupItem(ev, layer)}
                        >{l.title}</div>
                    ))}
                </div>
            </div>
        );
    }

    const updateGroupItem = (ev, layer) => {
        let assetsPath = ConfigUtils.getConfigProp("assetsPath");
        ev.target.parentElement.parentElement.childNodes[0].firstChild.innerText = layer.title;
        ev.target.parentElement.parentElement.childNodes[1].firstChild.src = assetsPath + "/" + layer.thumbnail;
    }

    const buttonClicked = () => {
        setVisible((visible) => !visible);
    }

    const backgroundLayerClicked = (layer) => {
        if(layer) {
            changeLayerProperty(layer.uuid, "visibility", true);
        } else {
            let visible = layers.find(layer => layer.role === LayerRole.BACKGROUND && layer.visibility);
            if(visible) {
                changeLayerProperty(visible.uuid, "visibility", false);
            }
        }
        setVisible(false);
    }
  
    let tooltip = formatMessage({id: "tooltip.background"});
    let classes = classnames({
        "map-button": true,
        "map-button-active": visible
    });
    let backgroundLayers = layers.filter(layer => layer.role === LayerRole.BACKGROUND).slice(0).reverse();
    // Re-sort layers, ensuring grouped layers are grouped together
    let idx = 0;
    let indices = backgroundLayers.reduce((res, l) => {
        let name = l.group || l.name;
        if(!res[name]) {
            res[name] = ++idx;
        }
        return res;
    }, {});
    backgroundLayers = sortBy(backgroundLayers, entry => indices[entry.group || entry.name]);
    let entries = backgroundLayers.reduce((res, layer) => {
        if(!isEmpty(res) && layer.group && layer.group === res[res.length - 1].group) {
            res[res.length - 1].layers.push(layer);
        } else if(layer.group) {
            res.push({
                group: layer.group,
                layers: [layer]
            });
        } else {
            res.push(layer)
        }
        return res;
    }, []);

    if(entries.length > 0){
        return (
            <div>
                <MapButton 
                    icon={'FaMapMarkedAlt'}
                    type="fa"
                    tooltip={tooltip} 
                    position={{bottom: (`${5 + 4 * position}em`), right: 8}} 
                    onClick={buttonClicked} 
                />
                <div id="BackgroundSwitcher" className={visible ? 'bgswitcher-active' : ''}>
                    {renderLayerItem(null, backgroundLayers.filter(layer => layer.visibility === true).length === 0)}
                    {entries.map(entry => entry.group ? renderGroupItem(entry) : renderLayerItem(entry, entry.visibility === true))}
                </div>
            </div>
        );
    }

    return null;
};

BackgroundSwitcher.defaultProps = {
    position: 0
}

BackgroundSwitcher.propTypes = {
    position: PropTypes.number,
    layers: PropTypes.array,
    toggleBackgroundswitcher: PropTypes.func,
    changeLayerProperty: PropTypes.func
}

const selector = (state) => ({
    layers: state.layers.flat || []
});

export default {
    BackgroundSwitcherPlugin: connect(selector, {
      changeLayerProperty: changeLayerProperty
    })(BackgroundSwitcher),
    reducers: {}
};
