import React from "react"
import { IconButton, withStyles } from "goodmap-core"
import clsx from "clsx";
import { FaRegSun, FaRegTrashAlt } from "react-icons/fa";
import Icon from "../../components/Icon";

const LayerGroupItem = ({
    disabled,
    groupExpandedToggled,
    itemVisibilityToggled,
    layerMenuToggled,
    removeLayer,
    allowRemove,
    classes,
    layer,
    path,
    allowReordering,
    expanderstate,
    checkboxState,
    visibility,
    inMutuallyExclusiveGroup,
    group
}) => {

    let itemclasses = {
        [classes.groupItem]: true,
        [classes.layerTreeItem]: true,
        [classes.layerItemDisabled]: disabled
    };

    return (
        <div className={clsx(itemclasses)}>
            <span className={classes.container}>
                <div className={classes.checkboxContainer}>
                    <Icon 
                        fontSize={22} 
                        className={classes.expander} 
                        icon={expanderstate} 
                        onClick={groupExpandedToggled}
                    />
                    <Icon fontSize={22} className={classes.layerItemCheckbox} icon={checkboxState} onClick={(e) => {
                        e.stopPropagation();
                        itemVisibilityToggled(layer, path, visibility, inMutuallyExclusiveGroup)}
                    } />
                </div>
                <span className={classes.layerItemTitle} title={group.title}>{group.title}</span>
            </span>
            <span></span>
            {allowReordering ? (<IconButton className={classes.iconButton} size="small" onClick={(e) => { e.stopPropagation(); layerMenuToggled(group.uuid)}}><FaRegSun size={20}/></IconButton>) : null}
            {allowRemove ? (<IconButton className={classes.iconButton} size="small" onClick={(e) => { e.stopPropagation(); removeLayer(layer.id, path)}}><FaRegTrashAlt size={20}/></IconButton>) : null}
        </div>
    )
}

const styles = (theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    checkboxContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    root: {

    },
    groupItem: {
        fontWeight: 600
    },
    layerTreeItem: {
        fontSize: "small",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    layerItemDisabled: {
        color: "#999999"
    },
    layerItemCheckbox: {
        color: theme.palette.grey[600],
        fontSize: "1.2em",
        marginRight: theme.spacing(1),
        flex: "0 0 auto",
        alignItems: "center"
    },
    expander: {
        color: theme.palette.grey[600],
        fontSize: "1.4em",
        marginRight: theme.spacing(1),
        flex: "0 0 1em"
    },
    iconButton: {
        fontSize: "1.4em",
        color: theme.palette.grey[600],
        padding: theme.spacing(1)
    },
    layerItemTitle: {
        marginLeft: theme.spacing(1),
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flex: "0 1 auto",
        alignItems: "center"
    },
})

export default withStyles(styles)(LayerGroupItem)