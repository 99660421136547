/* eslint-disable */
/**
 * Copyright 2016, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react'
import PropTypes from 'prop-types'
import Message from '../../components/I18N/Message'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    FacebookShareCount,
    FacebookIcon,
    TwitterIcon,
    GooglePlusIcon,
    LinkedinIcon
} from 'react-share'
import './share.css'


class ShareSocials extends React.Component {
    static propTypes = {
        shareUrl: PropTypes.string,
         getCount: PropTypes.func,
         shareTitle: PropTypes.string
    }
    static defaultProps = {
        shareTitle: 'GeoSolutions'
    }
    render() {
        let countProps = {};
        if (this.props.getCount) {
            countProps.getCount = this.props.getCount;
        }
        const title = this.props.shareTitle;

        return (
            <div className="social-links">

                <h4>
                    <Message msgId="share.socialIntro"/>
                </h4>

                <div className="social-box facebook">
                <FacebookShareButton
                    url={this.props.shareUrl}
                    quote={title}
                    className="share-facebook">
                    <FacebookIcon
                    size={32}
                    round />
                </FacebookShareButton>
                <FacebookShareCount
                    url={this.props.shareUrl}
                    {...countProps}
                    className="share-facebook-count">
                    {count => count}
                </FacebookShareCount>
                </div>

                <div className="social-box twitter">
                    <TwitterShareButton
                    url={this.props.shareUrl}
                    title={title}
                    className="share-twitter">
                    <TwitterIcon
                        size={32}
                        round />
                    </TwitterShareButton>
                    <div className="share-twitter-count">
                    &nbsp;
                    </div>
                </div>

                <div className="social-box linkedin">
                    <LinkedinShareButton
                    url={this.props.shareUrl}
                    title={title}
                    className="share-linkedin-count">
                    <LinkedinIcon
                        size={32}
                        round />
                    </LinkedinShareButton>
                    <div className="linkedin-twitter-count">
                    &nbsp;
                    </div>
                </div>
        </div>
        );
    }
};

export default ShareSocials;
