/**
 * Copyright 2015, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import BingLayer from "./BingLayer"
import GoogleLayer from "./GoogleLayer"
import GraticuleLayer from "./GraticuleLayer"
import MapQuest from "./MapQuest"
import OSMLayer from "./OSMLayer"
import OverlayLayer from "./OverlayLayer"
import VectorLayer from "./VectorLayer"
import TileProviderLayer from "./TileProviderLayer"
import WMSLayer from "./WMSLayer"
import WMTSLayer from "./WMTSLayer"
import WFSLayer from "./WFSLayer"


const LayerRegistry = {
    'bing': BingLayer,
    'google': GoogleLayer,
    'graticule': GraticuleLayer,
    'mapquest': MapQuest,
    'osm': OSMLayer,
    'overlay': OverlayLayer,
    'tileprovider': TileProviderLayer,
    'vector': VectorLayer,
    'wms': WMSLayer,
    'wmts': WMTSLayer,
    'wfs': WFSLayer
};

export default LayerRegistry;
