/* eslint-disable */
/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Window from "../components/Window";
import {Typography, Divider, Button} from "goodmap-core";
import { useIntl } from 'react-intl';

const Help = () => {
   
    const { formatMessage } = useIntl();

    return (
        <Window padding={1} id="Help" width="20em" title="appmenu.items.Help"
            icon="info">
            {() => ({
                body: (
                    <div>
                        <Typography gutterBottom variant="h5">{formatMessage({id: "help.title"})}</Typography>
                        <Typography gutterBottom>{formatMessage({id: "help.text"})}</Typography>
                        <br/>
                        <Divider/>
                        <br/>
                        <Button onClick={() => window.open("https://elearning.skymove.sk")} variant="contained" color="primary">{formatMessage({id: "help.button"})}</Button>
                    </div>
                )
            })}
        </Window>
    );
};

Help.defaultProps = {}

Help.propTypes = { }

export default {
    HelpPlugin: Help,
    reducers: {
        task: require('../reducers/task')
    }
};
