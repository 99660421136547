import ol_interaction_Draw from 'ol/interaction/Draw';
import { useEffect, useMemo } from "react";

export const useDrawInteraction = ({ 
    source, 
    onDrawStart,
    onDrawEnd,
    style,
    isFreeHand,
    type
}) => {

    const drawInteraction = useMemo(() => {
        return new ol_interaction_Draw({
            source,
            style,
            type,
            condition: (event) => {  return event.pointerEvent.buttons === 1 },
            freehand: isFreeHand
        })
    }, [source, type, isFreeHand, style])
   

    useEffect(() => {
        drawInteraction.on('drawstart', onDrawStart);
        drawInteraction.on('drawend', onDrawEnd);
        return () => {            
            drawInteraction.un('drawstart', onDrawStart);
            drawInteraction.un('drawend', onDrawEnd);
        }
    }, [drawInteraction, onDrawStart, onDrawEnd])

    return { drawInteraction }  
  
   
}