/* eslint-disable */
/**
 * Copyright 2016, GeoSolutions Sas.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import url from 'url';

const urlQuery = url.parse(window.location.href, true).query;

const PluginsContainer = ({
    mode,
    plugins,
    pluginsConfig,
    pluginsAppConfig
}) => {
    const renderPlugins = (pluginsConfig) => {
        return pluginsConfig.map(pluginConf => {
            let Plugin = plugins[pluginConf.name + "Plugin"];
            if(!Plugin) {
                console.warn("Non-existing plugin: " + pluginConf.name);
                return null;
            }
            let cfg = pluginConf.cfg || {};
            let appCfg = pluginsAppConfig[pluginConf.name + "Plugin"] || {};
            return (<Plugin key={pluginConf.name} {...cfg} {...appCfg} />);
        });
    }

    if (pluginsConfig) {
        return (
            <div id="PluginsContainer">
                {
                    renderPlugins(pluginsConfig[mode])
                }
            </div>
        );
    }
    return null;
}

PluginsContainer.propTypes = {
    mode: PropTypes.string,
    plugins: PropTypes.object,
    pluginsConfig: PropTypes.object,
    pluginsAppConfig: PropTypes.object
}

PluginsContainer.defaultProps = {
    mode: 'desktop',
    plugins: {},
    pluginsConfig: {},
    pluginsAppConfig: {}
}

export default connect((state) => ({
    pluginsConfig: state.localConfig && state.localConfig.plugins || null,
    mode: (urlQuery.mode || (state.browser && state.browser.mobile ? 'mobile' : 'desktop'))
}))(PluginsContainer);
