import React, { useEffect, useState } from "react"
import {makeStyles, MenuItem, TextField} from "goodmap-core"
import LocaleUtils from "../../utils/LocaleUtils";
import MapUtils from "../../utils/MapUtils";

const Scales = ({
    changeZoomLevel,
    zoom,
    scales
}) => {

    const classes = useStyles()
    const [scale, setScaleValue] = useState(0)

    const setScale = (value) => {
        const scale = parseInt(value);
        if(!isNaN(scale)) {
            const z = MapUtils.computeZoom(scales, scale);
            changeZoomLevel(z);
        } else {
            changeZoomLevel(zoom);
        }
    }

    useEffect(() => {
        setScaleValue(Math.round(MapUtils.computeForZoom(scales, zoom)));
    }, [scales, zoom])

    return (
            <span className={classes.scaleInput}>
                {/* <span> 1 : </span> */}
                <TextField fullWidth InputProps={{classes: {input: classes.input}}} classes={{root: classes.scaleSelector}} size="small" select variant="outlined"  onChange={ev => changeZoomLevel(parseInt(ev.target.value, 10))} value={Math.round(zoom)}>
                    {scales.map((item, index) =>
                        (<MenuItem value={index} key={index}>
                           1 : {LocaleUtils.toLocaleFixed(item, 0)}
                        </MenuItem>)
                    )}
                </TextField>
                <TextField classes={{root: classes.scaleTextInput}} InputProps={{classes: {input: classes.inputText}}}  type="text" variant="outlined" size="small" value={scale}
                    onChange={ev => setScaleValue(ev.target.value)}
                    onKeyUp={ev => { if(ev.keyCode === 13) setScale(ev.target.value)} }
                    onBlur={ev => setScale(ev.target.value)}/>
            </span>
    );
}

Scales.defaultProps = {

}

Scales.propTypes = {

}

const useStyles = makeStyles((theme) => ({
    scaleSelector: {  },
    scaleInput: {
        position: "relative",
        display: "flex"
    },
    scaleTextInput: {
        marginTop: 2,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        borderWidth: 0,
        left: 24,
        width: 80,
        "& fieldset": {
            borderWidth: 0
        }
    },
    inputText: {
        
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 2,
        paddingBottom: 2,
        borderWidth: 0,
        background: "#fff"
    },
    input: {
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4,
        paddingBottom: 4
    }
}))

export default Scales